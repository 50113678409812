import LabelWithValue from "components/label-with-value/label-with-value";
import { Countries } from "constants";

const ProfileBasicInfo = ({ containerClass, info, onClickEditProfile, onClickUpdatePassword }) => {
  return (
    <div className={containerClass}>
      <div className="flex flex-col gap-8">
        <div className="flex flex-row">
          <LabelWithValue
            containerClass="flex-1"
            label="First Name"
            value={info?.first_name || "N/A"}
          />
          <LabelWithValue
            containerClass="flex-1"
            label="Last Name"
            value={info?.last_name || "N/A"}
          />
          <LabelWithValue
            containerClass="flex-1"
            label="Email Address"
            value={info?.email || "N/A"}
          />
        </div>
        <div className="flex flex-row">
          <LabelWithValue
            containerClass="flex-1"
            label="Country"
            value={info?.country ? Countries[info?.country] : "N/A"}
          />
          <LabelWithValue
            containerClass="flex-1"
            label="Phone Number"
            value={info?.phone_number || "N/A"}
          />
          <LabelWithValue containerClass="flex-1" label="Company" value={info?.company || "N/A"} />
        </div>
        <div className="flex flex-row">
          <LabelWithValue
            containerClass="flex-1"
            label="Industry"
            value={info?.industry || "N/A"}
          />
        </div>
      </div>

      <div className="mt-7 flex gap-3">
        <button
          className="flex h-[30px] w-[130px] items-center justify-center p-0 text-xs"
          onClick={onClickEditProfile}
        >
          Edit Details
        </button>
        <button
          className="flex h-[30px] w-[130px] items-center justify-center p-0 text-xs"
          onClick={onClickUpdatePassword}
        >
          Update Password
        </button>
      </div>
    </div>
  );
};

export default ProfileBasicInfo;
