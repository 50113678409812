import "./register.css";

import { RegisterBox } from "components";

export default function Register() {
  return (
    <div className="registerPage">
      <RegisterBox />
    </div>
  );
}
