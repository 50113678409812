import "./tab-menu.css";

export default function tabMenu({ views, currentView, changeView }) {
  return (
    <div className="adminTabs">
      {views.map((e, i) => {
        return (
          <div
            key={`adminTab_${i}`}
            className={currentView === e.value ? "active" : ""}
            onClick={() => changeView(e.value)}
          >
            {e.label}
          </div>
        );
      })}
    </div>
  );
}
