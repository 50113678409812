import { Sort } from "assets/icons";
import { SimplePaging } from "components";
import { PageHeader } from "components/admin";
import { SUBSCRIPTION_PAYMENT_STATUS } from "constants/index";
import { getDateFromUnix } from "lib/utils";
import { useGetAdminPaymentHistoryQuery } from "modules/payment-history/paymentHistoryService";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSortBy, useTable } from "react-table";

const DEFAULT_PARAMS = {
  endBeforeId: "",
  limit: 10,
  startAfterId: "",
  toFirst: "true",
  toLast: "",
};

const columns = (navigate) => {
  return [
    {
      Cell: (props) => (
        <>
          <p
            className="cursor-pointer text-bleu-100"
            onClick={() => navigate(`/admin/customers/${props.row.original?.data?.customerId}`)}
          >
            {props.row.original?.data?.name}
          </p>
        </>
      ),
      Header: "Name",
      accessor: "name",
      // isSortable: true,
      width: "15%",
    },
    {
      Cell: (props) => (
        <>
          <p className="text-gris-600">
            {props.row.original?.data?.subscription?.subscription_type_name}
          </p>
        </>
      ),
      Header: "Subscription type",
      accessor: "subscriptionType",
      // isSortable: true,
      width: "15%",
    },
    {
      Cell: (props) => (
        <>
          <p className="font-bold text-gris-600">
            {getDateFromUnix(
              props.row.original?.data?.payment_date?._seconds,
              props.row.original?.data?.payment_date?._nanoseconds
            )}
          </p>
        </>
      ),
      Header: "Purchase date",
      accessor: "purchased_at",
      // isSortable: true,
      width: "15%",
    },
    {
      Cell: (props) => (
        <>
          <p className="text-bleu-850">
            {props.row.original?.data?.subscription
              ? getDateFromUnix(
                  props.row.original?.data?.subscription?.expiration_date?._seconds,
                  props.row.original?.data?.subscription?.expiration_date?._nanoseconds
                )
              : ""}
          </p>
        </>
      ),
      Header: "Expiry date",
      accessor: "expired_at",
      // isSortable: true,
      width: "15%",
    },
    {
      Cell: (props) => (
        <>
          <p className="text-bleu-850">{props.row.original?.data?.amount_paid}</p>
        </>
      ),
      Header: "Amount paid",
      accessor: "paid_amount",
      width: "15%",
    },
    {
      Cell: (props) => (
        <p
          className={`${
            props.row.original?.data?.status !== SUBSCRIPTION_PAYMENT_STATUS.paid
              ? `text-red-500`
              : `text-green-800`
          }`}
        >
          {props.row.original?.data?.status === SUBSCRIPTION_PAYMENT_STATUS.paid && "Paid"}
          {props.row.original?.data?.status !== SUBSCRIPTION_PAYMENT_STATUS.paid && "Not Paid"}
        </p>
      ),
      Header: "Status",
      accessor: "status",
      width: "10%",
    },
    {
      Cell: (props) => (
        <>
          <p
            className="cursor-pointer pr-1 text-right text-bleu-100"
            onClick={() => navigate(`/admin/payments/${props.row.original?.id}`)}
          >
            Details
          </p>
        </>
      ),
      Header: () => (
        <div
          style={{
            textAlign: "right",
          }}
        >
          Actions
        </div>
      ),
      accessor: "customerId",
      width: "15%",
    },
  ];
};

export default function Payments() {
  const navigate = useNavigate();
  const [params, setParams] = useState(DEFAULT_PARAMS);
  const { data, isLoading, isFetching } = useGetAdminPaymentHistoryQuery(params);

  const COLUMN = useMemo(() => columns(navigate) || [], [navigate]);
  const paymentsData = useMemo(() => data?.data || [], [data]);

  const onChangePaginationHandler = (obj) => {
    setParams((p) => ({ ...p, ...obj }));
  };

  const tableInstance = useTable(
    {
      columns: COLUMN,
      data: paymentsData,
    },
    useSortBy
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;
  return (
    <>
      <PageHeader title="Payments" searchField={false} />
      {isLoading || isFetching ? (
        <p className="mt-10">Loading data ...</p>
      ) : !isLoading && paymentsData.length ? (
        <table {...getTableProps()}>
          <thead>
            {
              // Loop over the header rows
              headerGroups.map((headerGroup) => (
                // Apply the header row props
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {
                    // Loop over the headers in each row
                    headerGroup.headers.map((column) => {
                      let thProps = column.getHeaderProps();
                      if (column.isSortable) {
                        thProps = column.getHeaderProps(column.getSortByToggleProps());
                      }
                      return (
                        <th {...thProps}>
                          {column.render("Header")}
                          {column.isSortable && <Sort />}
                        </th>
                      );
                    })
                  }
                </tr>
              ))
            }
          </thead>
          {/* Apply the table body props */}
          <tbody {...getTableBodyProps()}>
            {
              // Loop over the table rows
              rows.map((row, i) => {
                // Prepare the row for display
                prepareRow(row);
                const rowClass = i % 2 ? "odd" : "even";
                return (
                  // Apply the row props
                  <tr {...row.getRowProps()} className={rowClass}>
                    {
                      // Loop over the rows cells
                      row.cells.map((cell) => {
                        // Apply the cell props
                        return (
                          <td {...cell.getCellProps()}>
                            {
                              // Render the cell contents
                              cell.render("Cell")
                            }
                          </td>
                        );
                      })
                    }
                  </tr>
                );
              })
            }
          </tbody>
        </table>
      ) : (
        <p className="mt-10">No data found.</p>
      )}
      {!(isLoading || isFetching) && (
        <div className="mt-6 flex items-center justify-between">
          <SimplePaging
            data={{
              nextPageId: data?.nextPageId || "",
              prevPageId: data?.prevPageId || "",
            }}
            onChangePagination={onChangePaginationHandler}
          />
          <p>
            Showing {paymentsData?.length} out of {data?.total || "-"}
          </p>
        </div>
      )}
    </>
  );
}
