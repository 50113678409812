import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

export const paymentMethodSlice = createSlice({
  initialState,
  name: "payment-method",
  reducers: {},
});

export default paymentMethodSlice.reducer;
