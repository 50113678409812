import { Sort } from "assets/icons";
import { SimplePaging } from "components";
import { SUBSCRIPTION_PAYMENT_STATUS } from "constants/index";
import { getDateFromUnix } from "lib/utils";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useSortBy, useTable } from "react-table";

const columns = (navigate) => {
  return [
    {
      Cell: (props) => (
        <>
          <p className="text-gris-600">
            {props.row.original?.data?.subscription?.subscription_type_name}
          </p>
        </>
      ),
      Header: "Subscription type",
      accessor: "subscriptionType",
      isSortable: true,
      width: "15%",
    },
    {
      Cell: (props) => (
        <>
          <p className="font-bold text-gris-600">
            {getDateFromUnix(
              props.row.original?.data?.payment_date?._seconds,
              props.row.original?.data?.payment_date?._nanoseconds
            )}
          </p>
        </>
      ),
      Header: "Purchase date",
      accessor: "purchased_at",
      isSortable: true,
      width: "15%",
    },
    {
      Cell: (props) => (
        <>
          <p className="text-bleu-850">
            {getDateFromUnix(
              props.row.original?.data?.subscription?.expiration_date?._seconds,
              props.row.original?.data?.subscription?.expiration_date?._nanoseconds
            )}
          </p>
        </>
      ),
      Header: "Expiry date",
      accessor: "expired_at",
      isSortable: true,
      width: "15%",
    },
    {
      Cell: (props) => (
        <>
          <p className="text-bleu-100">{props.row.original?.data?.subscription?.seats_purchased}</p>
        </>
      ),
      Header: "Seats",
      accessor: "seats",
      width: "15%",
    },
    {
      Cell: (props) => (
        <>
          <p className="text-bleu-850">{props.row.original?.data?.amount_paid}</p>
        </>
      ),
      Header: "Amount paid",
      accessor: "paid_amount",
      width: "15%",
    },
    {
      Cell: (props) => (
        <p
          className={`${
            props.row.original?.data?.status !== SUBSCRIPTION_PAYMENT_STATUS.paid
              ? `text-red-500`
              : `text-green-800`
          }`}
        >
          {props.row.original?.data?.status === SUBSCRIPTION_PAYMENT_STATUS.paid && "Paid"}
          {props.row.original?.data?.status !== SUBSCRIPTION_PAYMENT_STATUS.paid && "Not Paid"}
        </p>
      ),
      Header: "Status",
      accessor: "status",
      width: "10%",
    },
    {
      Cell: (props) => (
        <>
          <p
            className="cursor-pointer pr-1 text-right text-bleu-100"
            onClick={() => navigate(`/admin/payments/${props.row.original?.id}`)}
          >
            Details
          </p>
        </>
      ),
      Header: () => (
        <div
          style={{
            textAlign: "right",
          }}
        >
          Invoice
        </div>
      ),
      accessor: "customerId",
      width: "15%",
    },
  ];
};

const CustomerPaymentHistory = ({ data, isLoading, onChangePagination }) => {
  const navigate = useNavigate();
  const COLUMN = useMemo(() => columns(navigate) || [], [navigate]);
  const invoiceData = useMemo(() => data?.data || [], [data]);

  const tableInstance = useTable(
    {
      columns: COLUMN,
      data: invoiceData,
    },
    useSortBy
  );
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;

  return (
    <div>
      {isLoading ? (
        <p className="mt-10">Loading data ...</p>
      ) : !isLoading && invoiceData.length ? (
        <table className="mt-10" {...getTableProps()}>
          <thead>
            {
              // Loop over the header rows
              headerGroups.map((headerGroup) => (
                // Apply the header row props
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {
                    // Loop over the headers in each row
                    headerGroup.headers.map((column) => {
                      let thProps = column.getHeaderProps();
                      if (column.isSortable) {
                        thProps = column.getHeaderProps(column.getSortByToggleProps());
                      }
                      return (
                        <th {...thProps} width={column.width || "unset"}>
                          {column.render("Header")}
                          {column.isSortable && <Sort />}
                        </th>
                      );
                    })
                  }
                </tr>
              ))
            }
          </thead>
          {/* Apply the table body props */}
          <tbody {...getTableBodyProps()}>
            {
              // Loop over the table rows
              rows.map((row, i) => {
                // Prepare the row for display
                prepareRow(row);
                const rowClass = i % 2 ? "odd" : "even";
                return (
                  // Apply the row props
                  <tr {...row.getRowProps()} className={rowClass}>
                    {
                      // Loop over the rows cells
                      row.cells.map((cell) => {
                        // Apply the cell props
                        return (
                          <td {...cell.getCellProps()}>
                            {
                              // Render the cell contents
                              cell.render("Cell")
                            }
                          </td>
                        );
                      })
                    }
                  </tr>
                );
              })
            }
          </tbody>
        </table>
      ) : (
        <p className="mt-10">No history found.</p>
      )}
      {!isLoading && (
        <SimplePaging
          containerClass="mt-6"
          data={{
            nextPageId: data?.nextPageId || "",
            prevPageId: data?.prevPageId || "",
          }}
          onChangePagination={onChangePagination}
        />
      )}
    </div>
  );
};

export default CustomerPaymentHistory;
