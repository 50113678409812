import "./modal.css";

import { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";

//#region Utility Functions
const backDropHandler = (e, modalWrapperRef, callback) => {
  let clickedTarget = e.target;
  // check if the user has clicked inside or outside the modal
  if (clickedTarget.contains(modalWrapperRef?.current)) {
    callback();
  }
};

const handleCloseClick = (e, callback) => {
  e.preventDefault();
  callback();
};
//#endregion Utility Functions

function Modal({ containerClass = "", show, onClose, children }) {
  const [isBrowser, setIsBrowser] = useState(false);
  const modalWrapperRef = useRef(null);

  useEffect(() => {
    setIsBrowser(true);

    //Saving the scroll so that when the modal is closed, the page doesn't scroll back to the top on some browsers
    window.addEventListener("scroll", () => {
      document.documentElement.style.setProperty("--scroll-y", `${window.scrollY}px`);
    });
  }, []);

  //Adjusting body style when the modal shows/hides
  useEffect(() => {
    if (show) {
      const scrollY = document.documentElement.style.getPropertyValue("--scroll-y");
      const body = document.body;
      body.style.top = `-${scrollY}`;
      body.style.position = "fixed";
      body.style.width = "100%";
    } else {
      const body = document.body;
      const scrollY = body.style.top;
      body.style.position = "";
      body.style.top = "";
      window.scrollTo(0, parseInt(scrollY || "0") * -1);
    }
  }, [show]);

  const modalContent = show ? (
    <div
      className="modalOverlay"
      ref={modalWrapperRef}
      onClick={(e) => backDropHandler(e, modalWrapperRef, onClose)}
    >
      <div className={`modal ${containerClass}`}>{children}</div>
    </div>
  ) : (
    <></>
  );

  if (isBrowser) {
    return ReactDOM.createPortal(modalContent, document.getElementById("modal-root"));
  } else {
    return null;
  }
}

export default Modal;
