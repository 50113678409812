import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const SEAT_API_REDUCER_KEY = "seat-api";

export const seatApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BACKEND_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem("authToken");
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    addSeats: builder.mutation({
      invalidatesTags: ["subscriptionSeatList"],
      query: ({ customerId, ...body }) => ({
        body: body,
        method: "POST",
        url: `/customers/${customerId}/seats/multiple`,
      }),
    }),
    deleteSeat: builder.mutation({
      invalidatesTags: ["subscriptionSeatList"],
      query: ({ customerId, id }) => ({
        // body: {},
        method: "DELETE",
        url: `/customers/${customerId}/seats/${id}`,
      }),
    }),
    getSubscriptionSeats: builder.query({
      providesTags: ["subscriptionSeatList"],
      query: (customerId) => `/customers/${customerId}/seats`,
    }),
    updateSeat: builder.mutation({
      invalidatesTags: ["subscriptionSeatList"],
      query: ({ customerId, id, ...body }) => ({
        body: body,
        method: "PUT",
        url: `/customers/${customerId}/seats/${id}`,
      }),
    }),
  }),
  reducerPath: SEAT_API_REDUCER_KEY,
  tagTypes: ["subscriptionSeatList"],
});

export const { useAddSeatsMutation, useDeleteSeatMutation, useGetSubscriptionSeatsQuery, useUpdateSeatMutation } = seatApi;
