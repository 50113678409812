import { Loader } from "components";
import { useUpdateSeatMutation } from "modules/seat/seatService";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

const SubscriptionSeatEdit = ({ customerId, selected, onClose }) => {
  const { register, handleSubmit, formState } = useForm();

  const [updateSeat, { isLoading }] = useUpdateSeatMutation();

  const handleSubmitForm = async (data) => {
    data.id = selected.id;
    data.customerId = customerId;
    let result = await updateSeat(data);
    if (result.error) {
      toast.error(result?.error?.data?.message || "Something went wrong");
    } else {
      toast.success("Seat updated successfully");
      onClose();
    }
  };

  return (
    <div className="relative w-[700px]">
      <Loader isLoading={isLoading} />
      <h2 className="page-title">Edit Seat</h2>
      <form onSubmit={handleSubmit(handleSubmitForm)}>
        <div className="form-fields mt-10 flex flex-row gap-10">
          <div className="flex flex-1 flex-col gap-5">
            <div className="formElement">
              <p className="formTitle">Name *</p>
              <div className="formInputWrapper">
                <input
                  className="formTextInput"
                  {...register("name", {
                    required: true,
                    value: selected?.data?.name || "",
                  })}
                />
              </div>
              <div className="validationError">
                {formState.errors?.name?.type === "required" && "Name is required"}
              </div>
            </div>

            <div className="formElement">
              <p className="formTitle">Machine ID *</p>
              <div className="formInputWrapper">
                <input
                  className="formTextInput"
                  {...register("fingerprint", {
                    required: true,
                    value: selected?.data?.fingerprint || "",
                  })}
                />
              </div>
              <div className="validationError">
                {formState.errors?.fingerprint?.type === "required" && "Machine ID is required"}
              </div>
            </div>
          </div>
          <div className="flex flex-1 flex-col gap-5">
            <div className="formElement">
              <p className="formTitle">Username *</p>
              <div className="formInputWrapper">
                <input
                  className="formTextInput"
                  {...register("login", {
                    required: true,
                    value: selected?.data?.login || "",
                  })}
                />
              </div>
              <div className="validationError">
                {formState.errors?.login?.type === "required" && "Username is required"}
              </div>
            </div>

            <div className="formElement">
              <p className="formTitle">Password *</p>
              <div className="formInputWrapper">
                <input
                  type="password"
                  className="formTextInput"
                  {...register("password", {
                    required: true,
                    value: selected?.data?.password || "",
                  })}
                />
              </div>
              <div className="validationError">
                {formState.errors?.password?.type === "required" && "Password is required"}
              </div>
            </div>
          </div>
        </div>
        <div className="mt-10 flex justify-end gap-5">
          <button onClick={onClose} className="btn-secondary">
            Cancel
          </button>
          <button>Save changes</button>
        </div>
      </form>
    </div>
  );
};

export default SubscriptionSeatEdit;
