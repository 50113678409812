import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

export const customerSlice = createSlice({
  initialState,
  name: "customer",
  reducers: {},
});

export default customerSlice.reducer;
