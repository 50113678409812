const Twitter = ({ width = 17, height = 16 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M16.2676 3.48859C15.6694 3.75368 15.0267 3.93279 14.3511 4.01375C15.0482 3.59663 15.5698 2.94013 15.8184 2.16675C15.1635 2.55577 14.4467 2.8296 13.6992 2.97634C13.1965 2.43962 12.5307 2.08388 11.8051 1.96434C11.0795 1.8448 10.3348 1.96815 9.68652 2.31524C9.03824 2.66233 8.52268 3.21374 8.2199 3.88386C7.91711 4.55398 7.84404 5.30532 8.01202 6.02123C6.68494 5.9546 5.38669 5.60967 4.20154 5.00882C3.0164 4.40798 1.97083 3.56465 1.1327 2.53357C0.846123 3.02792 0.68134 3.60108 0.68134 4.21149C0.681021 4.761 0.816342 5.30209 1.0753 5.78676C1.33426 6.27143 1.70884 6.68469 2.16582 6.98987C1.63584 6.973 1.11757 6.8298 0.654115 6.57218V6.61516C0.654062 7.38588 0.920657 8.13287 1.40866 8.72939C1.89667 9.32592 2.57603 9.73524 3.33148 9.88789C2.83984 10.0209 2.32439 10.0405 1.82407 9.94521C2.03721 10.6084 2.45239 11.1883 3.01149 11.6037C3.57058 12.0192 4.2456 12.2494 4.94205 12.2622C3.75979 13.1903 2.29971 13.6937 0.796688 13.6915C0.530443 13.6916 0.264423 13.676 0 13.6449C1.52566 14.6259 3.30163 15.1465 5.11543 15.1445C11.2554 15.1445 14.6119 10.0591 14.6119 5.64868C14.6119 5.50539 14.6083 5.36067 14.6019 5.21738C15.2548 4.74522 15.8184 4.16055 16.2662 3.49074L16.2676 3.48859Z" />
    </svg>
  );
};

export default Twitter;
