import edit from "assets/icons/edit.svg";
import visa from "assets/icons/visa.svg";
import { Loader } from "components";

const PaymentMethodItem = ({ data, defaultMethodId, makeDefault, isLoading }) => {
  return (
    <div className="relative w-[300px] rounded border p-5">
      <Loader isLoading={isLoading} />
      <div className="flex flex-row justify-between">
        <div className="flex flex-row gap-[18px]">
          <img src={visa} />
          <div>
            <p className="text-base font-semibold text-bleu-850">{data?.card?.cardType || ""}</p>
            <p className="text-xs font-bold text-gris-600">
              ************{data?.card?.lastFourDigits || ""}
            </p>
          </div>
        </div>
        {/* <p className="text-xs font-bold text-gris-600">2/23</p> */}
      </div>
      <div className="mt-[66px] flex flex-row justify-between">
        {data?.customerPaymentProfileId === defaultMethodId ? (
          <p className="text-sm font-bold text-bleu-100">Default Card</p>
        ) : (
          <button
            className="h-[30px] p-0 text-xs"
            onClick={() => makeDefault(data?.customerPaymentProfileId)}
          >
            Make card default
          </button>
        )}
        {/* <img className="cursor-pointer" src={edit} /> */}
      </div>
    </div>
  );
};

export default PaymentMethodItem;
