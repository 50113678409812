const Phone = ({ width = 24, height = 24 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.67962 4.10985L5.08237 2.70711C5.47289 2.31658 6.10606 2.31658 6.49658 2.70711L9.71395 5.92447C10.1045 6.315 10.1045 6.94816 9.71395 7.33869L7.52687 9.52576C7.1808 9.87183 7.095 10.4005 7.31388 10.8383C8.57918 13.3689 10.6311 15.4208 13.1617 16.6861C13.5995 16.905 14.1282 16.8192 14.4742 16.4731L16.6613 14.2861C17.0518 13.8955 17.685 13.8955 18.0755 14.2861L21.2929 17.5034C21.6834 17.8939 21.6834 18.5271 21.2929 18.9176L19.8902 20.3204C17.7788 22.4317 14.4362 22.6693 12.0475 20.8777L9.57594 19.0241C7.83241 17.7164 6.28359 16.1676 4.97594 14.4241L3.12226 11.9525C1.33072 9.56377 1.56827 6.2212 3.67962 4.10985Z"
        fill="#00A0EE"
      />
    </svg>
  );
};

export default Phone;
