import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

export const appLinkSlice = createSlice({
  initialState,
  name: "appLink",
  reducers: {},
});

export default appLinkSlice.reducer;
