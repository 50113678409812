import "./gallery.css";

export default function Gallery() {
  var mockGallery = [
    {
      name: "image 1",
      path: "assets/images/screen1-1.jpg",
    },
    {
      name: "image 2",
      path: "assets/images/screen1-2.jpg",
    },
    {
      name: "image 3",
      path: "assets/images/screen1-3.jpg",
    },
    {
      name: "image 4",
      path: "assets/images/screen1-4.jpg",
    },
    {
      name: "image 5",
      path: "assets/images/screen1-13.jpg",
    },
    {
      name: "image 6",
      path: "assets/images/screen2-1.jpg",
    },

    {
      name: "image 7",
      path: "assets/images/screen2.jpg",
    },
    {
      name: "image 8",
      path: "assets/images/screen10-1.jpg",
    },
    {
      name: "image 9",
      path: "assets/images/screen10-2.jpg",
    },
    {
      name: "image 10",
      path: "assets/images/screen10-3.jpg",
    },
  ];
  return (
    <div className="gallery p-10 md:min-h-[925px] md:p-0 md:px-10 md:py-[130px]" id="gallery">
      <div className="blueTitle">Gallery</div>
      <div className="exoTitle mt-1 mb-10">Take a sneak peek</div>
      <div className="inline-block">
        <div className="grid w-auto grid-cols-1 gap-5 md:grid-cols-3">
          {mockGallery.map((e, i) => {
            return (
              <img
                key={"galleryEntry" + i}
                src={`${process.env.PUBLIC_URL}/${e.path}`}
                alt={e.name}
                width={439}
                style={{ height: 300, objectFit: "cover" }}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}
