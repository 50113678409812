const Location = ({ width = 24, height = 24 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.3983 20.9552C14.0148 20.1257 20 16.6629 20 10.8643C20 6.44598 16.4183 2.86426 12 2.86426C7.58172 2.86426 4 6.44598 4 10.8643C4 16.6629 9.98521 20.1257 11.6017 20.9552C11.8552 21.0852 12.1448 21.0852 12.3983 20.9552ZM12.0001 14.2927C13.8937 14.2927 15.4287 12.7577 15.4287 10.8641C15.4287 8.97057 13.8937 7.43555 12.0001 7.43555C10.1066 7.43555 8.57153 8.97057 8.57153 10.8641C8.57153 12.7577 10.1066 14.2927 12.0001 14.2927Z"
        fill="#00A0EE"
      />
    </svg>
  );
};

export default Location;
