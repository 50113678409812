import "./login-box.css";

import logo from "assets/images/logo.svg";
import { LoginForgotPwd, LoginForm } from "components";
import { useAuth } from "lib/auth";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export default function LoginBox() {
  const [showLoginScreen, setShowLoginScreen] = useState(true);
  const navigate = useNavigate();
  const auth = useAuth();

  //#region Event Handlers
  const toggleLoginForgotPwd = () => {
    setShowLoginScreen(!showLoginScreen);
  };

  const handleLogin = async (data) => {
    await auth.login(data.email, data.password);
    if (auth.isCustomer()) await auth.getCurrentSubscription();
  };

  const onLoginSuccessCallbackHandler = () => {
    toast.success("Login successful");
    if (auth.isAdmin()) {
      navigate("/admin/customers");
    } else {
      navigate("/customer/profile");
    }
  };

  const handleForgotPwd = async (data) => {
    return new Promise((resolve, reject) => {
      auth
        .sendPasswordReset(data.email)
        .then(() => {
          toast.success(`Password reset link sent to your email`);
          resolve();
        })
        .catch((error) => {
          toast.error(`There was an error, please try again`);
          reject();
        });
    });
  };

  return (
    <div className="loginBox">
      <div>
        <img className="mx-auto" src={logo} alt="NVISION3D" width={"210px"} />
      </div>
      {showLoginScreen && (
        <LoginForm
          handleLogin={handleLogin}
          toggleLoginForgotPwd={toggleLoginForgotPwd}
          onSuccessCallback={onLoginSuccessCallbackHandler}
        />
      )}
      {!showLoginScreen && (
        <LoginForgotPwd
          handleForgotPwd={handleForgotPwd}
          toggleLoginForgotPwd={toggleLoginForgotPwd}
        />
      )}
    </div>
  );
}
