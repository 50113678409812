import { Loader } from "components";
import { Countries } from "constants";
import { useAddPaymentMethodMutation } from "modules/payment-method/paymentMethodService";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

const sortedCountries = Object.entries(Countries).sort((a, b) => {
  if (a[1] < b[1]) {
    return -1;
  }
  if (a[1] > b[1]) {
    return 1;
  }
  return 0;
});

const PaymentMethodAddEdit = ({ onClose }) => {
  const { register, handleSubmit, formState } = useForm();

  const [addPaymentMethod, { isLoading }] = useAddPaymentMethodMutation();

  const handleSubmitForm = async (data) => {
    const body = {
      card: {
        billingAddress: {
          address: data.address,
          city: data.city,
          country: data.country,
          state: data.state,
          zip: data.zip,
        },
        ccv: data.ccv,
        expirationDate: data.expirationDate,
        number: data.number.replace(/ /g, ""),
      },
    };
    let result = await addPaymentMethod(body);
    if (result.error) {
      toast.error(result?.error?.data?.message || "Something went wrong");
    } else {
      toast.success("Payment method added successfully");
      onClose();
    }
  };

  return (
    <div className="relative w-[700px]">
      <Loader isLoading={isLoading} />
      <h2 className="page-title">Add/Edit Payment Method</h2>
      <form onSubmit={handleSubmit(handleSubmitForm)}>
        <div>
          <p className="mt-11 mb-8 text-[26px] font-semibold">Card details</p>
          <div className="form-fields flex flex-row gap-[76px]">
            <div className="flex flex-1 flex-col gap-5">
              {/* <div className="formElement">
                <p className="formTitle">Card Type</p>
                <div className="formInputWrapper">
                  <select
                    className="formSelect"
                    placeholder="Card Type"
                    {...register("cardType", {
                      // required: true,
                    })}
                  >
                    <option value="">Select Card Type</option>
                    <option value="visa">Visa</option>
                    <option value="master">Master</option>
                  </select>
                </div>
                <div className="validationError">
                  {formState.errors?.cardType?.type === "required" && "Card Type is required"}
                </div>
              </div> */}

              <div className="formElement">
                <p className="formTitle">Card Number</p>
                <div className="formInputWrapper">
                  <input
                    className="formTextInput"
                    {...register("number", {
                      required: true,
                    })}
                  />
                </div>
                <div className="validationError">
                  {formState.errors?.number?.type === "required" && "Card Number is required"}
                </div>
              </div>

              <div className="formElement">
                <p className="formTitle">CCV</p>
                <div className="formInputWrapper">
                  <input
                    className="formTextInput"
                    {...register("ccv", {
                      required: true,
                    })}
                  />
                </div>
                <div className="validationError">
                  {formState.errors?.ccv?.type === "required" && "ccv is required"}
                </div>
              </div>
            </div>
            <div className="flex flex-1 flex-col gap-5">
              <div className="formElement">
                <p className="formTitle">Expiry Date</p>
                <div className="formInputWrapper">
                  <input
                    className="formTextInput"
                    {...register("expirationDate", {
                      required: true,
                    })}
                  />
                </div>
                <div className="validationError">
                  {formState.errors?.expirationDate?.type === "required" &&
                    "Expiry Date is required"}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <p className="mt-11 mb-8 text-[26px] font-semibold">Billing address</p>
          <div className="form-fields flex flex-row gap-[76px]">
            <div className="flex flex-1 flex-col gap-5">
              <div className="formElement">
                <p className="formTitle">Address</p>
                <div className="formInputWrapper">
                  <input
                    className="formTextInput"
                    {...register("address", {
                      required: true,
                    })}
                  />
                </div>
                <div className="validationError">
                  {formState.errors?.address?.type === "required" && "Street is required"}
                </div>
              </div>

              <div className="formElement">
                <p className="formTitle">Zip Code</p>
                <div className="formInputWrapper">
                  <input
                    className="formTextInput"
                    {...register("zip", {
                      required: true,
                    })}
                  />
                </div>
                <div className="validationError">
                  {formState.errors?.zip?.type === "required" && "Zip Code is required"}
                </div>
              </div>

              <div className="formElement">
                <p className="formTitle">Country</p>
                <div className="formInputWrapper">
                  <select
                    className="formSelect"
                    placeholder="Country"
                    {...register("country", {
                      required: true,
                    })}
                  >
                    <option value="">Select Country</option>
                    {sortedCountries.map((e, i) => {
                      const [key, value] = e;
                      return (
                        <option key={"country" + i} value={key}>
                          {value}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="validationError">
                  {formState.errors?.country?.type === "required" && "Country is required"}
                </div>
              </div>
            </div>
            <div className="flex flex-1 flex-col gap-5">
              <div className="formElement">
                <p className="formTitle">City</p>
                <div className="formInputWrapper">
                  <input
                    className="formTextInput"
                    {...register("city", {
                      required: true,
                    })}
                  />
                </div>
                <div className="validationError">
                  {formState.errors?.city?.type === "required" && "Address is required"}
                </div>
              </div>

              <div className="formElement">
                <p className="formTitle">State/Province</p>
                <div className="formInputWrapper">
                  <input
                    className="formTextInput"
                    {...register("state", {
                      required: true,
                    })}
                  />
                </div>
                <div className="validationError">
                  {formState.errors?.state?.type === "required" && "State is required"}
                </div>
              </div>
            </div>
          </div>
          <div className="mt-10 flex justify-end gap-5">
            <button onClick={onClose} className="btn-secondary">
              Cancel
            </button>
            <button>Save changes</button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default PaymentMethodAddEdit;
