import { ChevronLeft } from "assets/icons";
import {
  Loader,
  Pricing,
  SubscriptionPurchaseStepThree,
  SubscriptionPurchaseStepTwo,
} from "components";
import { useAuth } from "lib/auth";
import {
  usePublicSubscriptionTypesQuery,
  usePurchaseSubscriptionMutation,
} from "modules/subscription/subscriptionActions";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const PurchaseSubscription = () => {
  const navigate = useNavigate();
  const { currentSubscription, getCurrentSubscription } = useAuth();
  const [selectedSubscription, setSelectedSubscription] = useState(null);
  const [step, setStep] = useState(0);
  const [isLoadingCurrentSub, setIsLoadingCurrentSub] = useState(0);
  const [paymentOption, setPaymentOption] = useState("MonthlyOnce");
  const [couponCode, setCouponCode] = useState("");
  const [priceData, setPriceData] = useState(null);
  const { data, isLoading } = usePublicSubscriptionTypesQuery();
  const [purchaseSubscription, { isLoading: isLoadingPurchase }] =
    usePurchaseSubscriptionMutation();

  useEffect(() => {
    // if (currentSubscription) navigate(`/`);
  }, []);

  const onSelectSubscription = useCallback(
    (sub) => {
      setSelectedSubscription(sub);
      setStep(1);
    },
    [selectedSubscription]
  );

  const onSelectPaymentOption = (option) => {
    setPaymentOption(option);
  };

  const onNextPayment = () => {
    if (!currentSubscription) setStep(2);
    else purchase();
  };

  const purchase = async (card = null) => {
    let body = {
      paymentApproach: paymentOption,
      subscriptionTypeId: selectedSubscription?.id,
    };
    if (card) body.card = card;
    if (couponCode) body.couponCode = couponCode;
    let result = await purchaseSubscription(body);
    if (result.error) {
      toast.error(result?.error?.data?.message || "Something went wrong");
    } else {
      setIsLoadingCurrentSub(true);
      await getCurrentSubscription();
      setIsLoadingCurrentSub(false);
      toast.success("Purchased successfully!");
      navigate(`/customer/subscriptions`);
    }
  };

  return (
    <div className="relative">
      <Loader isLoading={isLoadingPurchase || isLoadingCurrentSub} />
      {step === 0 && (
        <Pricing
          data={data}
          isLoading={isLoading}
          onSelect={onSelectSubscription}
          from="purchase"
        />
      )}
      {(step === 1 || step === 2) && (
        <div className="bg-bleu-850 p-10 pt-20 text-center text-white md:p-0 md:px-40 md:pt-[120px] md:pb-32">
          <a className="cursor-pointer" onClick={() => setStep((p) => p - 1)}>
            <ChevronLeft />
          </a>
          <div className="exoTitle mt-1 mb-11">Subscription Plan and Payment</div>
          {step === 1 && !isLoadingPurchase && (
            <SubscriptionPurchaseStepTwo
              selectedSubscription={selectedSubscription}
              paymentOption={paymentOption}
              priceData={priceData}
              onSelect={onSelectPaymentOption}
              onNext={onNextPayment}
              setPriceData={setPriceData}
              couponCode={couponCode}
              setCouponCode={setCouponCode}
            />
          )}
          {step === 2 && (
            <SubscriptionPurchaseStepThree
              selectedSubscription={selectedSubscription}
              priceData={priceData}
              callback={purchase}
            />
          )}
          {/* {isLoadingPurchase && <span className="loader mb-20"></span>} */}
          {/* <div className="text-center">
            <p className="p-20">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Non netus maecenas viverra
              elit fermentum, dolor faucibus non. Nec eget at neque massa dui.
            </p>
          </div> */}
        </div>
      )}
    </div>
  );
};

export default PurchaseSubscription;
