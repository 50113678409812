import { Loader } from "components";
import { useAuth } from "lib/auth";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

const ProfileUpdatePassword = ({ onClose }) => {
  const { register, handleSubmit, formState, watch } = useForm();
  const { validateCurrentAndUpdatePassword, logout } = useAuth();

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmitForm = async (data) => {
    setIsLoading(true);
    let result = await validateCurrentAndUpdatePassword(data);
    setIsLoading(false);
    if (result.success) {
      toast.success("Successfully updated. Please login again.");
      logout();
    } else {
      toast.error(result.message);
    }
  };

  const newPassword = watch("newPassword");

  const isEqualToNewPassword = (val) => {
    return val === newPassword;
  };

  return (
    <div className="relative">
      <Loader isLoading={isLoading} />
      <h2 className="page-title">Update Password</h2>
      <form onSubmit={handleSubmit(handleSubmitForm)}>
        <div className="mt-12">
          <div className="form-fields flex flex-row gap-[76px]">
            <div className="flex flex-1 flex-col gap-10">
              <div className="formElement">
                <p className="formTitle">Current Password</p>
                <div className="formInputWrapper">
                  <input
                    type="password"
                    className="formTextInput"
                    placeholder="********"
                    {...register("currentPassword", {
                      minLength: 6,
                      required: true,
                    })}
                  />
                </div>
                <div className="validationError">
                  {formState.errors?.currentPassword?.type === "required" &&
                    "Current Password is required"}
                  {formState.errors?.currentPassword?.type === "minLength" &&
                    "Password should be at least 6 characters"}
                </div>
              </div>

              <div className="formElement">
                <p className="formTitle">New Password</p>
                <div className="formInputWrapper">
                  <input
                    type="password"
                    className="formTextInput"
                    placeholder="********"
                    {...register("newPassword", {
                      minLength: 6,
                      required: true,
                    })}
                  />
                </div>
                <div className="validationError">
                  {formState.errors?.newPassword?.type === "required" && "New Password is required"}
                  {formState.errors?.newPassword?.type === "minLength" &&
                    "Password should be at least 6 characters"}
                </div>
              </div>

              <div className="formElement">
                <p className="formTitle">Confirm Password</p>
                <div className="formInputWrapper">
                  <input
                    type="password"
                    className="formTextInput"
                    placeholder="********"
                    {...register("confirmPassword", {
                      required: true,
                      validate: isEqualToNewPassword,
                    })}
                  />
                </div>
                <div className="validationError">
                  {formState.errors?.confirmPassword?.type === "required" &&
                    "Confirm Password is required"}
                  {formState.errors?.confirmPassword?.type === "validate" &&
                    "Passwords didn't match"}
                </div>
              </div>
            </div>
          </div>
          <div className="mt-10 flex justify-end gap-5">
            <button onClick={onClose} className="btn-secondary">
              Cancel
            </button>
            <button>Save changes</button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ProfileUpdatePassword;
