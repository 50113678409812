import LabelWithValue from "components/label-with-value/label-with-value";
import { getDateFromUnix, subscriptionPaymentTypeText, subscriptionStatusText } from "lib/utils";
import { Link } from "react-router-dom";

const ProfileBasicInfo = ({ data, containerClass }) => {
  return (
    <div className={`flex flex-col gap-8 ${containerClass}`}>
      <div className="flex flex-row">
        <LabelWithValue
          containerClass="flex-1"
          label="Subscription type"
          value={
            <>
              {data?.subscription_type?.name} (
              <Link to={`/customer/subscriptions`} className="text-bleu-100">
                Purchase/Upgrade
              </Link>
              )
            </>
          }
        />
        <LabelWithValue containerClass="flex-1" label="Seats" value={data?.seats?.paidSeatsCount} />
        <LabelWithValue
          containerClass="flex-1"
          label="Expiry Date"
          value={getDateFromUnix(
            data?.expiration_date?._seconds,
            data?.expiration_date?._nanoseconds
          )}
        />
      </div>
      <div className="flex flex-row">
        <LabelWithValue
          containerClass="flex-1"
          label="Payment"
          value={subscriptionPaymentTypeText(data?.paymentApproach)}
        />
        <LabelWithValue
          containerClass="flex-1"
          label="Joined"
          value={getDateFromUnix(data?.start_date?._seconds, data?.start_date?._nanoseconds)}
        />
        <LabelWithValue
          containerClass="flex-1"
          label="Status"
          value={subscriptionStatusText(data?.status)}
        />
      </div>
    </div>
  );
};

export default ProfileBasicInfo;
