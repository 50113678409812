import { Loader } from "components";
import React from "react";

const SeatClearConfirmModal = ({ loading, onConfirm, onClose }) => {
  return (
    <div className="relative w-[360px]">
      <Loader isLoading={loading} />
      <h2 className="font-exo text-thirtytwo font-semibold">Are You Sure?</h2>
      <p className="pb-4 text-sixteen font-semibold text-gris-600">
        This action would clear the selected seat.
      </p>
      <div className="flex gap-5">
        <button className="btn-secondary h-[40px]" onClick={onClose}>
          Cancel
        </button>
        <button onClick={onConfirm}>Confirm</button>
      </div>
    </div>
  );
};

export default SeatClearConfirmModal;
