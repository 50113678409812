import { formatSubscriptionTypeData } from "modules/subscription/subscriptionActions";

const SubscriptionItem = ({ item, onEdit }) => {
  let { id, data } = item;
  data = formatSubscriptionTypeData(data);
  return (
    <div className="flex-col rounded-[3px] border border-gris-850 px-[30px] py-[24px]">
      <div className="flex-col space-y-4">
        <h3 className="text-2xl font-semibold text-bleu-100">{data?.name || "N/A"} </h3>
        <p className="text-eight text-bleu-850">{data?.description || "N/A"}</p>
        <div className="flex">
          <div className="flex-1">
            <p className="text-ten font-bold text-bleu-100">Monthly pricing</p>
            <p className="text-base text-bleu-850">${data?.monthly_fee || "N/A"}</p>
          </div>
          <div className="flex-1">
            <p className="text-ten font-bold text-bleu-100">Annual pricing</p>
            <p className="text-base text-bleu-850">${data?.annual_fee || "N/A"}</p>
          </div>
        </div>

        <div className="flex">
          <div className="flex-1">
            <p className="text-ten font-bold text-bleu-100">monthly seat pricing</p>
            <p className="text-base text-bleu-850">${data?.extra_seat_monthly_fee || "N/A"}</p>
          </div>
          <div className="flex-1">
            <p className="text-ten font-bold text-bleu-100">Annual seat pricing</p>
            <p className="text-base text-bleu-850">${data?.extra_seat_annual_fee || "N/A"}</p>
          </div>
        </div>
      </div>
      <button className="mt-[86px]" onClick={() => onEdit(item)}>
        Edit Subscription
      </button>
    </div>
  );
};

export default SubscriptionItem;
