import { useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

export default function LoginForm({ handleLogin, toggleLoginForgotPwd, onSuccessCallback }) {
  const { register, handleSubmit, formState } = useForm();
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmitForm = async (data) => {
    setIsLoading(true);
    try {
      await handleLogin(data);
      onSuccessCallback();
    } catch (e) {
      toast.error(`Error in username or password`);
    }
    setIsLoading(false);
  };

  return (
    <form onSubmit={handleSubmit(handleSubmitForm)}>
      <div className="exoTitle pt-16 pb-9">Login</div>
      <div className="min-w-[382px]">
        <input
          className="pl-2"
          name="email"
          placeholder="Email Address"
          type="email"
          {...register("email", {
            pattern: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,5})+$/,
            required: true,
          })}
        />
      </div>
      <div className="validationError">
        {formState.errors?.email?.type === "required" && "Email is required"}
        {formState.errors?.email?.type === "pattern" && "Email is not valid"}
      </div>
      <div className="mt-3">
        <input
          className="pl-2"
          name="password"
          placeholder="Password"
          type="password"
          {...register("password", { required: true })}
        />
      </div>
      <div className="validationError">
        {formState.errors?.password?.type === "required" && "Password is required"}
      </div>
      <div className="mt-5 mb-16 font-exo text-lg font-semibold leading-[24px] text-bleu-100">
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a href="#" onClick={toggleLoginForgotPwd}>
          Forgot Password?
        </a>
      </div>
      {!isLoading && <button className="mb-20 w-[65%]">Login</button>}
      {isLoading && <span className="loader mb-20"></span>}
    </form>
  );
}
