import "react-toastify/dist/ReactToastify.css";

import { Footer, Header, ProtectedLayout } from "components";
import { AuthProvider, useAuth } from "lib/auth";
import {
  Coupons,
  Customer,
  Customers,
  DownloadApp,
  Home,
  Invoice,
  Login,
  ManageAppLinks,
  MyProfile,
  MySubscription,
  MySubscriptionSeats,
  PaymentHistory,
  PaymentMethods,
  Payments,
  PrivacyPolicy,
  PurchaseSubscription,
  Register,
  Subscriptions,
  TermsAndConditions,
} from "pages";
import { Provider } from "react-redux";
import { Navigate, Outlet, Route, Routes, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { store } from "redux/store";

const RedirectFromAuthRoutesIfLoggedIn = () => {
  const { isUserLoggedIn } = useAuth();
  if (isUserLoggedIn()) return <Navigate to="/" />;
  return <Outlet />;
};

function App() {
  const { pathname } = useLocation();

  return (
    <Provider store={store}>
      <AuthProvider>
        <Routes>
          <Route
            element={
              <div className="relative">
                <Header />
                <Outlet />
                <Footer />
              </div>
            }
          >
            <Route path="/" element={<Home />} />
            <Route path="/terms-conditions" element={<TermsAndConditions />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/purchase" element={<PurchaseSubscription />} />

            <Route element={<RedirectFromAuthRoutesIfLoggedIn />}>
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
            </Route>
          </Route>

          <Route
            element={
              <ProtectedLayout>
                <Outlet />
              </ProtectedLayout>
            }
          >
            <Route path="/admin/customers" element={<Customers />} />
            <Route path="/admin/customers/:id" element={<Customer />} />
            <Route path="/admin/subscriptions" element={<Subscriptions />} />
            <Route path="/admin/payments" element={<Payments />} />
            <Route path="/admin/payments/:id" element={<Invoice />} />
            <Route path="/admin/coupons" element={<Coupons />} />
            <Route path="/admin/app-links" element={<ManageAppLinks />} />
            <Route path="/customer/app-links" element={<DownloadApp />} />
            <Route path="/customer/profile" element={<MyProfile />} />
            <Route path="/customer/subscriptions" element={<MySubscription />} />
            <Route path="/customer/subscription-seats" element={<MySubscriptionSeats />} />
            <Route path="/customer/payment-history" element={<PaymentHistory />} />
            <Route path="/customer/payment-history/:id" element={<Invoice />} />
            <Route path="/customer/payment-methods" element={<PaymentMethods />} />
          </Route>
        </Routes>
        <ToastContainer position="top-right" autoClose={5000} pauseOnHover />
      </AuthProvider>
    </Provider>
  );
}

export default App;
