import "./signup.css";

import { HashLink } from "react-router-hash-link";

export default function Signup() {
  return (
    <div className="signup p-10 md:p-0 md:px-40 md:py-[120px]">
      <div className="font-exo text-lg font-semibold leading-[24px]">Sign Up</div>
      <div className="exoTitle mb-[18px] mt-2 text-bleu-100 md:w-[555px]">
        It's time to upgrade your design software and design cabinets in 3D!
      </div>
      <div className="mb-9 text-xl leading-[30px] md:w-[555px]">
        Sign up today and find out why Nvision3D woodworking software will soon become your go-to
        and favorite 3D modelling software. Design in 3D and visualize your design in a room of your
        choosing to fully immerse yourself in the experience. Win clients, save time, and save costs
        — it's that easy!
      </div>
      <div>
        <HashLink smooth to="/#price">
          {" "}
          <button>Sign up, today!</button>
        </HashLink>
      </div>
    </div>
  );
}
