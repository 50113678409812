import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const PROFILE_API_REDUCER_KEY = "profile-api";

export const profileApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BACKEND_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem("authToken");
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getCustomerProfile: builder.query({
      providesTags: ["customer-profile"],
      query: (id) => `/customers/${id}`,
    }),
    updateCustomerProfile: builder.mutation({
      invalidatesTags: ["customer-profile"],
      query: ({ id, ...body }) => ({
        body: body,
        method: "PATCH",
        url: `/customers/${id}`,
      }),
    }),
  }),
  reducerPath: PROFILE_API_REDUCER_KEY,
  tagTypes: ["customer-profile"],
});

export const { useGetCustomerProfileQuery, useUpdateCustomerProfileMutation } = profileApi;
