import axios from "axios";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
});

axiosInstance.interceptors.request.use(function (config) {
  const token = localStorage.getItem("authToken");
  config.headers.Authorization = token ? `Bearer ${token}` : "";
  return config;
});

export default axiosInstance;


export const handleRequest = async (req) => {
  try {
    let res = await req();
    return {
      data: res.data,
      success: true,
    };
  } catch (e) {
    let message = "Something went wrong.";
    if (e.response && e.response.data && e.response.data.message) message = e.response.data.message;
    return {
      message,
      success: false,
    };
  }
};
