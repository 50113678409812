export default function Logout() {
  return (
    <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.82258 2.46885C1.90121 4.52242 0 7.88114 0 11.6825C0 17.9254 5.1379 22.9863 11.4768 23C17.8064 23.0137 22.9907 17.9345 23 11.7008C23.0046 7.89939 21.1034 4.53154 18.1867 2.47341C17.6441 2.09464 16.8883 2.25436 16.5637 2.8248L15.831 4.10714C15.5575 4.5863 15.6873 5.19325 16.1371 5.52182C18.0615 6.92737 19.2903 9.15435 19.2903 11.678C19.295 15.8901 15.8357 19.3492 11.5 19.3492C7.25242 19.3492 3.68185 15.9631 3.70968 11.6323C3.72359 9.26844 4.85504 6.9867 6.86754 5.51726C7.31734 5.18868 7.44254 4.58174 7.16895 4.10714L6.43629 2.8248C6.11169 2.25893 5.36048 2.09008 4.82258 2.46885ZM9.64516 12.0476V1.09524C9.64516 0.488293 10.1413 0 10.7581 0H12.2419C12.8587 0 13.3548 0.488293 13.3548 1.09524V12.0476C13.3548 12.6545 12.8587 13.1428 12.2419 13.1428H10.7581C10.1413 13.1428 9.64516 12.6545 9.64516 12.0476Z"
        fill="white"
      />
    </svg>
  );
}
