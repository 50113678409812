const Task = ({ width = 50, height = 50 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M39.5833 6.24992H30.875C30 3.83325 27.7083 2.08325 25 2.08325C22.2917 2.08325 20 3.83325 19.125 6.24992H10.4167C8.125 6.24992 6.25 8.12492 6.25 10.4166V39.5833C6.25 41.8749 8.125 43.7499 10.4167 43.7499H39.5833C41.875 43.7499 43.75 41.8749 43.75 39.5833V10.4166C43.75 8.12492 41.875 6.24992 39.5833 6.24992ZM25 6.24992C26.1458 6.24992 27.0833 7.18742 27.0833 8.33325C27.0833 9.47908 26.1458 10.4166 25 10.4166C23.8542 10.4166 22.9167 9.47908 22.9167 8.33325C22.9167 7.18742 23.8542 6.24992 25 6.24992ZM29.1667 35.4166H14.5833V31.2499H29.1667V35.4166ZM35.4167 27.0833H14.5833V22.9166H35.4167V27.0833ZM35.4167 18.7499H14.5833V14.5833H35.4167V18.7499Z"
        fill="#00A0EE"
      />
    </svg>
  );
};

export default Task;
