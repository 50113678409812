import "./download-usage.css";

import { HashLink } from "react-router-hash-link";

export default function DownloadUsage() {
  return (
    <div className="download p-10 md:min-h-[616px] md:p-0 md:px-40 md:py-[120px]">
      <div className="blueTitle">Download &amp; Usage</div>
      <div className="exoTitle mt-2 mb-5 inline-block md:w-[555px]">
        Book a Demo for Fabricate
        <br />
        Thirty minutes is all you need!
      </div>
      <div className="mb-10 inline-block text-xl leading-[30px] lg:w-[763px]">
        Our 3D cabinet design software removes the hassle of manual drafts while speeding up the
        design process with accuracy.
        <br />
        Gone are the days of presenting 2D drafts to clients who need to visualize a 3D cabinet. If
        the client has any changes, you can adjust the 3D rendering in real-time and focus on
        closing deals rather than returning to the designer, waiting for the new design, and
        returning to the client.
      </div>
      <div>
        <HashLink smooth to="/#contactus">
          <button>Book a Demo</button>
        </HashLink>
      </div>
    </div>
  );
}
