import { Loader } from "components";
import { useAddCouponMutation } from "modules/coupon/couponService";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

const CouponAddForm = ({ onClose }) => {
  const [addCoupon, { isLoading }] = useAddCouponMutation();
  const { register, handleSubmit, formState, watch, setValue } = useForm();
  const discount_type = watch("type");

  const generateCode = () => {
    setValue("code", Math.random().toString(36).slice(2).toUpperCase());
  };

  const handleSubmitForm = async (data) => {
    let result = await addCoupon(data);
    if (result.error) {
      toast.error(result?.error?.data?.message || "Something went wrong");
    } else {
      toast.success("Coupon added successfully");
      onClose();
    }
  };

  return (
    <div className="relative w-[534px]">
      <Loader isLoading={isLoading} />
      <h2 className="text-blue-850 font-exo text-3xl font-semibold">Add Coupon</h2>
      <form onSubmit={handleSubmit(handleSubmitForm)}>
        <div className="subscription-add-edit-form-container mt-12">
          <div className="form-fields flex flex-row gap-[76px]">
            <div className="flex flex-1 flex-col gap-10">
              <div className="formElement">
                <p className="formTitle">Name</p>
                <div className="formInputWrapper">
                  <input
                    className="formTextInput"
                    {...register("name", {
                      required: true,
                    })}
                  />
                </div>
                <div className="validationError">
                  {formState.errors?.name?.type === "required" && "Name is required"}
                </div>
              </div>

              <div className="formElement">
                <p className="formTitle">Discount Type</p>
                <div className="mt-3 flex gap-6">
                  <div className="flex items-center">
                    <input
                      className={`formInputRadio`}
                      type="radio"
                      value={"percentage"}
                      {...register("type", {
                        required: true,
                      })}
                    />
                    <label className="formInputRadioLabel">Percentage</label>
                  </div>
                  <div className="flex items-center">
                    <input
                      className={`formInputRadio`}
                      type="radio"
                      value={"usd"}
                      {...register("type", {
                        required: true,
                      })}
                    />
                    <label className="formInputRadioLabel">USD</label>
                  </div>
                </div>

                <div className="validationError">
                  {formState.errors?.type?.type === "required" && "Discount type is required"}
                </div>
              </div>

              {/*discount_type === "percentage" && (
                <div className="formElement">
                  <p className="formTitle">Percentage</p>
                  <div className="formInputWrapper">
                    <select
                      className="formTextInput"
                      {...register("percentage_value", {
                        pattern: /^[+-]?([0-9]*[.])?[0-9]+$/,
                        required: true,
                      })}
                    >
                      <option value="">Please select</option>
                      <option value="15">15% Off Annual</option>
                      <option value="20">20% Off Annual</option>
                      <option value="25">25% Off Annual</option>
                    </select>
                  </div>
                  <div className="validationError">
                    {formState.errors?.percentage_value?.type === "required" &&
                      "Amount is required"}
                    {formState.errors?.percentage_value?.type === "pattern" &&
                      "Please enter valid number"}
                  </div>
                </div>
                    )*/}

              {/* {discount_type === "usd" && ( */}
              <div className="formElement">
                <p className="formTitle">Discount Value</p>
                <div className="formInputWrapper">
                  <input
                    className="formTextInput"
                    {...register("value", {
                      pattern: /^[+-]?([0-9]*[.])?[0-9]+$/,
                      required: true,
                    })}
                  />
                  {discount_type === "usd" && <span className="text-gris-600">USD</span>}
                  {discount_type === "percentage" && <span className="text-gris-600">%</span>}
                </div>
                <div className="validationError">
                  {formState.errors?.value?.type === "required" && "Value is required"}
                  {formState.errors?.value?.type === "pattern" && "Please enter valid number"}
                </div>
              </div>
              {/* )} */}
            </div>
            <div className="flex flex-1 flex-col gap-10">
              <div className="formElement">
                <p className="formTitle">Code</p>
                <div className="formInputWrapper">
                  <input
                    className="formTextInput"
                    {...register("code", {
                      // pattern: /^[+-]?([0-9]*[.])?[0-9]+$/,
                      required: true,
                    })}
                  />
                  <span
                    onClick={generateCode}
                    className="flex h-[28px] cursor-pointer items-center justify-center rounded-md bg-bleu-100 px-2 py-1 text-white"
                  >
                    Generate
                  </span>
                </div>
                <div className="validationError">
                  {formState.errors?.code?.type === "required" && "Code is required"}
                </div>
              </div>

              <div className="formElement">
                <p className="formTitle">Expiry Date</p>
                <div className="formInputWrapper calenderWrapper">
                  <input
                    type="date"
                    className="formTextInput"
                    {...register("expiration_date", {
                      required: true,
                    })}
                  />
                </div>
                <div className="validationError">
                  {formState.errors?.expiration_date?.type === "required" &&
                    "Expiry Date is required"}
                </div>
              </div>
            </div>
          </div>
          <div className="mt-10 flex justify-end gap-5">
            <button className="btn-secondary">Cancel</button>
            <button>Save changes</button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default CouponAddForm;
