import "./about-us.css";

export default function AboutUs() {
  return (
    <div className="about p-10 md:p-0 md:px-40 md:py-[140px]" id="about">
      <div className="blueTitle">About Us</div>
      <div className="grid grid-cols-1 md:grid-cols-2 md:gap-x-11">
        <div className="exoTitle mb-5 mt-1 text-gris-700">
          We're on a mission to creating the best 3D cabinet design software.
        </div>
        <div></div>
        <div>
          <div className="mb-5 text-xl leading-[30px]">
            NVision3D was founded in 2020 with a vision to make 3D design software accessible to
            everyone. The revolutionary software allows users to design in 3D from the start; say
            goodbye to 2D designs that require rendering. In the future, we aim to add room
            visualizations so users can see their designs in real time.
          </div>
          <div className="text-gris-700">
            The gaming industry has made 3D design intuitive for the next generation of cabinet and
            woodworking designers. Further, in the future, we’ll include VR technology to help you
            fully immerse yourself in your rooms filled with beautiful designs created using
            Nvision3D. Users will have the ability to design multiple storey homes prior to
            architectural or contractor consulting. A picture truly is worth more than a thousand
            words. With Nvision3D we want to give the designers, users, and CNC manufacturers the
            control to make their visions a reality.
          </div>
        </div>
        <div>
          <div className="mb-5 text-twentysix font-bold leading-9 text-bleu-100">
            With Nvision3D we want to give the designers, users, and CNC manufacturers the control
            to make their visions a reality.
          </div>
          <div className="text-gris-700">
            Our cabinet-design software is the next generation of design to suit the next generation
            of designers, homeowners, woodworkers, engineers, and machinery operators. Whether
            you're a homeowner designing your first kitchen cabinet, a CNC manufacturer, or a
            seasoned woodworker, Nvision3D will give you the confidence and features needed to
            design cabinets and closets you’ve only dreamt of.
          </div>
        </div>
      </div>
    </div>
  );
}
