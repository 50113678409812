import { ChevronLeft } from "assets/icons";
import {
  BasicInformationView,
  CustomerPaymentHistory,
  PageHeader,
  SubscriptionSeats,
  TabMenu,
} from "components/admin";
import { useGetAdminPaymentHistoryQuery } from "modules/payment-history/paymentHistoryService";
import { useGetCustomerProfileQuery } from "modules/profile/profileActions";
import { useGetSubscriptionSeatsQuery } from "modules/seat/seatService";
import { useState } from "react";
import { Link, useParams } from "react-router-dom";

const DEFAULT_PAYMENT_PARAMS = {
  customerId: "",
  endBeforeId: "",
  limit: 10,
  startAfterId: "",
  toFirst: "true",
  toLast: "",
};

function Customer() {
  let { id } = useParams();
  const [paymentParams, setPaymentParams] = useState({ ...DEFAULT_PAYMENT_PARAMS, customerId: id });
  const [currentView, setCurrentView] = useState("basic");
  const changeView = (view) => {
    setCurrentView(view);
  };

  const { data, isLoading } = useGetCustomerProfileQuery(id);
  const { data: customerSeats, isLoading: isLoadingSeats } = useGetSubscriptionSeatsQuery(id);
  const {
    data: customerInvoices,
    isLoading: isLoadingInvoices,
    isFetching: isFetchingInvoices,
  } = useGetAdminPaymentHistoryQuery(paymentParams);

  const views = [
    { label: "Basic Information", value: "basic" },
    { label: "Subscription Seats", value: "subscription_seats" },
    { label: "Payment History", value: "payment_history" },
  ];

  const onChangePaginationHandler = (obj) => {
    setPaymentParams((p) => ({ ...p, ...obj }));
  };

  return (
    <>
      <PageHeader
        title={
          <>
            <Link to="/admin/customers">
              <ChevronLeft />
            </Link>{" "}
            {data?.data?.first_name} {data?.data?.last_name}
          </>
        }
        searchField={false}
      />
      <TabMenu views={views} currentView={currentView} changeView={changeView} />
      {isLoading || isLoadingSeats || isLoadingInvoices ? (
        <p className="pt-8">Loading data...</p>
      ) : (
        <>
          {currentView === "basic" && <BasicInformationView customer={data?.data || {}} />}
          {currentView === "subscription_seats" && (
            <SubscriptionSeats customerSeats={customerSeats} />
          )}
          {currentView === "payment_history" && (
            <CustomerPaymentHistory
              data={customerInvoices}
              isLoading={isLoadingInvoices || isFetchingInvoices}
              onChangePagination={onChangePaginationHandler}
            />
          )}
        </>
      )}
    </>
  );
}

export default Customer;
