export default function Avatar() {
  return (
    <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.5 0C5.152 0 0 5.152 0 11.5C0 17.848 5.152 23 11.5 23C17.848 23 23 17.848 23 11.5C23 5.152 17.848 0 11.5 0ZM11.5 4.6C13.7195 4.6 15.525 6.4055 15.525 8.625C15.525 10.8445 13.7195 12.65 11.5 12.65C9.2805 12.65 7.475 10.8445 7.475 8.625C7.475 6.4055 9.2805 4.6 11.5 4.6ZM11.5 20.7C9.1655 20.7 6.4055 19.757 4.439 17.388C6.45335 15.8076 8.93966 14.9486 11.5 14.9486C14.0603 14.9486 16.5467 15.8076 18.561 17.388C16.5945 19.757 13.8345 20.7 11.5 20.7Z"
        fill="white"
      />
    </svg>
  );
}
