import { SUBSCRIPTION_STATUS } from "constants/index";
import { saveAs } from "file-saver";
import { useAuth } from "lib/auth";
import { useGetApplinksQuery } from "modules/app-links/appLinkActions";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const DownloadApp = () => {
  const navigate = useNavigate();
  const { currentSubscription } = useAuth();
  const { data, isLoading } = useGetApplinksQuery();
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (currentSubscription?.status !== SUBSCRIPTION_STATUS.active) {
      navigate(-1);
    }
  }, []);

  const copy = () => {
    navigator.clipboard.writeText(data?.data?.desktop);
    setCopied(true);
  };

  const download = () => {
    saveAs(data?.data?.desktop, "parametric-app.exe");
  };
  return (
    <div>
      {/* <PageHeader title="Download App!" searchField={false} /> */}
      {isLoading ? (
        <p>Fetching info...</p>
      ) : (
        <div className="m-4 flex flex-col items-center">
          <p className="p-4 font-exo text-2xl font-bold">Download for Desktop</p>
          <div className="flex flex-col items-center justify-center gap-4">
            <div
              onClick={download}
              className="flex w-[150px] cursor-pointer flex-col items-center rounded-md bg-bleu-850 p-2 hover:opacity-[.8]"
            >
              <p className="text-center text-xl font-semibold text-white">Click here to Download</p>
            </div>
            <p>or</p>
            <a onClick={copy} className="cursor-pointer text-base font-semibold text-bleu-100">
              {copied ? `Copied!` : `Copy link`}
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

export default DownloadApp;
