import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

export const profileSlice = createSlice({
  initialState,
  name: "profile",
  reducers: {},
});

export default profileSlice.reducer;
