import "./faq.css";

import { ArrowDown } from "assets/icons";
import { useState } from "react";
import { HashLink } from "react-router-hash-link";

export default function FAQ() {
  const [indexOfOpenFAQ, setIndexOfOpenFAQ] = useState(0);

  var staticFAQ = [
    {
      answer: (
        <>
          <p>
            3D design software is a computer-based program that allows users to design objects in
            three dimensions visualizing width, height, and depth (WxHxD). This mathematical
            representation makes it possible to communicate a design better than a 2D drawing would.
          </p>
        </>
      ),
      question: "What is 3D design software?",
    },
    {
      answer: (
        <>
          <p>
            The Nvision3D design software is used for visualization of woodworking and,
            specifically, designing bathroom, kitchen cabinets, and closets.
          </p>
        </>
      ),
      question: "What is Nvision3D design software used for?",
    },
    {
      answer: (
        <>
          <p>
            Our software doesn't require designers to start with a 2D design and render it in 3D.
            With Nvision3D, users design in 3 dimensions in real-time.
          </p>
        </>
      ),
      question: "What makes Nvision3D different?",
    },
    {
      answer: (
        <>
          <p>
            Not at the moment. We have an architectural visualization tool planned for the near
            future. Users will be able to see their designs in the kitchen, bathroom, or bedroom to
            get the bigger picture. These speeds up time and the decision-making process.
          </p>
        </>
      ),
      question: "Do you offer visualization tools?",
    },
    {
      answer: (
        <>
          <p>
            No, we don't have a free trial. We are confident in our product and provide an in-depth
            demo to manufacturers and designers before purchasing Nvision3D software.
          </p>
        </>
      ),
      question: "Do you have a free trial?",
    },
  ];

  const clickHandler = (i) => {
    setIndexOfOpenFAQ(i);
  };

  return (
    <div className="faq p-10 md:p-0 md:px-40 md:pt-[150px] md:pb-32" id="faq">
      <div className="blueTitle">FAQ</div>
      <div className="exoTitle mb-14 mt-1">Frequently Asked Questions</div>
      <div>
        {staticFAQ.map((e, i) => (
          <div
            key={`faq${i}`}
            className={["faqEntry p-4 md:p-10", indexOfOpenFAQ === i ? "open" : ""].join(" ")}
            onClick={() => clickHandler(i)}
          >
            <div className="flex">
              <div className="flex-1 grow font-exo text-base leading-[30px] md:text-twentytwo">
                {e.question}
              </div>
              <div>
                <div className="faqEntryArrow  ml-4 md:ml-20">
                  <ArrowDown />
                </div>
              </div>
            </div>
            <div className="faqAnswer">{e.answer}</div>
          </div>
        ))}
      </div>
      <div className="mt-20 mb-5 inline-block max-w-[631px]">
        Sign up today and step into the future of 3D design and room visualization. We offer
        excellent support to answer any questions you may have and include a range of templates to
        get you started.
      </div>
      <div>
        <HashLink smooth to="/#price">
          <button>Sign up</button>
        </HashLink>
      </div>
    </div>
  );
}
