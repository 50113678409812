const Shield = ({ width = 50, height = 50 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M35.4167 22.9167C36.125 22.9167 36.8125 23 37.5 23.1042V13.0625L21.875 6.25L6.25 13.0625V23.2917C6.25 32.75 12.9167 41.6042 21.875 43.75C23.0208 43.4792 24.125 43.0833 25.2083 42.6042C23.7708 40.5625 22.9167 38.0833 22.9167 35.4167C22.9167 28.5208 28.5208 22.9167 35.4167 22.9167Z"
        fill="#00A0EE"
      />
      <path
        d="M35.4167 27.0833C30.8125 27.0833 27.0833 30.8124 27.0833 35.4166C27.0833 40.0208 30.8125 43.7499 35.4167 43.7499C40.0208 43.7499 43.75 40.0208 43.75 35.4166C43.75 30.8124 40.0208 27.0833 35.4167 27.0833ZM35.4167 29.9583C36.7083 29.9583 37.75 31.0208 37.75 32.2916C37.75 33.5624 36.6875 34.6249 35.4167 34.6249C34.1458 34.6249 33.0833 33.5624 33.0833 32.2916C33.0833 31.0208 34.125 29.9583 35.4167 29.9583ZM35.4167 41.1458C33.4792 41.1458 31.7917 40.1874 30.75 38.7083C30.8542 37.2083 33.8958 36.4583 35.4167 36.4583C36.9375 36.4583 39.9792 37.2083 40.0833 38.7083C39.0417 40.1874 37.3542 41.1458 35.4167 41.1458Z"
        fill="#00A0EE"
      />
    </svg>
  );
};

export default Shield;
