import {
  Modal,
  MySubscriptionItem,
  SubscriptionChangeConfirmModal,
  SubscriptionPurchaseStepTwo,
} from "components";
import { PageHeader } from "components/admin";
import { SUBSCRIPTION_STATUS, SUBSCRIPTION_STEP_TWO_PURCHASE_TYPE } from "constants/index";
import { useAuth } from "lib/auth";
import { getDateFromUnix } from "lib/utils";
import {
  useFetchSubscriptionUpgradeCostMutation,
  usePublicSubscriptionTypesQuery,
  usePurchaseSubscriptionMutation,
  useUpgradeSubscriptionMutation,
} from "modules/subscription/subscriptionActions";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export default function MySubscription() {
  const navigate = useNavigate();
  const { currentSubscription, getCurrentSubscription, getCustomerId } = useAuth();
  const { data, isLoading } = usePublicSubscriptionTypesQuery();
  const [fetchUpgradeCost, { isLoading: isFetchingCost }] =
    useFetchSubscriptionUpgradeCostMutation();

  const [isReloadingItems, setIsReloadingItems] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showRepurchaseModal, setShowRepurchaseModal] = useState(false);
  const [selected, setSelected] = useState(null);
  const [costCalculation, setCostCalculation] = useState(null);

  const [step, setStep] = useState(0);
  const [paymentOption, setPaymentOption] = useState("MonthlyOnce");
  const [ignoreOldSeats, setIgnoreOldSeats] = useState(true);
  const [couponCode, setCouponCode] = useState("");
  const [priceData, setPriceData] = useState(null);
  const [purchaseSubscription, { isLoading: isLoadingPurchase }] =
    usePurchaseSubscriptionMutation();
  const [upgradeSubscription, { isLoading: isLoadingUpgrade }] = useUpgradeSubscriptionMutation();

  const isCurrentSubActive = useMemo(() => {
    return currentSubscription?.status === SUBSCRIPTION_STATUS.active;
  }, [currentSubscription]);

  useEffect(() => {
    getCurrentSubscription();
  }, []);

  const onChangeSubscriptionHandler = async (subs) => {
    setSelected(subs);
    if (isCurrentSubActive) {
      setCostCalculation(null);
      setShowModal(true);
      let body = {
        subscriptionTypeId: subs?.id,
      };
      let result = await fetchUpgradeCost(body);
      if (result?.error?.status) {
        toast.error(result?.error?.data?.message || "Something went wrong.");
      } else {
        setCostCalculation(result?.data);
      }
    } else {
      setShowRepurchaseModal(true);
      setStep(1);
    }
  };

  const onSelectPaymentOption = (option) => {
    setPaymentOption(option);
  };

  const onNextPayment = () => {
    purchase();
    // setStep(2);
    // if (!currentSubscription) setStep(2);
    // else purchase();
  };

  const purchase = async (card = null) => {
    let body = {
      ignoreOldSeats,
      paymentApproach: paymentOption,
      subscriptionTypeId: selected?.id,
    };
    if (card) body.card = card;
    if (couponCode) body.couponCode = couponCode;
    let result = await purchaseSubscription(body);
    if (result.error) {
      toast.error(result?.error?.data?.message || "Something went wrong");
    } else {
      setShowRepurchaseModal(false);
      setIsReloadingItems(true);
      await getCurrentSubscription();
      setIsReloadingItems(false);
      toast.success("Purchased successfully!");
      navigate(`/customer/subscriptions`);
    }
  };

  const upgrade = async () => {
    let result = await upgradeSubscription({ subscriptionTypeId: selected?.id });
    if (result?.error?.status) {
      toast.error(result?.error?.data?.message || "Something went wrong.");
    } else {
      toast.success("Subscription upgraded successfully.");
      setCostCalculation(null);
      setShowModal(false);
      setIsReloadingItems(true);
      await getCurrentSubscription();
      setIsReloadingItems(false);
    }
  };

  return (
    <>
      <PageHeader title="Subscriptions" searchField={false} />
      {isLoading && <p className="mt-12">Loading data...</p>}
      {!isLoading && !!!data?.length && <p className="mt-12">No seats found.</p>}
      <div className="mt-12 grid grid-cols-3 gap-8">
        {data?.map((item, i) => {
          return (
            <MySubscriptionItem
              key={i}
              item={item}
              currentSub={currentSubscription}
              current={
                item?.id === currentSubscription?.subscription_type?.id && isCurrentSubActive
              }
              onChangeSubscriptionHandler={onChangeSubscriptionHandler}
              isLoading={isReloadingItems}
            />
          );
        })}
      </div>
      <Modal show={showModal} onClose={() => setShowModal(false)}>
        <SubscriptionChangeConfirmModal
          isLoading={isFetchingCost || isLoadingUpgrade}
          migrationDetails={{
            amount: costCalculation?.totalNewSubscriptionPrice + costCalculation?.totalNewSeatPrice,
            plan_expires_at: getDateFromUnix(
              currentSubscription?.expiration_date?._seconds,
              currentSubscription?.expiration_date?._nanoseconds
            ),
          }}
          cancel={() => {
            setShowModal(false);
            setSelected(null);
            setCostCalculation(null);
          }}
          confirm={upgrade}
        />
      </Modal>

      <Modal
        containerClass="!p-0"
        show={showRepurchaseModal}
        onClose={() => setShowRepurchaseModal(false)}
      >
        <div className="bg-bleu-850 p-10 text-center text-white">
          <div className="exoTitle mt-1 mb-11">Subscription Plan and Payment</div>
          <SubscriptionPurchaseStepTwo
            isLoading={isLoadingPurchase}
            selectedSubscription={selected}
            paymentOption={paymentOption}
            ignoreOldSeats={ignoreOldSeats}
            setIgnoreOldSeats={setIgnoreOldSeats}
            priceData={priceData}
            onSelect={onSelectPaymentOption}
            onNext={onNextPayment}
            setPriceData={setPriceData}
            couponCode={couponCode}
            setCouponCode={setCouponCode}
            purchaseType={SUBSCRIPTION_STEP_TWO_PURCHASE_TYPE.rePurchase}
          />
          {/* {step === 2 && !isLoadingPurchase && (
            <SubscriptionPurchaseStepThree
              selectedSubscription={selected}
              priceData={priceData}
              callback={purchase}
            />
          )} */}
        </div>
      </Modal>
    </>
  );
}
