import "./basic-information-view.css";

import { SUBSCRIPTION_STATUS } from "constants/index";
import { getDateFromUnix } from "lib/utils";

function BasicInformationView({ customer }) {
  const sub = customer?.subscription;
  return (
    <>
      <div className="basicInformation">
        <dl>
          <dt>Full Name</dt>
          <dd>
            {customer?.first_name || ""} {customer?.last_name || ""}
          </dd>
          <dt>Subscription Type</dt>
          <dd>{sub?.subscriptionTypeName || "N/A"}</dd>
          <dt>Payment</dt>
          <dd>Annual ::TODO</dd>
          <dt>Expiry Date</dt>
          <dd>
            {getDateFromUnix(sub?.expiration_date?._seconds, sub?.expiration_date?._nanoseconds)}
          </dd>
        </dl>
        <dl>
          <dt>Email Address</dt>
          <dd>
            <a href={`mailto:${customer?.email}`}>{customer?.email || ""}</a>
          </dd>
          <dt>Seats</dt>
          <dd>{sub?.seats?.paidSeatsCount || "N/A"}</dd>
          <dt>Joined</dt>
          <dd>
            {getDateFromUnix(customer?.join_date?._seconds, customer?.join_date?._nanoseconds)}
          </dd>
          <dt>Status</dt>
          {/* TODO: add the condition below to see if below is active */}
          <dd
            className={
              sub?.status === SUBSCRIPTION_STATUS.active ? "!text-vert-800" : "!text-gris-300"
            }
          >
            {sub?.status}
          </dd>
        </dl>
      </div>
      {/* <button onClick={() => toggleEditCustomer()}>Edit Customer</button> */}
    </>
  );
}

export default BasicInformationView;
