import "./pricing.css";

import { useAuth } from "lib/auth";
import { formatSubscriptionTypeData } from "modules/subscription/subscriptionActions";
import { useNavigate } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

export default function Pricing({
  data = [],
  isLoading = false,
  onSelect = null,
  from = "landing",
}) {
  const auth = useAuth();
  const navigate = useNavigate();

  const purchaseClickHandler = (data) => {
    if (from === "landing") {
      if (auth.isUserLoggedIn()) {
        navigate("/purchase");
      } else {
        navigate("/register");
      }
    } else if (from === "purchase") {
      onSelect(data);
    }
  };

  return (
    <div className="pricing p-10 md:p-0 md:px-40 md:pt-[120px] md:pb-32" id="price">
      <div className="blueTitle">Pricing</div>
      <div className="exoTitle mt-1 mb-11">Pricing plans to suit all cabinet design needs</div>
      <div className="mb-4 flex flex-wrap items-end justify-center gap-2 md:gap-0">
        {isLoading && <span className="loader mb-20"></span>}
        {data.map((type, i) => {
          let { id, data: info } = type;
          info = formatSubscriptionTypeData(info);
          return (
            <div
              className={[
                "priceBlock w-full lg:min-h-[600px] lg:flex-1",
                info.isPopular || i % 2 == 1 ? "popular" : "",
              ].join(" ")}
              key={`pkg${i}`}
            >
              {info.isPopular && (
                <div className="flex justify-end">
                  <div className="inline-block rounded-[50px] bg-bleu-50 px-7 py-2">
                    most popular
                  </div>
                </div>
              )}
              <div className="font-exo text-xl font-medium leading-[30px] text-bleu-100">
                {info.name}
              </div>
              <div className="mb-[18px] flex">
                <div className="font-exo text-twentyeight font-bold leading-[46px]">$</div>
                <div className="pt-4 font-exo text-6xl font-bold leading-[46px]">
                  {info.monthly_fee}
                </div>
                <div className="pt-7 font-exo text-twentyeight leading-[46px] text-white/70">
                  /month
                </div>
              </div>
              <div className="text-sm leading-[21px] text-white/70">{info.description}</div>
              <div className="py-10">
                <button
                  onClick={() => purchaseClickHandler(type)}
                  className={[info.isPopular ? "" : "gris", "w-[100%]"].join(" ")}
                >
                  Purchase
                </button>
              </div>
              <div className="font-exo text-xl font-semibold leading-[30px]">{info.name}</div>
              <div className="mt-2">
                <ul className="packageFeatures">
                  {info.features.map((f, fi) => (
                    <li key={`pkg${i}${fi}`}>{f}</li>
                  ))}
                </ul>
              </div>
            </div>
          );
        })}
      </div>
      <div className="text-sm leading-[21px] text-white/60">
        Prices shown are charged monthly.{" "}
        <HashLink className="navLink" smooth to="/#contactus">
          Contact us
        </HashLink>{" "}
        for custom pricing solutions and schedule a demo.
      </div>
    </div>
  );
}
