import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const PAYMENT_METHOD_API_REDUCER_KEY = "payment-method-api";

export const paymentMethodApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BACKEND_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem("authToken");
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    addPaymentMethod: builder.mutation({
      invalidatesTags: ["PaymentMethods"],
      query: (contact) => ({
        body: contact,
        method: "POST",
        url: "/customers/paymentmethods",
      }),
    }),
    getPaymentMethods: builder.query({
      providesTags: ["PaymentMethods"],
      query: () => "/customers/paymentmethods",
    }),
    setDefaultPaymentMethod: builder.mutation({
      invalidatesTags: ["PaymentMethods"],
      query: (body) => ({
        body: body,
        method: "POST",
        url: `/customers/paymentmethods/set-default`,
      }),
    }),
    updatePaymentMethod: builder.mutation({
      invalidatesTags: ["PaymentMethods"],
      query: ({ id, ...body }) => ({
        body: body,
        method: "PUT",
        url: `/customers/paymentmethods/${id}`,
      }),
    }),
  }),
  reducerPath: PAYMENT_METHOD_API_REDUCER_KEY,
  tagTypes: ["PaymentMethods"],
});

export const {
  useAddPaymentMethodMutation,
  useGetPaymentMethodsQuery,
  useSetDefaultPaymentMethodMutation,
  useUpdatePaymentMethodMutation,
} = paymentMethodApi;
