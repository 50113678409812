const PaginationLeft = ({ width = 7, height = 11 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 7 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.38227 0L0 5.5L5.38227 11L7 9.34712L3.23394 5.5L7 1.65288L5.38227 0Z"
        fill="white"
      />
    </svg>
  );
};

export default PaginationLeft;
