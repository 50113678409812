import "./features.css";

import { Chart, Shield, Task } from "assets/icons";

export default function Features() {
  const staticFeatures = [
    {
      description:
        "We offer a range of templates to choose from before customizing your design. Prefer to start from scratch? That’s fine, too! With Nvision3D you are in control and can choose which design methodology to follow when working on your project.",
      icon: <Task />,
      title: "Start fresh or with a template",
    },
    {
      description:
        "Forget about the days of designing in 2D and waiting for a 3D render. With Nvision3D, you design in 3D from the start. Impressing clients, meeting deadlines, and creating 3D presentations have never been this quick and easy.",
      icon: <Chart />,
      title: "Design & visualize in 3D",
    },
    {
      description:
        "Cabinet templates aren’t the only designs we include. We have a range of joinery options to use in your designs. You’ll save time and money and forget about the tedious task of designing joints for your woodworking projects.",
      icon: <Shield />,
      title: "Pre-Designed Joinery",
    },
  ];
  return (
    <div className="features p-10 md:px-40 lg:min-h-[900px] lg:p-0 lg:pt-[200px]">
      <div className="blueTitle">Benefits</div>
      <div className="exoTitle mt-1 mb-16">Why choose Nvision3D for your woodworking design?</div>
      <div className="flex flex-wrap items-start justify-center gap-6">
        {staticFeatures.map((e, i) => (
          <div className="featureBox" key={`feature${i}`}>
            <div className="mb-3">{e.icon}</div>
            <div className="text-twentysix font-semibold">{e.title}</div>
            <div className="text-sm text-white/50">{e.description}</div>
          </div>
        ))}
      </div>
    </div>
  );
}
