import { combineReducers } from "@reduxjs/toolkit";
import { appLinkApi, appLinkReducer } from "modules/app-links";
import { couponApi, couponReducer } from "modules/coupon";
import { paymentHistoryApi, paymentHistoryReducer } from "modules/payment-history";
import { paymentMethodApi, paymentMethodReducer } from "modules/payment-method";
import { seatApi, seatReducer } from "modules/seat";

import { customerApi, customerReducer } from "../modules/customer";
import { profileApi, profileReducer } from "../modules/profile";
import {
  subscriptionReducer,
  subscriptionsApi,
  subscriptionTypesApi
} from "../modules/subscription";

const appReducer = combineReducers({
  [profileApi.reducerPath]: profileApi.reducer,
  [subscriptionsApi.reducerPath]: subscriptionsApi.reducer,
  [subscriptionTypesApi.reducerPath]: subscriptionTypesApi.reducer,
  [customerApi.reducerPath]: customerApi.reducer,
  applink: appLinkReducer,
  coupon: customerReducer,
  customer: couponReducer,
  paymentHistory: paymentHistoryReducer,
  paymentMethod: paymentMethodReducer,
  profile: profileReducer,
  seat: seatReducer,
  subscription: subscriptionReducer,
  [appLinkApi.reducerPath]: appLinkApi.reducer,
  [couponApi.reducerPath]: couponApi.reducer,
  [paymentHistoryApi.reducerPath]: paymentHistoryApi.reducer,
  [paymentMethodApi.reducerPath]: paymentMethodApi.reducer,
  [seatApi.reducerPath]: seatApi.reducer,
});

export const rootReducer = (state, action) => {
  //   if (action.type === 'auth/logoutDone') state = undefined;
  return appReducer(state, action);
};

export default rootReducer;
