import logo from "assets/images/logo.svg";
import { getDateFromUnix } from "lib/utils";
import { formatSubscriptionTypeData } from "modules/subscription/subscriptionActions";

const InvoiceDetails = ({ data }) => {
  const subscription = formatSubscriptionTypeData(data?.data?.subscription);
  const upgrade = data?.data?.upgrade ? formatSubscriptionTypeData(data?.data?.upgrade) : null;
  const upcharge = data?.data?.upcharge || null;
  return (
    <div className="invoiceComponent mt-10">
      <div className="flex justify-between bg-bleu-850 py-[50px] px-[75px] text-white">
        <div>
          <img className="rounded-sm bg-bleu-850 p-2" src={logo} alt="Home" />
          <div className="mt-4 flex gap-4">
            <p className="w-[150px] text-sm">2811 Springdale Ave #8422, Springdale AR 72766</p>
            <div className="text-sm">
              <p>(479) 313-0919</p>
              <p>Info@nv3dsoftware.com</p>
              <a href="https://nv3dsoftware.com/">www.nv3dsoftware.com</a>
            </div>
          </div>
        </div>
        <div className="text-[46px] text-white">INVOICE</div>
      </div>
      <div className="p-[75px] text-black">
        <div className="flex justify-between">
          <div className="flex flex-col gap-4">
            <div className="flex gap-10">
              <LabelValueComponent label="Billed to" value={data?.data?.name} />
              <LabelValueComponent label="Invoice Number" value={data?.id} />
              <LabelValueComponent
                label="Date of issue"
                value={getDateFromUnix(
                  data?.data?.payment_date?._seconds,
                  data?.data?.payment_date?._nanoseconds
                )}
              />
            </div>
            {/* <div className="flex gap-10">
              <LabelValueComponent
                label="Address"
                value="12 Carly Circle, Rutland, MA 01543, United States"
              />
              <LabelValueComponent label="Customer Account Number" value="14634643" />
            </div> */}
          </div>
          <div className="text-right">
            <p className="font-exo text-[11px] text-gris-600">Invoice total</p>
            <p className="text-2xl font-semibold text-bleu-100">${data?.data?.amount_paid}</p>
          </div>
        </div>

        <table className="mt-20 border-t-4 border-t-bleu-100">
          <thead>
            <tr>
              <th className="w-[40%] !pl-0 !font-bold !text-bleu-100">Description</th>
              <th className="w-[30%] !font-bold !text-bleu-100">Payment Date</th>
              {/* <th className="w-[20%] !font-bold !text-bleu-100">From</th> */}
              {/* <th className="w-[20%] !font-bold !text-bleu-100">To</th> */}
              <th className="w-[30%] !text-right !font-bold !text-bleu-100">Qty</th>
              {/* <th className="w-[15%] !text-right font-bold !text-bleu-100">Unit cost</th> */}
            </tr>
          </thead>
          <tbody className="!border-0">
            {subscription && (
              <tr>
                <td className="!pl-0 pr-2 text-sm font-bold text-bleu-850">
                  {subscription?.subscription_type_name}
                </td>
                <td className="text-sm font-bold text-bleu-850">
                  {getDateFromUnix(
                    subscription?.start_date?._seconds,
                    subscription?.start_date?._nanoseconds
                  )}
                </td>
                {/* <td className="text-sm font-bold text-bleu-850">
                  {getDateFromUnix(
                    subscription?.expiration_date?._seconds,
                    subscription?.expiration_date?._nanoseconds
                  )}
                </td> */}
                <td className="!text-right text-sm font-bold text-bleu-850">1</td>
                {/* <td className="!text-right text-sm font-bold text-bleu-850">
                  ${subscription?.subscription_price}
                </td> */}
              </tr>
            )}
            {upgrade && (
              <tr>
                <td className="!pl-0 pr-8 text-[11px] font-normal text-gris-600">
                  <p className="text-base !text-bleu-850">
                    {upgrade?.name}{" "}
                    <span className="font-exo text-sm !font-bold !text-bleu-100">(Upgrade)</span>
                  </p>
                  {upgrade?.description}
                </td>
                <td className="text-sm font-bold text-bleu-850">
                  {getDateFromUnix(
                    data?.data?.payment_date?._seconds,
                    data?.data?.payment_date?._nanoseconds
                  )}
                </td>
                {/* <td className="text-sm font-bold text-bleu-850">
                  {getDateFromUnix(
                    data?.data?.payment_date?._seconds,
                    data?.data?.payment_date?._nanoseconds
                  )}
                </td> */}
                <td className="!text-right text-sm font-bold text-bleu-850">1</td>
                {/* <td className="!text-right text-sm font-bold text-bleu-850">
                  {upgrade?.monthly_fee}
                </td> */}
              </tr>
            )}
            {(!!subscription || !!upgrade) && (
              <tr>
                <td colSpan={5} className="!pl-0">
                  <hr className="my-8 border-b-2 border-b-gris-300" />
                </td>
              </tr>
            )}
            {subscription?.seats_purchased > 1 && (
              <tr>
                <td className="!pl-0 pr-2 text-sm font-bold text-bleu-850">Seats</td>
                <td className="text-sm font-bold text-bleu-850">
                  {getDateFromUnix(
                    data?.data?.payment_date?._seconds,
                    data?.data?.payment_date?._nanoseconds
                  )}
                </td>
                {/* <td className="text-sm font-bold text-bleu-850">
                  {getDateFromUnix(
                    data?.data?.payment_date?._seconds,
                    data?.data?.payment_date?._nanoseconds
                  )}
                </td> */}
                <td className="!text-right text-sm font-bold text-bleu-850">
                  {subscription?.seats_purchased - 1}
                </td>
                {/* <td className="text-right text-sm font-bold text-bleu-850">
                  ${subscription?.seat_price}
                </td> */}
              </tr>
            )}
            {upgrade?.seats_purchased > 1 && (
              <tr>
                <td className="!pl-0 pr-2 text-sm font-bold text-bleu-850">Seats</td>
                <td className="text-sm font-bold text-bleu-850">
                  {getDateFromUnix(
                    data?.data?.payment_date?._seconds,
                    data?.data?.payment_date?._nanoseconds
                  )}
                </td>
                {/* <td className="text-sm font-bold text-bleu-850">
                  {getDateFromUnix(
                    data?.data?.payment_date?._seconds,
                    data?.data?.payment_date?._nanoseconds
                  )}
                </td> */}
                <td className="!text-right text-sm font-bold text-bleu-850">
                  {upgrade?.seats_purchased - 1}asd
                </td>
                {/* <td className="text-right text-sm font-bold text-bleu-850">
                  ${upgrade?.extra_seat_monthly_fee}
                </td> */}
              </tr>
            )}
            {!subscription && !upgrade && upcharge && (
              <tr>
                <td className="!pl-0 pr-2 text-sm font-bold text-bleu-850">Seats</td>
                <td className="text-sm font-bold text-bleu-850">
                  {getDateFromUnix(
                    data?.data?.payment_date?._seconds,
                    data?.data?.payment_date?._nanoseconds
                  )}
                </td>
                {/* <td className="text-sm font-bold text-bleu-850">
                  {getDateFromUnix(
                    data?.data?.payment_date?._seconds,
                    data?.data?.payment_date?._nanoseconds
                  )}
                </td> */}
                <td className="!text-right text-sm font-bold text-bleu-850">
                  {upcharge?.seats_purchased}
                </td>
                {/* <td className="text-right text-sm font-bold text-bleu-850">
                  ${upcharge?.seat_price}
                </td> */}
              </tr>
            )}
            <tr>
              <td colSpan={5} className="!pl-0">
                <hr className="my-8 border-b-2 border-b-gris-300" />
              </td>
            </tr>
          </tbody>
        </table>
        <div className="flex flex-row-reverse">
          <div className="w-1/3">
            <table>
              <tbody className="!border-0">
                {/* <tr>
                  <td className="!pl-0 pr-2 text-right font-exo text-sm font-bold text-bleu-100">
                    Subtotal
                  </td>
                  <td className="text-right text-sm font-bold text-bleu-850">$99999.99</td>
                </tr> */}
                {/* <tr>
                  <td className="!pl-0 pr-2 text-right font-exo text-sm font-bold text-bleu-100">
                    Tax
                  </td>
                  <td className="text-right text-sm font-bold text-bleu-850">$999.99</td>
                </tr> */}
                <tr>
                  <td className="!pl-0 pr-2 text-right font-exo text-sm font-bold text-bleu-100">
                    Total
                  </td>
                  <td className="text-right text-sm font-bold text-bleu-850">
                    ${data?.data?.amount_paid}
                  </td>
                </tr>
                {/* <tr className="!h-40">
                  <td className="!pl-0 pr-2 text-right font-exo text-sm font-bold text-bleu-100">
                    Amount Due (USD)
                  </td>
                  <td className="text-right text-sm font-bold text-bleu-850">$999999.99</td>
                </tr> */}
              </tbody>
            </table>
          </div>
        </div>

        <div className="thankyouNote text-center text-xl font-bold">
          Thank you for your business
        </div>
      </div>
    </div>
  );
};

export default InvoiceDetails;

const LabelValueComponent = ({ label, value }) => {
  return (
    <div className="w-[160px]">
      <p className="font-exo text-[11px] text-gris-600">{label}</p>
      <p className="font-bold text-bleu-850">{value}</p>
    </div>
  );
};
