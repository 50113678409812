export default function HowItWorks() {
  return (
    <div className="bg-parametric-blue p-2 pt-20 text-center text-white">
      <div className="blueTitle">How it works</div>
      <div className="exoTitle mt-1 mb-2">The future of woodworking design is 3D</div>
      <div className="inline-block md:w-[669px]">
        Design and visualize with our revolutionary 3D modeler exclusive to Nvision3D. Skip the
        steep learning curve that comes with most traditional CAD software.
      </div>
      <div className="pb-12 pt-16 md:px-36">
        <iframe
          width="100%"
          height="552"
          src="https://www.youtube.com/embed/LnCTkBLDcGQ"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
}
