import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

export const paymentHistorySlice = createSlice({
  initialState,
  name: "payment-history",
  reducers: {},
});

export default paymentHistorySlice.reducer;
