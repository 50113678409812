const Chart = ({ width = 50, height = 50 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M39.5833 6.25H10.4167C8.125 6.25 6.25 8.125 6.25 10.4167V39.5833C6.25 41.875 8.125 43.75 10.4167 43.75H39.5833C41.875 43.75 43.75 41.875 43.75 39.5833V10.4167C43.75 8.125 41.875 6.25 39.5833 6.25ZM18.75 35.4167H14.5833V25H18.75V35.4167ZM27.0833 35.4167H22.9167V29.1667H27.0833V35.4167ZM27.0833 25H22.9167V20.8333H27.0833V25ZM35.4167 35.4167H31.25V14.5833H35.4167V35.4167Z"
        fill="#00A0EE"
      />
    </svg>
  );
};

export default Chart;
