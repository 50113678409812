import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { generateQueryParams } from "lib/utils";

export const CUSTOMER_API_REDUCER_KEY = "customer-api";

export const customerApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BACKEND_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem("authToken");
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getAdminCustomers: builder.query({
      // providesTags: ["customers"],
      providesTags: (result, error, page) =>
        result
          ? [
              // Provides a tag for each post in the current page,
              // as well as the 'PARTIAL-LIST' tag.
              ...result.data.map(({ id }) => ({ id, type: "customers" })),
              { id: "PARTIAL-LIST", type: "customers" },
            ]
          : [{ id: "PARTIAL-LIST", type: "customers" }],
      query: (params = {}) => `/customers?${generateQueryParams(params)}`,
    }),
    submitCustomerContact: builder.mutation({
      query: (body) => ({
        body: body,
        method: "POST",
        url: `/contactForm`,
      }),
    }),
  }),
  reducerPath: CUSTOMER_API_REDUCER_KEY,
  tagTypes: ["customers"],
});

export const { useGetAdminCustomersQuery, useSubmitCustomerContactMutation } = customerApi;
