import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const APPLINK_API_REDUCER_KEY = "applink-api";

export const appLinkApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BACKEND_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem("authToken");
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    addApplink: builder.mutation({
      invalidatesTags: ["appLinks"],
      query: (body) => ({
        body: body,
        method: "POST",
        url: "/admin/appLinks",
      }),
    }),
    getApplinks: builder.query({
      providesTags: ["appLinks"],
      query: () => "/appLinks",
    }),
  }),
  reducerPath: APPLINK_API_REDUCER_KEY,
  tagTypes: ["appLinks"],
});

export const { useAddApplinkMutation, useGetApplinksQuery } = appLinkApi;
