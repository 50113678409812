import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const SUBSCRIPTION_API_REDUCER_KEY = "subscriptions-api";

export const subscriptionsApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BACKEND_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem("authToken");
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    currentSubscription: builder.query({
      providesTags: ["current-subscription"],
      query: () => "/customers/subscriptions",
    }),
    fetchSubscriptionCost: builder.mutation({
      query: (body) => ({
        body: body,
        method: "POST",
        url: `/customers/subscriptions/calculate`,
      }),
    }),
    fetchSubscriptionSeatCost: builder.mutation({
      query: ({ customerId, ...body }) => ({
        body: body,
        method: "POST",
        url: `/customers/${customerId}/seats/calculate`,
      }),
    }),
    fetchSubscriptionUpgradeCost: builder.mutation({
      query: (body) => ({
        body: body,
        method: "POST",
        url: `/customers/subscriptions/upgrade/calculate`,
      }),
    }),
    purchaseSubscription: builder.mutation({
      invalidatesTags: ["current-subscription"],
      query: (body) => ({
        body: body,
        method: "POST",
        url: "/customers/subscriptions",
      }),
    }),
    upgradeSubscription: builder.mutation({
      invalidatesTags: ["current-subscription"],
      query: (body) => ({
        body: body,
        method: "POST",
        url: "/customers/subscriptions/upgrade",
      }),
    }),
  }),
  reducerPath: SUBSCRIPTION_API_REDUCER_KEY,
  tagTypes: ["current-subscription"],
});

export const {
  useCurrentSubscriptionQuery,
  useFetchSubscriptionCostMutation,
  useFetchSubscriptionSeatCostMutation,
  useFetchSubscriptionUpgradeCostMutation,
  usePurchaseSubscriptionMutation,
  useUpgradeSubscriptionMutation,
} = subscriptionsApi;
