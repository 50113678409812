import { Modal, PaymentMethodAddEdit, PaymentMethodItem } from "components";
import { PageHeader } from "components/admin";
import {
  useGetPaymentMethodsQuery,
  useSetDefaultPaymentMethodMutation,
} from "modules/payment-method/paymentMethodService";
import { useState } from "react";
import { toast } from "react-toastify";

const PaymentMethods = () => {
  const [showAddEditPaymentMethodModal, setShowAddEditPaymentMethodModal] = useState(false);
  const { data, isLoading } = useGetPaymentMethodsQuery();
  const [setAsDefault, { isLoading: isLoadingSetDefault }] = useSetDefaultPaymentMethodMutation();

  const makeDefault = async (methodId) => {
    let result = await setAsDefault({ customerPaymentMethodId: methodId });
    if (result.error) toast.error(result?.error?.data?.message || "Something went wrong.");
    else toast.success("Default method updated.");
  };

  return (
    <>
      <PageHeader title="Payment Methods" searchField={false} />

      <div className="mt-8 mb-8 flex flex-row flex-wrap gap-4">
        {isLoading && <p>Loading data...</p>}
        {!isLoading && !!!data?.cards?.length && <p>No data found.</p>}
        {data?.cards?.map((item, i) => {
          return (
            <PaymentMethodItem
              key={`method_${i}`}
              data={item}
              defaultMethodId={data?.defaultPaymentMethodId}
              makeDefault={makeDefault}
              isLoading={isLoadingSetDefault}
            />
          );
        })}
      </div>
      <div className="flex w-[300px] justify-center rounded border py-[77px]">
        <button
          onClick={() => setShowAddEditPaymentMethodModal(true)}
          className="flex h-[30px] w-[130px] items-center justify-center p-0 text-xs"
        >
          Add Card
        </button>
      </div>
      <Modal
        show={showAddEditPaymentMethodModal}
        onClose={() => {
          setShowAddEditPaymentMethodModal(false);
        }}
      >
        <PaymentMethodAddEdit
          onClose={() => {
            setShowAddEditPaymentMethodModal(false);
          }}
        />
      </Modal>
    </>
  );
};

export default PaymentMethods;
