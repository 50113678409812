export { default as Countries } from "./countries";
export { default as Industries } from "./industries";

export const RULES = {
  hasActiveSub: "HAS_ACTIVE_SUBSCRIPTION",
};

export const ADMIN_ROUTES = [
  {
    href: "/admin/customers",
    title: "Customers",
  },
  {
    href: "/admin/subscriptions",
    title: "Subscriptions",
  },
  {
    href: "/admin/payments",
    title: "Payments",
  },
  {
    href: "/admin/coupons",
    title: "Coupons",
  },
  {
    href: "/admin/app-links",
    title: "Manage Apps",
  },
];

export const CUSTOMER_ROUTES = [
  {
    href: "/customer/profile",
    title: "My Profile",
  },
  {
    href: "/customer/subscriptions",
    title: "My Subscriptions",
  },
  {
    href: "/customer/subscription-seats",
    title: "Subscription Seats",
  },
  {
    href: "/customer/payment-history",
    title: "Payment History",
  },
  {
    href: "/customer/payment-methods",
    title: "Payment Methods",
  },
  {
    href: "/customer/app-links",
    rules: [RULES.hasActiveSub],
    title: "Download App",
  },
];

export const UNSUBSCRIBED_CUSTOMER_ROUTES = [
  {
    href: "/purchase",
    title: "Purchase Subscription",
  },
];

export const PAYMENT_APPROACH = {
  annuallyOnce: "AnnuallyOnce",
  monthOnce: "MonthlyOnce",
  monthlyForYear: "MonthlyForYear",
  yearlyOnce: "YearlyOnce",
};

export const SUBSCRIPTION_STATUS = {
  active: "Active",
  cancelled: "Canceled",
  expired: "Expired",
  suspended: "Suspended",
  terminated: "Terminated",
};

export const SUBSCRIPTION_PAYMENT_STATUS = {
  paid: "settledSuccessfully",
};

export const SUBSCRIPTION_STEP_TWO_PURCHASE_TYPE = {
  initialPurchase: "initial-purchase",
  rePurchase: "re-purchase",
};

export const PAGINATION_ORDER = {
  asc: "asc",
  desc: "desc",
};
