import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isFetchLoading: false,
  isLoading: false,
  subscriptionTypes: [],
};

export const subscriptionSlice = createSlice({
  initialState,
  name: "subscription",
  reducers: {},
});

// export const {} = subscriptionSlice.actions;

export default subscriptionSlice.reducer;
