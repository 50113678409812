import {
  AboutUs,
  Contact,
  DownloadUsage,
  FAQ,
  Features,
  Gallery,
  Hero,
  HowItWorks,
  Parametrics,
  Pricing,
  Signup,
  Testimonials
} from "components";
import { usePublicSubscriptionTypesQuery } from "modules/subscription/subscriptionActions";

export default function Home() {
  const { data, isLoading } = usePublicSubscriptionTypesQuery();

  return (
    <>
      <Hero />
      <HowItWorks />
      <Features />
      <AboutUs />
      <Signup />
      <Parametrics />
      <Gallery />
      <DownloadUsage />
      <Pricing data={data} isLoading={isLoading} from="landing" />
      <FAQ />
      {/* <Testimonials /> */}
      <Contact />
    </>
  );
}
