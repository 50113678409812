import { Sort } from "assets/icons";
import { SimplePaging } from "components";
import { PageHeader } from "components/admin";
import { PAGINATION_ORDER } from "constants/index";
import { getDateFromUnix } from "lib/utils";
import { useGetAdminCustomersQuery } from "modules/customer/customerActions";
import { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { useSortBy, useTable } from "react-table";

const DEFAULT_PARAMS = {
  endBeforeId: "",
  limit: 10,
  orderByDirection: "",
  orderByFieldName: "",
  startAfterId: "",
  toFirst: "true",
  toLast: "",
};

export default function Customers() {
  const [params, setParams] = useState(DEFAULT_PARAMS);
  const { data, isLoading, isFetching } = useGetAdminCustomersQuery(params);

  const customersData = useMemo(() => {
    if (data) return data?.data;
    return [];
  }, [data]);

  const onChangeSortHandler = (id) => {
    let direction = PAGINATION_ORDER.asc;
    if (params.orderByFieldName === id && params.orderByDirection === PAGINATION_ORDER.asc) {
      direction = PAGINATION_ORDER.desc;
    }
    setParams({ ...DEFAULT_PARAMS, orderByDirection: direction, orderByFieldName: id });
  };

  const onChangePaginationHandler = (obj) => {
    setParams((p) => ({ ...p, ...obj }));
  };

  const customersColumns = useMemo(() => {
    var cols = [];
    cols.push({
      Cell: (props) => (
        <>
          <Link to={"/admin/customers/" + props.row.original.id}>
            {props.row.original?.data?.first_name}
          </Link>
        </>
      ),
      Header: "First Name",
      accessor: "first_name",
      isSortable: true,
    });

    cols.push({
      Cell: (props) => (
        <>
          <Link to={"/admin/customers/" + props.row.original.id}>
            {props.row.original?.data?.last_name}
          </Link>
        </>
      ),
      Header: "Last Name",
      accessor: "last_name",
      isSortable: true,
    });

    cols.push({
      Cell: (props) => <>{props.row.original?.data?.email}</>,
      Header: "Email Address",
      accessor: "email",
      isSortable: true,
    });

    cols.push({
      Cell: (props) => <>{props.row.original?.data?.subscription?.subscriptionTypeName || "N/A"}</>,
      Header: "Subscription Type",
      accessor: "subscription",
    });

    cols.push({
      Cell: (props) => <>{props.row.original?.data?.subscription?.seats?.activeSeatsCount}</>,
      Header: "Seats used",
      accessor: "seats",
    });

    // cols.push({
    //   Cell: (props) => (
    //     <span className="font-semibold text-bleu-850">${props.row.original.fee}</span>
    //   ),
    //   Header: "Monthly fee",
    //   accessor: "fee",
    // });

    cols.push({
      Cell: (props) => (
        <>
          {getDateFromUnix(
            props.row.original?.data?.join_date?._seconds,
            props.row.original?.data?.join_date?._nanoseconds
          )}
        </>
      ),
      Header: "Joined",
      accessor: "join_date",
    });

    cols.push({
      Cell: (props) => (
        <>
          {props.row.original?.data?.subscription
            ? getDateFromUnix(
                props.row.original?.data?.subscription?.expiration_date?._seconds,
                props.row.original?.data?.subscription?.expiration_date?._nanoseconds
              )
            : "N/A"}
        </>
      ),
      Header: "Renewal date",
      accessor: "renewal",
    });

    cols.push({
      Cell: (props) => (
        <span
          className={
            props.row.original?.data?.subscription?.status.toLowerCase() === "active"
              ? "!text-vert-800"
              : "!text-gris-300"
          }
        >
          {props.row.original?.data?.subscription?.status}
        </span>
      ),
      Header: "Status",
      accessor: "status",
    });
    return cols;
  }, [data]);

  const tableInstance = useTable(
    {
      columns: customersColumns,
      data: customersData,
    },
    useSortBy
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;

  return (
    <>
      <PageHeader title="Customers" searchField={false} />

      {isLoading || isFetching ? (
        <p>Loading data ...</p>
      ) : (
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => {
                  let thProps = column.getHeaderProps();
                  if (column.isSortable) {
                    thProps = column.getHeaderProps(column.getSortByToggleProps());
                  }
                  return (
                    <th {...thProps}>
                      {column.render("Header")}
                      {column.isSortable && (
                        <span onClick={() => onChangeSortHandler(column.id)}>
                          <Sort />
                        </span>
                      )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              const rowClass = i % 2 ? "odd" : "even";
              return (
                <tr {...row.getRowProps()} className={rowClass}>
                  {row.cells.map((cell) => {
                    return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      )}

      {!(isLoading || isFetching) && (
        <div className="mt-6 flex items-center justify-between">
          <SimplePaging
            containerClass="mt-6"
            data={{
              nextPageId: data?.nextPageId || "",
              prevPageId: data?.prevPageId || "",
            }}
            onChangePagination={onChangePaginationHandler}
          />
          <p>
            Showing {customersData?.length} out of {data?.total || "-"}
          </p>
        </div>
      )}
    </>
  );
}
