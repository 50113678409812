const ChevronLeft = ({ width = 13, height = 23 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 13 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.344805 10.6062L9.87828 0.370252C10.3381 -0.123418 11.0835 -0.123418 11.5432 0.370252L12.6552 1.56411C13.1142 2.05694 13.1151 2.85567 12.6571 3.34965L5.10168 11.5L12.6571 19.6503C13.1151 20.1443 13.1142 20.9431 12.6552 21.4359L11.5432 22.6297C11.0835 23.1234 10.338 23.1234 9.87828 22.6297L0.344805 12.3939C-0.114935 11.9002 -0.114935 11.0998 0.344805 10.6062Z"
        fill="#00A0EE"
      />
    </svg>
  );
};

export default ChevronLeft;
