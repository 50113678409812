import { Modal } from "components";
import { PageHeader, SubscriptionAddEditForm, SubscriptionItem } from "components/admin";
import { useSubscriptionTypesQuery } from "modules/subscription/subscriptionActions";
import { useCallback, useState } from "react";

export default function Subscriptions() {
  const [selectedSubscription, setSelectedSubscription] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const { data, isLoading } = useSubscriptionTypesQuery();

  const onEditHandler = useCallback(
    (sub) => {
      setSelectedSubscription(sub);
      setShowModal(true);
    },
    [selectedSubscription]
  );

  return (
    <>
      <PageHeader
        title="Subscriptions"
        buttonTitle="Add Subscription"
        buttonActionHandler={() => setShowModal((p) => !p)}
      />
      <div className="mt-[50px] grid grid-cols-3 gap-8">
        {isLoading && <p>Loading data...</p>}
        {!isLoading && !!!data?.length && <p>No data found.</p>}
        {data?.map((item) => {
          return <SubscriptionItem key={item.id} item={item} onEdit={onEditHandler} />;
        })}
      </div>
      <Modal
        show={showModal}
        onClose={() => {
          setShowModal(false);
          setSelectedSubscription(null);
        }}
      >
        <SubscriptionAddEditForm
          selectedSubscription={selectedSubscription}
          onClose={() => {
            setShowModal(false);
            setSelectedSubscription(null);
          }}
        />
      </Modal>
    </>
  );
}
