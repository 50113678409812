import {
  Modal,
  ProfileBasicInfo,
  ProfileBasicInfoEdit,
  ProfileSubscriptionInfo,
  ProfileUpdatePassword,
} from "components";
import { PageHeader } from "components/admin";
import { useAuth } from "lib/auth";
import { useGetCustomerProfileQuery } from "modules/profile/profileActions";
import { useEffect } from "react";
import { useState } from "react";

const MyProfile = () => {
  const [showProfileEditModal, setShowProfileEditModal] = useState(false);
  const [showUpdatePasswordModal, setShowUpdatePasswordModal] = useState(false);

  const { currentSubscription, decodedToken, getCurrentSubscription } = useAuth();

  const { data, isLoading } = useGetCustomerProfileQuery(
    decodedToken()?.custom_claims?.customer?.customerId || ""
  );

  useEffect(() => {
    getCurrentSubscription();
  }, []);

  return (
    <div>
      <PageHeader title="My Profile" searchField={false} />
      {isLoading ? (
        <p className="mt-10">Loading profile data...</p>
      ) : (
        <ProfileBasicInfo
          containerClass="mt-10"
          info={data?.data}
          onClickEditProfile={() => setShowProfileEditModal(true)}
          onClickUpdatePassword={() => setShowUpdatePasswordModal(true)}
        />
      )}

      <ProfileSubscriptionInfo data={currentSubscription} containerClass="mt-[72px]" />

      <Modal
        show={showProfileEditModal}
        onClose={() => {
          setShowProfileEditModal(false);
        }}
      >
        <ProfileBasicInfoEdit
          id={data?.id}
          data={data?.data}
          onClose={() => {
            setShowProfileEditModal(false);
          }}
        />
      </Modal>

      <Modal
        show={showUpdatePasswordModal}
        onClose={() => {
          setShowUpdatePasswordModal(false);
        }}
      >
        <ProfileUpdatePassword
          onClose={() => {
            setShowUpdatePasswordModal(false);
          }}
        />
      </Modal>
    </div>
  );
};

export default MyProfile;
