import "./header.css";

import logo from "assets/images/logo.svg";
import ProtectedLayoutHeader from "components/layout/header/header";
import { useAuth } from "lib/auth";
import { NavLink, useLocation } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

export default function Header() {
  const { pathname } = useLocation();
  const auth = useAuth();

  return (
    <div className="absolute inset-x-0 top-0">
      <div className="flex flex-col items-center gap-2 py-2 md:flex-row md:py-8">
        <div className="ml-10 mr-16 hidden md:block">
          <NavLink to="/">
            <img src={logo} alt="Home" />
          </NavLink>
        </div>
        <div className="grow">
          <nav className="flex gap-12">
            <HashLink className="navLink" smooth to="/#about">
              About
            </HashLink>
            <HashLink className="navLink" smooth to="/#gallery">
              Gallery
            </HashLink>
            <HashLink className="navLink" smooth to="/#price">
              Price
            </HashLink>
            <HashLink className="navLink" smooth to="/#contactus">
              Contact us
            </HashLink>
          </nav>
        </div>
        <div className="lg:mr-10">
          {!auth.isUserLoggedIn() ? (
            <>
              {pathname.includes("login") ? (
                <NavLink to="/register">
                  <button className="gris mr-5 lg:min-w-[150px] md:min-w-[80px]">Register</button>
                </NavLink>
              ) : (
                <NavLink to="/login">
                  <button className="gris mr-5 lg:min-w-[150px] md:min-w-[80px]">Login</button>
                </NavLink>
              )}
            </>
          ) : (
            <ProtectedLayoutHeader />
          )}
        </div>
      </div>
    </div>
  );
}
