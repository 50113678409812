import {
  useCurrentSubscriptionQuery,
  useFetchSubscriptionCostMutation,
  useFetchSubscriptionSeatCostMutation,
  useFetchSubscriptionUpgradeCostMutation,
  usePurchaseSubscriptionMutation,
  useUpgradeSubscriptionMutation,
} from "./subscriptionsService";
import {
  useAddSubscriptionTypeMutation,
  usePublicSubscriptionTypesQuery,
  useSubscriptionTypesQuery,
  useUpdateSubscriptionTypeMutation,
} from "./subscriptionTypeService";

const formatSubscriptionTypeData = (data = {}) => {
  let temp = { ...data };
  try {
    let parsedData = JSON.parse(data?.description || "{}");
    temp.description = parsedData.description || "";
    temp.features = parsedData.features || [];
  } catch (e) {
    console.log("formatSubscriptionTypeData error ===>", e.message);
    return null;
  }
  return temp;
};

export {
  formatSubscriptionTypeData,
  useAddSubscriptionTypeMutation,
  useCurrentSubscriptionQuery,
  useFetchSubscriptionCostMutation,
  useFetchSubscriptionSeatCostMutation,
  useFetchSubscriptionUpgradeCostMutation,
  usePublicSubscriptionTypesQuery,
  usePurchaseSubscriptionMutation,
  useSubscriptionTypesQuery,
  useUpdateSubscriptionTypeMutation,
  useUpgradeSubscriptionMutation,
};
