import { Sort } from "assets/icons";
import { useMemo } from "react";
import { useSortBy, useTable } from "react-table";

function SubscriptionSeats({ customerSeats }) {
  const columns = useMemo(
    () => [
      { Header: "Machine ID", accessor: "fingerprint", isSortable: true },
      { Header: "Username", accessor: "login", isSortable: true },
      { Header: "Name", accessor: "name", isSortable: true },
    ],
    []
  );

  const seats = useMemo(() => {
    if (customerSeats) return customerSeats?.map((item) => item.data);
    return [];
  }, [customerSeats]);

  const tableInstance = useTable(
    {
      columns: columns,
      data: seats,
    },
    useSortBy
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;

  return (
    <table {...getTableProps()}>
      <thead>
        {
          // Loop over the header rows
          headerGroups.map((headerGroup) => (
            // Apply the header row props
            <tr {...headerGroup.getHeaderGroupProps()}>
              {
                // Loop over the headers in each row
                headerGroup.headers.map((column) => {
                  let thProps = column.getHeaderProps();
                  if (column.isSortable) {
                    thProps = column.getHeaderProps(column.getSortByToggleProps());
                  }
                  return (
                    <th {...thProps}>
                      {column.render("Header")}
                      {column.isSortable && <Sort />}
                    </th>
                  );
                })
              }
            </tr>
          ))
        }
      </thead>
      {/* Apply the table body props */}
      <tbody {...getTableBodyProps()}>
        {
          // Loop over the table rows
          rows.map((row, i) => {
            // Prepare the row for display
            prepareRow(row);
            const rowClass = i % 2 ? "odd" : "even";
            return (
              // Apply the row props
              <tr {...row.getRowProps()} className={rowClass}>
                {
                  // Loop over the rows cells
                  row.cells.map((cell) => {
                    // Apply the cell props
                    return (
                      <td {...cell.getCellProps()}>
                        {
                          // Render the cell contents
                          cell.render("Cell")
                        }
                      </td>
                    );
                  })
                }
              </tr>
            );
          })
        }
      </tbody>
    </table>
  );
}

export default SubscriptionSeats;
