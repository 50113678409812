import { useState } from "react";
import { useForm } from "react-hook-form";

export default function LoginForgotPwd({ handleForgotPwd, toggleLoginForgotPwd }) {
  const { register, handleSubmit, formState } = useForm();
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");

  const handleSubmitForm = (data) => {
    setIsLoading(true);
    handleForgotPwd(data)
      .then((_e) => {
        setIsFormSubmitted(true);
        setEmail(data.email);
        setIsLoading(false);
      })
      .catch((error) => setIsLoading(false));
  };

  return (
    <>
      {!isFormSubmitted && (
        <form onSubmit={handleSubmit(handleSubmitForm)}>
          <div className="px-10 pb-5">
            <div className="exoTitle pt-16 pb-9">Forgot your password?</div>
            <div className="text-sm">
              Confirm your email and we’ll send you the instructions to create a new password.
            </div>
          </div>
          <div className="min-w-[382px]">
            <input
              name="email"
              placeholder="Email Address"
              type="email"
              {...register("email", {
                pattern: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                required: true,
              })}
            />
          </div>
          <div className="validationError">
            {formState.errors?.email?.type === "required" && "Email is required"}
            {formState.errors?.email?.type === "pattern" && "Email is not valid"}
          </div>
          {!isLoading && <button className="mt-16 w-[65%]">Reset Password</button>}

          {isLoading && <span className="loader mt-16"></span>}
        </form>
      )}
      {isFormSubmitted && !isLoading && (
        <div>
          <div className="exoTitle pt-16 pb-9">Reset Password</div>
          <div className="text-sm font-semibold">
            An email has been sent to <span className="text-bleu-100">{email}</span> with further
            instructions on how to reset your password.
          </div>
        </div>
      )}
      <div className="mt-9 mb-11 font-exo text-lg font-semibold leading-[24px] text-bleu-100">
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a href="#" onClick={toggleLoginForgotPwd}>
          Back to Login
        </a>
      </div>
    </>
  );
}
