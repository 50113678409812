import { Loader } from "components";
import { Countries, Industries } from "constants";
import { useUpdateCustomerProfileMutation } from "modules/profile/profileActions";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

const sortedCountries = Object.entries(Countries).sort((a, b) => {
  if (a[1] < b[1]) {
    return -1;
  }
  if (a[1] > b[1]) {
    return 1;
  }
  return 0;
});

const ProfileBasicInfoEdit = ({ id = "", data = null, onClose }) => {
  const { register, handleSubmit, formState, setValue } = useForm();
  const [updateProfile, { isLoading }] = useUpdateCustomerProfileMutation();

  useEffect(() => {
    if (data) {
      setValue("company", data?.company || "");
      setValue("country", data?.country || "");
      setValue("first_name", data?.first_name || "");
      setValue("industry", data?.industry || "");
      setValue("last_name", data?.last_name || "");
      setValue("phone_number", data?.phone_number || "");
    }
  }, []);

  const handleSubmitForm = async (data) => {
    let result = await updateProfile({ id, ...data });
    if (result.error) {
      toast.error(result?.error?.data?.message || "Something went wrong");
    } else {
      toast.success("Profile updated successfully");
      onClose();
    }
  };

  return (
    <div className="relative w-[700px]">
      <Loader isLoading={isLoading} />
      <h2 className="page-title">My Profile</h2>
      <form onSubmit={handleSubmit(handleSubmitForm)}>
        <div className="subscription-add-edit-form-container mt-12">
          <div className="form-fields flex flex-row gap-[76px]">
            <div className="flex flex-1 flex-col gap-10">
              <div className="formElement">
                <p className="formTitle">First Name *</p>
                <div className="formInputWrapper">
                  <input
                    className="formTextInput"
                    {...register("first_name", {
                      required: true,
                    })}
                  />
                </div>
                <div className="validationError">
                  {formState.errors?.first_name?.type === "required" && "First Name is required"}
                </div>
              </div>

              <div className="formElement">
                <p className="formTitle">Email Address</p>
                <div className="formInputWrapper">
                  <input
                    className="formTextInput"
                    value={data?.email || ""}
                    // {...register("email", {
                    //   required: true,
                    // })}
                    disabled
                  />
                </div>
                {/* <div className="validationError">
                  {formState.errors?.email?.type === "required" && "Email is required"}
                </div> */}
              </div>

              <div className="formElement">
                <p className="formTitle">Phone Number *</p>
                <div className="formInputWrapper">
                  <input
                    className="formTextInput"
                    {...register("phone_number", {
                      required: true,
                    })}
                  />
                </div>
                <div className="validationError">
                  {formState.errors?.phone_number?.type === "required" &&
                    "Phone Number is required"}
                </div>
              </div>

              <div className="formElement">
                <p className="formTitle">Industry *</p>
                <div className="formInputWrapper">
                  <select
                    className="formSelect"
                    placeholder="Industry"
                    {...register("industry", {
                      required: true,
                    })}
                  >
                    <option value="">Select Industry</option>
                    {Industries.map((e, i) => {
                      return (
                        <option key={"industry" + i} value={e}>
                          {e}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="validationError">
                  {formState.errors?.industry?.type === "required" && "First Name is required"}
                </div>
              </div>
            </div>
            <div className="flex flex-1 flex-col gap-10">
              <div className="formElement">
                <p className="formTitle">Last Name *</p>
                <div className="formInputWrapper">
                  <input
                    className="formTextInput"
                    {...register("last_name", {
                      required: true,
                    })}
                  />
                </div>
                <div className="validationError">
                  {formState.errors?.last_name?.type === "required" && "Last Name is required"}
                </div>
              </div>

              <div className="formElement">
                <p className="formTitle">Country *</p>
                <div className="formInputWrapper">
                  <select
                    className="formSelect"
                    placeholder="Country"
                    {...register("country", {
                      required: true,
                    })}
                  >
                    <option value="">Select Country</option>
                    {sortedCountries.map((e, i) => {
                      const [key, value] = e;
                      return (
                        <option key={"country" + i} value={key}>
                          {value}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="validationError">
                  {formState.errors?.country?.type === "required" && "Country is required"}
                </div>
              </div>

              <div className="formElement">
                <p className="formTitle">Company Name *</p>
                <div className="formInputWrapper">
                  <input
                    className="formTextInput"
                    {...register("company", {
                      required: true,
                    })}
                  />
                </div>
                <div className="validationError">
                  {formState.errors?.company?.type === "required" && "Company is required"}
                </div>
              </div>
            </div>
          </div>
          <div className="mt-10 flex justify-end gap-5">
            <button onClick={onClose} className="btn-secondary">
              Cancel
            </button>
            <button>Save changes</button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ProfileBasicInfoEdit;
