const ArrowSend = ({ width = 25, height = 24 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.4393 12.6463L8.1921 11.8972L8.19209 11.8972L8.19208 11.8972C5.83888 11.1128 4.66228 10.7206 4.66228 9.99985C4.66228 9.27909 5.83888 8.88689 8.1921 8.10249L16.7053 5.26476C18.3611 4.71283 19.189 4.43687 19.626 4.87388C20.063 5.3109 19.787 6.1388 19.2351 7.79459L16.3974 16.3078L16.3974 16.3078C15.613 18.661 15.2208 19.8376 14.5 19.8376C13.7792 19.8376 13.387 18.661 12.6026 16.3078L11.8536 14.0605L16.2071 9.70696C16.5976 9.31643 16.5976 8.68327 16.2071 8.29275C15.8166 7.90222 15.1834 7.90222 14.7929 8.29275L10.4393 12.6463Z"
        fill="white"
      />
    </svg>
  );
};

export default ArrowSend;
