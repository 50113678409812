import "./simple-paging.css";

const SimplePaging = ({ data, onChangePagination, containerClass = "" }) => {
  const onFirst = () => {
    onChangePagination({
      endBeforeId: "",
      startAfterId: "",
      toFirst: "true",
      toLast: "",
    });
  };
  const onLast = () => {
    onChangePagination({
      endBeforeId: "",
      startAfterId: "",
      toFirst: "",
      toLast: "true",
    });
  };
  const onNext = () => {
    onChangePagination({
      endBeforeId: "",
      startAfterId: data?.nextPageId,
      toFirst: "",
      toLast: "",
    });
  };
  const onPrev = () => {
    onChangePagination({
      endBeforeId: data?.prevPageId,
      startAfterId: "",
      toFirst: "",
      toLast: "",
    });
  };
  const isNextAvailable = !!data?.nextPageId;
  const isPrevAvailable = !!data?.prevPageId;
  return (
    <div className={`flex gap-2 ${containerClass}`}>
      <button onClick={onFirst} className="simple-paging-button">
        First
      </button>
      <button
        disabled={!isPrevAvailable}
        onClick={onPrev}
        className={`simple-paging-button ${!isPrevAvailable && `bg-gris-600`}`}
      >{`< Previous`}</button>
      <button
        disabled={!isNextAvailable}
        onClick={onNext}
        className={`simple-paging-button ${!isNextAvailable && `bg-gris-600`}`}
      >{`Next >`}</button>
      <button onClick={onLast} className="simple-paging-button">
        Last
      </button>
    </div>
  );
};

export default SimplePaging;
