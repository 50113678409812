import { Loader } from "components";

const SubscriptionChangeConfirmModal = ({ isLoading, migrationDetails, cancel, confirm }) => {
  let { plan_expires_at, amount } = migrationDetails;
  //plan_expires_at - datestamp
  //amount - the amount we will show
  return (
    <div className="relative w-[360px]">
      <Loader isLoading={isLoading} />
      <h2 className="font-exo text-thirtytwo font-semibold">Upgrade Plan</h2>
      <p className="text-xs text-black opacity-70">
        You are currenty on an annual plan which expires on the {plan_expires_at}. The additional
        amount below will be charged upon confirmation.
      </p>

      <p className="pt-10 text-twentysix font-semibold text-bleu-850">
        {amount === 0 || amount ? `$${amount}` : `N/A`}
      </p>
      <p className="pb-14 text-sixteen font-semibold text-gris-600">Additional fee</p>
      <div className="flex gap-5">
        <button className="btn-secondary h-[40px]" onClick={cancel}>
          Cancel
        </button>
        <button onClick={confirm}>Confirm Upgrade</button>
      </div>
    </div>
  );
};

export default SubscriptionChangeConfirmModal;
