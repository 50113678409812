import { Countries } from "constants/index";
import { useAddPaymentMethodMutation } from "modules/payment-method/paymentMethodActions";
import { useForm } from "react-hook-form";

const sortedCountries = Object.entries(Countries).sort((a, b) => {
  if (a[1] < b[1]) {
    return -1;
  }
  if (a[1] > b[1]) {
    return 1;
  }
  return 0;
});

const SubscriptionPurchaseStepThree = ({ selectedSubscription, priceData, callback }) => {
  const { register, handleSubmit, formState } = useForm();

  const handleSubmitForm = async (data) => {
    const card = {
      billingAddress: {
        address: data.address,
        city: data.city,
        country: data.country,
        state: data.state,
        zip: data.zip,
      },
      ccv: data.ccv,
      expirationDate: data.expirationDate,
      number: data.number.replace(/ /g, ""),
    };
    callback(card);
  };

  return (
    <div className="flex flex-row items-start justify-center gap-10">
      <div className="w-[400px] rounded-md bg-bleu-800 p-10 text-left shadow-lg">
        <p className="pb-4 text-[32px] text-bleu-100">Your Payment Method</p>
        <form onSubmit={handleSubmit(handleSubmitForm)}>
          <div>
            <p className="mb-8 text-[26px] font-semibold">Card details</p>
            <div className="form-fields flex flex-col gap-2">
              <div className="flex flex-1 gap-2">
                {/* <div className="formElement">
                  <p className="formTitle">Card Type</p>
                  <div className="formInputWrapper">
                    <select
                      className="formSelect h-[42px] p-0 !text-white"
                      placeholder="Card Type"
                      {...register("cardType", {
                        required: true,
                      })}
                    >
                      <option value="">Select Card Type</option>
                      <option value="visa">Visa</option>
                      <option value="master">Master</option>
                    </select>
                  </div>
                  <div className="validationError">
                    {formState.errors?.cardType?.type === "required" && "Card Type is required"}
                  </div>
                </div> */}

                <div className="formElement flex-1">
                  <p className="formTitle">Card Number</p>
                  <div className="formInputWrapper">
                    <input
                      className="formTextInput !text-white"
                      {...register("number", {
                        required: true,
                      })}
                      placeholder="4321********1234"
                    />
                  </div>
                  <div className="validationError">
                    {formState.errors?.number?.type === "required" && "Card Number is required"}
                  </div>
                </div>
              </div>
              <div className="flex flex-1 gap-2">
                <div className="formElement">
                  <p className="formTitle">Expiry Date</p>
                  <div className="formInputWrapper">
                    <input
                      className="formTextInput !text-white"
                      {...register("expirationDate", {
                        required: true,
                      })}
                      placeholder="MM/YY"
                    />
                  </div>
                  <div className="validationError">
                    {formState.errors?.expirationDate?.type === "required" &&
                      "Expiry Date is required"}
                  </div>
                </div>

                <div className="formElement">
                  <p className="formTitle">CCV</p>
                  <div className="formInputWrapper">
                    <input
                      className="formTextInput !text-white"
                      {...register("ccv", {
                        required: true,
                      })}
                      placeholder="XXX"
                    />
                  </div>
                  <div className="validationError">
                    {formState.errors?.ccv?.type === "required" && "ccv is required"}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <p className="mt-11 mb-8 text-[26px] font-semibold">Billing address</p>
            <div className="form-fields flex flex-row gap-[76px]">
              <div className="flex flex-1 flex-col gap-5">
                <div className="formElement">
                  <p className="formTitle">Address</p>
                  <div className="formInputWrapper">
                    <input
                      className="formTextInput !text-white"
                      placeholder="Address"
                      {...register("address", {
                        required: true,
                      })}
                    />
                  </div>
                  <div className="validationError">
                    {formState.errors?.address?.type === "required" && "Street is required"}
                  </div>
                </div>

                <div className="formElement">
                  <p className="formTitle">Zip Code</p>
                  <div className="formInputWrapper">
                    <input
                      className="formTextInput !text-white"
                      placeholder="Zip Code"
                      {...register("zip", {
                        required: true,
                      })}
                    />
                  </div>
                  <div className="validationError">
                    {formState.errors?.zip?.type === "required" && "Zip Code is required"}
                  </div>
                </div>

                <div className="formElement">
                  <p className="formTitle">Country</p>
                  <div className="formInputWrapper">
                    <select
                      className="formSelect !text-white"
                      placeholder="Country"
                      {...register("country", {
                        required: true,
                      })}
                    >
                      <option value="">Select Country</option>
                      {sortedCountries.map((e, i) => {
                        const [key, value] = e;
                        return (
                          <option key={"country" + i} value={key}>
                            {value}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="validationError">
                    {formState.errors?.country?.type === "required" && "Country is required"}
                  </div>
                </div>
              </div>
              <div className="flex flex-1 flex-col gap-5">
                <div className="formElement">
                  <p className="formTitle">City</p>
                  <div className="formInputWrapper">
                    <input
                      className="formTextInput !text-white"
                      placeholder="City"
                      {...register("city", {
                        required: true,
                      })}
                    />
                  </div>
                  <div className="validationError">
                    {formState.errors?.city?.type === "required" && "Address is required"}
                  </div>
                </div>

                <div className="formElement">
                  <p className="formTitle">State/Province</p>
                  <div className="formInputWrapper">
                    <input
                      className="formTextInput !text-white"
                      placeholder="State"
                      {...register("state", {
                        required: true,
                      })}
                    />
                  </div>
                  <div className="validationError">
                    {formState.errors?.state?.type === "required" && "State is required"}
                  </div>
                </div>
              </div>
            </div>
            <button className="mt-5 w-full">Purchase Now</button>
          </div>
        </form>
      </div>
      <div className="w-[400px] rounded-md bg-bleu-400 p-10 text-left shadow-lg">
        {" "}
        <p className="pb-8 text-[32px] text-bleu-100">Summary</p>
        <div className="flex flex-row justify-between pb-8">
          <p>Subscription</p>
          <p>{selectedSubscription?.data?.name}</p>
        </div>
        <div className="flex flex-row justify-between pb-8">
          <p>Subtotal</p>
          <p>${priceData?.totalFee}</p>
        </div>
        <div className="flex flex-row justify-between pb-20">
          <p>Due now</p>
          <p>${priceData?.totalFee}</p>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionPurchaseStepThree;
