import { Loader } from "components";
import { SUBSCRIPTION_STATUS } from "constants/index";
import { getDateFromUnix, subscriptionPaymentTypeText } from "lib/utils";
import { formatSubscriptionTypeData } from "modules/subscription/subscriptionActions";

const SubscriptionItem = ({
  item,
  current = false,
  currentSub = null,
  onChangeSubscriptionHandler,
  isLoading = false,
}) => {
  const data = formatSubscriptionTypeData(item?.data);
  return (
    <div className="flex-col rounded-[3px] border border-gris-850 px-[30px] py-[24px] relative">
      <Loader isLoading={isLoading} />
      <div className="flex-col space-y-4">
        <h3 className="text-2xl font-semibold text-bleu-100">{data?.name} </h3>
        <p className="text-eight text-bleu-850">{data?.description}</p>
        {current ? (
          <>
            <div className="flex">
              <div className="flex-1">
                <p className="text-ten font-bold uppercase text-bleu-100">Payment Type</p>
                <p className="text-base text-bleu-850">
                  {subscriptionPaymentTypeText(currentSub?.paymentApproach)}
                </p>
              </div>
              <div className="flex-1">
                <p className="text-ten font-bold uppercase text-bleu-100">Seats</p>
                <p className="text-base text-bleu-850">{currentSub?.seats?.paidSeatsCount}</p>
              </div>
            </div>

            <div className="flex">
              <div className="flex-1">
                <p className="text-ten font-bold uppercase text-bleu-100">Expiry Date</p>
                <p className="text-base text-bleu-850">
                  {getDateFromUnix(
                    currentSub?.expiration_date?._seconds,
                    currentSub?.expiration_date?._nanoseconds
                  )}
                </p>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="flex">
              <div className="flex-1">
                <p className="text-ten font-bold uppercase text-bleu-100">Monthly pricing</p>
                <p className="text-base text-bleu-850">${data?.monthly_fee}</p>
              </div>
              <div className="flex-1">
                <p className="text-ten font-bold uppercase text-bleu-100">Annual pricing</p>
                <p className="text-base text-bleu-850">${data?.annual_fee}</p>
              </div>
            </div>

            <div className="flex">
              <div className="flex-1">
                <p className="text-ten font-bold uppercase text-bleu-100">monthly seat pricing</p>
                <p className="text-base text-bleu-850">${data?.extra_seat_monthly_fee}</p>
              </div>
              <div className="flex-1">
                <p className="text-ten font-bold uppercase text-bleu-100">Annual seat pricing</p>
                <p className="text-base text-bleu-850">${data?.extra_seat_annual_fee}</p>
              </div>
            </div>
          </>
        )}
      </div>
      {current && (
        <button className="mt-[86px] bg-gris-600" disabled>
          Current Package
        </button>
      )}
      {!current &&
        (currentSub &&
        currentSub?.status === SUBSCRIPTION_STATUS.active &&
        parseFloat(currentSub?.subscription_type?.annual_fee) < parseFloat(data?.annual_fee) ? (
          <button className="mt-[86px]" onClick={() => onChangeSubscriptionHandler(item)}>
            Upgrade
          </button>
        ) : currentSub && currentSub?.status !== SUBSCRIPTION_STATUS.active ? (
          <button className="mt-[86px]" onClick={() => onChangeSubscriptionHandler(item)}>
            Select Package
          </button>
        ) : (
          <></>
        ))}
    </div>
  );
};

export default SubscriptionItem;
