import "./left-menu.css";

import { Logout } from "assets/icons";
import logo from "assets/images/logo.svg";
import { ADMIN_ROUTES, CUSTOMER_ROUTES, UNSUBSCRIBED_CUSTOMER_ROUTES } from "constants/index";
import { useAuth } from "lib/auth";
import { useMemo } from "react";
import { NavLink } from "react-router-dom";

export default function ProtectedLayoutLeftNavbar({ isAdmin, currentSubscription }) {
  const auth = useAuth();

  const routes = useMemo(() => {
    if (isAdmin) return ADMIN_ROUTES;
    else {
      if (currentSubscription) return CUSTOMER_ROUTES;
      else return UNSUBSCRIBED_CUSTOMER_ROUTES;
    }
  }, [isAdmin]);

  return (
    <div className="protectedMenu">
      <div>
        <a href="/">
          <img src={logo} alt="Home" />
        </a>
      </div>
      <div>
        {routes.map((r) => {
          if (auth.checkRules(r?.rules))
            return (
              <NavLink key={`leftnav_${r.href}`} to={r.href}>
                {r.title}
              </NavLink>
            );
          else return <></>;
        })}
      </div>
      {/* <div>
        <NavLink to="/">Settings</NavLink>
      </div> */}
      <div>
        <a href="#" onClick={auth.logout}>
          <Logout /> Logout
        </a>
      </div>
    </div>
  );
}
