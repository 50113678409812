import SearchIcon from "assets/icons/search";

const PageHeader = ({ title, searchField = true, buttonTitle = "", buttonActionHandler }) => {
  return (
    <div className="pageHeaderComponent flex justify-between">
      <h2 className="text-bleu-850">{title}</h2>
      <div className="flex items-center space-x-[42px]">
        {searchField && (
          <div className="flex h-[30px] w-[220px] items-center justify-between space-x-2 rounded-[2px] border border-gris-300 px-3">
            <input placeholder="Search" className="flex-1 border-0" />
            <SearchIcon />
          </div>
        )}

        {buttonTitle && (
          <button className="h-[40px] px-[22px]" onClick={buttonActionHandler}>
            {buttonTitle}
          </button>
        )}
      </div>
    </div>
  );
};

export default PageHeader;
