import axios from "axios";
import { Buffer } from "buffer";
import { RULES, SUBSCRIPTION_STATUS } from "constants/index";
import { initializeApp } from "firebase/app";
import {
  createUserWithEmailAndPassword,
  getAuth,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
  updatePassword,
} from "firebase/auth";
import { getDatabase, off, onValue, ref } from "firebase/database";
import { createContext, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

import http from "./http";

const CURRENT_SUB_LOCAL = "CURRENT_SUBSCRIPTION_LOCAL";
const LOGGED_USER_LOCAL = "LOGGED_USER_LOCAL";

const AuthContext = createContext(null);

const getLocallyStoredCurrentSub = () => {
  let data = localStorage.getItem(CURRENT_SUB_LOCAL);
  if (data) {
    try {
      return JSON.parse(data);
    } catch (e) {
      return null;
    }
  }
  return null;
};

const setLocallyStoredCurrentSub = (sub) => {
  let data = sub ? JSON.stringify(sub) : "";
  localStorage.setItem(CURRENT_SUB_LOCAL, data);
};

const getLocalUser = () => {
  let data = localStorage.getItem(LOGGED_USER_LOCAL);
  if (data) {
    try {
      return JSON.parse(data);
    } catch (e) {
      return null;
    }
  }
  return null;
};

const setLocalUser = (user) => {
  let data = user ? JSON.stringify(user) : "";
  localStorage.setItem(LOGGED_USER_LOCAL, data);
};

export const AuthProvider = ({ children }) => {
  //TODO: probably the user below is to be deleted since we are depending on the token
  const [user, setUser] = useState(getLocalUser());
  const [currentSubscription, setCurrentSubscription] = useState(getLocallyStoredCurrentSub());
  const navigate = useNavigate();
  const axiosInstance = axios.create({
    baseURL: `https://securetoken.googleapis.com/v1`,
  });

  const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  };

  const app = initializeApp(firebaseConfig);
  const auth = getAuth(app);
  const db = getDatabase(app);

  const login = async (email, password) => {
    return new Promise((resolve, reject) => {
      signInWithEmailAndPassword(auth, email, password)
        .then((result) => {
          let userObj = { email: result.user.email };
          setUser(userObj);
          setLocalUser(userObj);
          localStorage.setItem("authToken", result._tokenResponse.idToken);
          localStorage.setItem("refreshToken", result._tokenResponse.refreshToken);
          setTimeout(refreshAuthToken, 1000 * 60 * 60);

          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const register = async (registrationData) => {
    const { name, email, password } = registrationData;
    try {
      const res = await createUserWithEmailAndPassword(auth, email, password);
      sessionStorage.setItem("authToken", res._tokenResponse.idToken);
      sessionStorage.setItem("refreshToken", res._tokenResponse.refreshToken);

      let callback = null;
      let metadataRef = null;

      auth.onAuthStateChanged((user) => {
        // Remove previous listener.
        if (callback) {
          // onValue(metadataRef, callback);
          off(metadataRef, "value", undefined);
          // metadataRef.off("value", callback);
        }
        // On user login add new listener.
        if (user) {
          // Check if refresh is required.
          metadataRef = ref(db, "metadata/" + user.uid + "/refreshTime");
          // ref(db, "posts/" + postId + "/starCount");
          // metadataRef = db.database().ref("metadata/" + user.uid + "/refreshTime");
          callback = async (snapshot) => {
            // Force refresh to pick up the latest custom claims changes.
            // Note this is always triggered on first call. Further optimization could be
            // added to avoid the initial trigger when the token is issued and already contains
            // the latest claims.
            user.getIdToken(true);
            await refreshSessionAuthToken();
            const authJson = decodedSessionToken();

            delete registrationData.email;
            delete registrationData.password;
            delete registrationData.confirmPassword;

            if (authJson?.custom_claims?.customer?.customerId) {
              try {
                await fetch(
                  `${process.env.REACT_APP_BACKEND_URL}/customers/${authJson?.custom_claims?.customer?.customerId}`,
                  {
                    body: JSON.stringify(registrationData),
                    headers: {
                      Accpet: "application/json",
                      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
                      "content-type": "application/json",
                    },
                    method: "PATCH",
                  }
                );
              } catch (err) {
                console.log(err);
              }

              sessionStorage.clear();
              setUser(null);
              try {
                signOut(auth);
              } catch (e) {}
            }
          };
          // Subscribe new listener to changes on that node.
          // metadataRef.on("value", callback);
          onValue(metadataRef, callback);
        }
      });

      return {
        success: true,
      };
    } catch (err) {
      console.log(err);
      localStorage.clear();
      return {
        message: err.message,
        success: false,
      };
    }
  };

  const sendPasswordReset = async (email) => {
    try {
      await sendPasswordResetEmail(auth, email);
    } catch (error) {
      throw error;
    }
  };

  const logout = () => {
    localStorage.clear();

    try {
      signOut(auth);
    } catch (e) {}

    setUser(null);
    navigate("/login");
  };

  const decodeToken = (token) => {
    try {
      let res = JSON.parse(Buffer.from(token.split(".")[1], "base64"));
      return res;
    } catch (e) {
      return null;
    }
  };

  //this method is only used for using inside register hook
  const refreshSessionAuthToken = async () => {
    try {
      const refreshPayload = {
        grant_type: "refresh_token",
        refresh_token: sessionStorage.getItem("refreshToken"),
      };
      await axiosInstance
        .post(`/token?key=${firebaseConfig.apiKey}`, refreshPayload)
        .then((result) => {
          const { data } = result;
          sessionStorage.setItem("authToken", data.id_token);
          sessionStorage.setItem("refreshToken", data.refresh_token);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const refreshAuthToken = async () => {
    try {
      const refreshPayload = {
        grant_type: "refresh_token",
        refresh_token: localStorage.getItem("refreshToken"),
      };
      await axiosInstance
        .post(`/token?key=${firebaseConfig.apiKey}`, refreshPayload)
        .then((result) => {
          const { data } = result;
          localStorage.setItem("authToken", data.id_token);
          localStorage.setItem("refreshToken", data.refresh_token);

          setTimeout(refreshAuthToken, 1000 * 60 * 60);
        });
    } catch (error) {
      console.log(error);
      logout();
    }
  };

  const isUserLoggedIn = () => {
    var authToken = localStorage.getItem("authToken");
    try {
      const { exp } = decodeToken(authToken);
      return Date.now() < exp * 1000;
    } catch (error) {
      return false;
    }
  };

  const isAdmin = () => {
    var authToken = localStorage.getItem("authToken");
    try {
      if (isUserLoggedIn()) {
        const authJsonToken = decodeToken(authToken);
        return authJsonToken.custom_claims.role === 0;
      } else {
        throw new Error("User is not logged in");
      }
    } catch (error) {
      return false;
    }
  };

  const isCustomer = () => {
    var authToken = localStorage.getItem("authToken");
    try {
      if (isUserLoggedIn()) {
        const authJsonToken = decodeToken(authToken);
        return authJsonToken.custom_claims.role === 1;
      } else {
        throw new Error("User is not logged in");
      }
    } catch (error) {
      return false;
    }
  };

  const getLoggedInEmail = () => {
    var authToken = localStorage.getItem("authToken");
    if (isUserLoggedIn()) {
      const authJsonToken = decodeToken(authToken);
      return authJsonToken?.email || "";
    }
    return "";
  };

  const validateCurrentAndUpdatePassword = async (data) => {
    try {
      let result = await signInWithEmailAndPassword(
        auth,
        auth.currentUser.email,
        data.currentPassword
      );
      result = await updatePassword(auth.currentUser, data.newPassword);
      return {
        success: true,
      };
    } catch (e) {
      return {
        message: e.message,
        success: false,
      };
    }
  };

  //this method is only used for register
  const decodedSessionToken = () => {
    let authToken = sessionStorage.getItem("authToken");
    try {
      const authJsonToken = decodeToken(authToken);
      return authJsonToken;
    } catch (e) {
      return null;
    }
  };

  const decodedToken = () => {
    let authToken = localStorage.getItem("authToken");
    try {
      const authJsonToken = decodeToken(authToken);
      return authJsonToken;
    } catch (e) {
      return null;
    }
  };

  const getCustomerId = () => {
    let user = decodedToken();
    if (user) return user?.custom_claims?.customer?.customerId;
    else return "";
  };

  const getCurrentSubscription = async () => {
    try {
      let result = await http.get(`/customers/subscriptions`);
      setCurrentSubscription(result?.data?.subscription);
      setLocallyStoredCurrentSub(result?.data?.subscription);
    } catch (e) {
      setCurrentSubscription(null);
      setLocallyStoredCurrentSub(null);
    }
  };

  const checkRules = (rules = null) => {
    if (!rules) return true;
    if (rules.includes(RULES.hasActiveSub)) {
      if (currentSubscription.status === SUBSCRIPTION_STATUS.active) return true;
      else return false;
    }
  };

  return (
    <AuthContext.Provider
      value={{
        checkRules,
        currentSubscription,
        decodedToken,
        getCurrentSubscription,
        getCustomerId,
        getLoggedInEmail,
        isAdmin,
        isCustomer,
        isUserLoggedIn,
        login,
        logout,
        register,
        sendPasswordReset,
        user,
        validateCurrentAndUpdatePassword,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
