import "./label-with-value.css";

const LabelWithValue = ({ containerClass = "", label, value }) => {
  return (
    <div className={`LabelWithValue ${containerClass}`}>
      <p className="label">{label}</p>
      <p className="value">{value}</p>
    </div>
  );
};

export default LabelWithValue;
