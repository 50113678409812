import "./register-box.css";

import logo from "assets/images/logo.svg";
import { Countries, Industries } from "constants";
import { useAuth } from "lib/auth";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export default function RegisterBox() {
  const { register, handleSubmit, formState, getValues } = useForm();
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const auth = useAuth();
  const navigate = useNavigate();

  const handleRegister = async (data) => {
    //TODO: After finalizing correct data registration, check below for error handling
    setIsLoading(true);
    let result = await auth.register(data);
    if (result.success) {
      setIsFormSubmitted(true);
      setEmail(data.email);
    } else {
      toast.error(result.message);
    }

    setIsLoading(false);
  };

  var sortedCountries = Object.entries(Countries).sort((a, b) => {
    if (a[1] < b[1]) {
      return -1;
    }
    if (a[1] > b[1]) {
      return 1;
    }
    return 0;
  });

  return (
    <div className="registerBox">
      <div>
        <img className="mx-auto" src={logo} alt="NVISION3D" width={"210px"} />
      </div>
      {!isFormSubmitted && (
        <form onSubmit={handleSubmit(handleRegister)}>
          <div className="exoTitle pt-16 pb-9">Register</div>
          <div className="min-w-[382px]">
            <input
              placeholder="First Name"
              type="text"
              {...register("first_name", {
                required: true,
              })}
            />
          </div>
          <div className="validationError">
            {formState.errors?.first_name?.type === "required" && "First name is required"}
          </div>
          <div>
            <input
              placeholder="Last Name"
              type="text"
              {...register("last_name", {
                required: true,
              })}
            />
          </div>
          <div className="validationError">
            {formState.errors?.last_name?.type === "required" && "Last name is required"}
          </div>
          <div>
            <input
              placeholder="Email Address"
              type="email"
              {...register("email", {
                pattern: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                required: true,
              })}
            />
          </div>
          <div className="validationError">
            {formState.errors?.email?.type === "required" && "Email is required"}
            {formState.errors?.email?.type === "pattern" && "Email is not valid"}
          </div>
          <div>
            <input
              type="password"
              placeholder="Password"
              {...register("password", { minLength: 8, required: true })}
            />
          </div>
          <div className="validationError">
            {formState.errors?.password?.type === "required" && "Password is required"}
            {formState.errors?.password?.type === "minLength" &&
              "Password should be at least 8 characters long"}
          </div>
          <div>
            <input
              type="password"
              placeholder="Confirm Password"
              {...register("confirmPassword", {
                minLength: 8,
                required: true,
                validate: {
                  matchesPreviousPassword: (value) => {
                    const { password } = getValues();
                    return password === value || "Passwords don't match";
                  },
                },
              })}
            />
          </div>
          <div className="validationError">
            {formState.errors?.confirmPassword?.type === "required" &&
              "Confirm password is required"}
            {formState.errors?.confirmPassword?.type === "minLength" &&
              "Password should be at least 8 characters long"}
            {formState.errors?.confirmPassword?.type === "matchesPreviousPassword" &&
              formState.errors.confirmPassword.message}
          </div>
          <div>
            <select
              name="country"
              placeholder="Country"
              {...register("country", {
                required: true,
              })}
            >
              <option value="">Select Country</option>
              {sortedCountries.map((e, i) => {
                const [key, value] = e;
                return (
                  <option key={"country" + i} value={key}>
                    {value}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="validationError">
            {formState.errors?.country?.type === "required" && "Country is required"}
          </div>
          <div>
            <input
              placeholder="Phone Number"
              type="text"
              {...register("phone_number", {
                required: true,
              })}
            />
          </div>
          <div className="validationError">
            {formState.errors?.phone_number?.type === "required" && "Phone number is required"}
          </div>
          <div>
            <input
              placeholder="Company Name"
              type="text"
              {...register("company", {
                required: true,
              })}
            />
          </div>
          <div className="validationError">
            {formState.errors?.company?.type === "required" && "Company name is required"}
          </div>
          <div>
            <select
              placeholder="Industry"
              {...register("industry", {
                required: true,
              })}
            >
              <option value="">Select Industry</option>
              {Industries.map((e, i) => {
                return (
                  <option key={"industry" + i} value={e}>
                    {e}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="validationError">
            {formState.errors?.industry?.type === "required" && "Industry is required"}
          </div>
          {!isLoading && <button className="my-16 w-[65%]">Register</button>}

          {isLoading && <span className="loader my-16"></span>}
        </form>
      )}
      {isFormSubmitted && (
        <>
          <div className="exoTitle mt-14">Thank you for Registering</div>
          {/* <div className="mt-8 mb-32 text-sm font-semibold">
            An email has been sent to <span className="text-bleu-100">{email}</span> with further
            instructions on how to create your password.
          </div> */}
          <button onClick={() => navigate("/login")} className="my-16 w-[65%]">
            Go to Login
          </button>
        </>
      )}
    </div>
  );
}
