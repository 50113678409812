import { PAYMENT_APPROACH, SUBSCRIPTION_STATUS } from "constants/index";

export const getDateFromUnix = (_seconds, _nanoseconds) => {
  // const unixTime = _seconds * 1000 + _nanoseconds;
  try {
    const unixTime = _seconds * 1000;
    const date = new Date(unixTime);
    return <>{date.toLocaleDateString("en-US")}</>;
  } catch (e) {
    return "";
  }
};

export const subscriptionPaymentTypeText = (paymentApproach) => {
  switch (paymentApproach) {
    case PAYMENT_APPROACH.monthOnce:
      return "Monthly";
    case PAYMENT_APPROACH.yearlyOnce:
      return "One Time Annual";
    case PAYMENT_APPROACH.annuallyOnce:
      return "One Time Annual";
    case PAYMENT_APPROACH.monthlyForYear:
      return "Annual Monthly Basis";
    default:
      return "";
  }
};

export const subscriptionStatusText = (status) => {
  switch (status) {
    case SUBSCRIPTION_STATUS.active:
      return "Active";
    case SUBSCRIPTION_STATUS.cancelled:
      return "Cancelled";
    case SUBSCRIPTION_STATUS.expired:
      return "Expired";
    case SUBSCRIPTION_STATUS.suspended:
      return "Suspended";
    case SUBSCRIPTION_STATUS.terminated:
      return "Terminated";
    default:
      return "";
  }
};

export const removeFalsy = (obj) => {
  let newObj = {};
  Object.keys(obj).forEach((props) => {
    if (obj[props] || obj[props] === "0" || obj[props] === 0 || obj[props] !== "") {
      newObj[props] = obj[props];
    }
  });

  return newObj;
};

export const generateQueryParams = (params) => {
  return Object.keys(removeFalsy(params))
    .filter((key) => params[key])
    .map((key) => `${key}=${params[key]}`)
    .join("&");
};
