import "./protected-layout.css";

import { ProtectedLayoutHeader, ProtectedLayoutLeftNavbar } from "components";
import { SUBSCRIPTION_STATUS } from "constants/index";
import { useAuth } from "lib/auth";
import { useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";

export default function ProtectedLayout(props) {
  const location = useLocation();
  const { isAdmin, isCustomer, isUserLoggedIn, getCurrentSubscription, currentSubscription } =
    useAuth();

  useEffect(() => {
    getCurrentSubscription();
  }, []);

  if (!isUserLoggedIn()) return <Navigate to="/login" />;

  if (location.pathname.includes("/customer/") && !isCustomer()) return <Navigate to="/" />;

  if (location.pathname.includes("/admin/") && !isAdmin()) return <Navigate to="/" />;

  if (isCustomer() && !currentSubscription) return <Navigate to="/purchase" />;

  return (
    <div className="protectedLayout">
      <div>
        <ProtectedLayoutLeftNavbar isAdmin={isAdmin()} currentSubscription={currentSubscription} />
      </div>
      <div>
        <ProtectedLayoutHeader />
      </div>
      <div>
        {isCustomer() &&
          (!currentSubscription || currentSubscription?.status !== SUBSCRIPTION_STATUS.active) && (
            <div
              className="mb-4 rounded-lg bg-red-100 p-4 text-sm text-red-700 dark:bg-red-200 dark:text-red-800"
              role="alert"
            >
              <span className="font-medium">
                Your subscription has expired. Please renew your subscription in order to continue
                using NVISION3D.
              </span>
            </div>
          )}
        {props.children}
      </div>
    </div>
  );
}
