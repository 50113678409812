import { Modal, PurchaseSeats } from "components";
import React, { useMemo, useState } from "react";

const AvailableSeatCount = ({ customerId, data, disabled }) => {
  const [addMoreSeatModal, setAddMoreSeatModal] = useState(false);

  const availableSeat = useMemo(() => {
    let count = 0;
    data.forEach((element) => {
      if (!element?.data?.fingerprint && !element?.data?.login) count++;
    });
    return count;
  }, [data]);

  return (
    <div className="rounded-[3px] border border-gris-300 p-7">
      <p className="text-fortyfour text-bleu-100">
        {availableSeat}/{data.length} Seats
      </p>
      <p className="pb-9 text-sixteen font-semibold text-bleu-850">Available</p>
      <button
        disabled={disabled}
        className={`text-xs ${disabled && `!bg-gris-600`}`}
        onClick={() => setAddMoreSeatModal(true)}
      >
        Purchase more
      </button>
      <Modal
        show={addMoreSeatModal}
        onClose={() => {
          setAddMoreSeatModal(false);
        }}
      >
        <PurchaseSeats
          customerId={customerId}
          onClose={() => {
            setAddMoreSeatModal(false);
          }}
        />
      </Modal>
    </div>
  );
};

export default AvailableSeatCount;
