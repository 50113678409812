import "./hero.css";

import { HashLink } from "react-router-hash-link";

export default function Hero() {
  return (
    <div className="hero md:min-h-[939px]">
      <div className="p-2 pt-28 pb-10 md:ml-40 md:w-96 md:pt-80 md:pb-0">
        <div className="title">The Future of 3D Design Software</div>
        <div className="mb-10 mt-5">
          Step into the future with Nvision3D design and visualization software to design cabinets
          and home interior in 3D from the start.
          <br />
          Find out more or sign up, today!
        </div>
        <div>
          <HashLink smooth to="/#parametrics">
            <button className="gris">Explore</button>
          </HashLink>
        </div>
      </div>
    </div>
  );
}
