import { Sort } from "assets/icons";
import { Modal, SimplePaging } from "components";
import { CouponAddForm, PageHeader } from "components/admin";
import { useGetCouponsQuery } from "modules/coupon/couponService";
import { useMemo, useState } from "react";
import { useSortBy, useTable } from "react-table";

const DEFAULT_PARAMS = {
  endBeforeId: "",
  limit: 10,
  startAfterId: "",
  toFirst: "true",
  toLast: "",
};

const COLUMN = [
  {
    Cell: (props) => (
      <>
        <p className="font-bold text-bleu-850">{props.row.original.data.name}</p>
      </>
    ),
    Header: "Name",
    accessor: "name",
    // isSortable: true,
    width: "20%",
  },
  {
    Cell: (props) => (
      <>
        <p className="text-bleu-850">
          {props.row.original.data.value}
          {props.row.original.data.type === "usd" ? "$" : "%"}
        </p>
      </>
    ),
    Header: "Discount",
    accessor: "value",
    // isSortable: true,
    width: "20%",
  },
  {
    Cell: (props) => (
      <>
        <p className="text-bleu-100">{props.row.original.data.code}</p>
      </>
    ),
    Header: "Code",
    accessor: "code",
    // isSortable: true,
    width: "20%",
  },
  {
    Cell: (props) => (
      <>
        <p
          className={`font-bold ${props.row.original.data.status === `active` && `text-bleu-100`} ${
            props.row.original.data.status === `redeemed` && `text-green-800`
          } ${props.row.original.data.status === `expired` && `text-red-500`}ƒ`}
        >
          {props.row.original.data.expiration_date}
        </p>
      </>
    ),
    Header: "Expiry date",
    accessor: "expired_at",
    // isSortable: true,
    width: "20%",
  },
  {
    Cell: (props) => (
      <>
        <p
          className={`${props.row.original.data.status === `active` && `text-bleu-100`} ${
            props.row.original.data.status === `redeemed` && `text-green-800`
          } ${props.row.original.data.status === `expired` && `text-red-500`}`}
        >
          {props.row.original.data.status.charAt(0).toUpperCase() +
            props.row.original.data.status.slice(1)}
        </p>
      </>
    ),
    Header: "Status",
    accessor: "status",
    // isSortable: true,
    width: "20%",
  },
];

export default function Coupons() {
  const [params, setParams] = useState(DEFAULT_PARAMS);
  const [selectedCoupon, setSelectedCoupon] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const { data, isLoading, isFetching } = useGetCouponsQuery();

  const couponsData = useMemo(() => {
    if (data) return data?.data || [];
    return [];
  }, [data]);

  const onChangePaginationHandler = (obj) => {
    setParams((p) => ({ ...p, ...obj }));
  };

  const tableInstance = useTable(
    {
      columns: COLUMN,
      data: couponsData,
    },
    useSortBy
  );
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;
  return (
    <>
      <PageHeader
        title="Coupons"
        buttonTitle="Add Coupon"
        buttonActionHandler={() => setShowModal((p) => !p)}
      />
      {isLoading ? (
        <p className="mt-10">Loading data ...</p>
      ) : (
        <table className="mt-10 !w-3/4" {...getTableProps()}>
          <thead>
            {
              // Loop over the header rows
              headerGroups.map((headerGroup) => (
                // Apply the header row props
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {
                    // Loop over the headers in each row
                    headerGroup.headers.map((column) => {
                      let thProps = column.getHeaderProps();
                      if (column.isSortable) {
                        thProps = column.getHeaderProps(column.getSortByToggleProps());
                      }
                      return (
                        <th {...thProps} width={column.width}>
                          {column.render("Header")}
                          {column.isSortable && <Sort />}
                        </th>
                      );
                    })
                  }
                </tr>
              ))
            }
          </thead>
          {/* Apply the table body props */}
          <tbody {...getTableBodyProps()}>
            {
              // Loop over the table rows
              rows.map((row, i) => {
                // Prepare the row for display
                prepareRow(row);
                const rowClass = i % 2 ? "odd" : "even";
                return (
                  // Apply the row props
                  <tr {...row.getRowProps()} className={rowClass}>
                    {
                      // Loop over the rows cells
                      row.cells.map((cell) => {
                        // Apply the cell props
                        return (
                          <td {...cell.getCellProps()}>
                            {
                              // Render the cell contents
                              cell.render("Cell")
                            }
                          </td>
                        );
                      })
                    }
                  </tr>
                );
              })
            }
          </tbody>
        </table>
      )}

      {!(isLoading || isFetching) && (
        <div className="mt-6 flex items-center justify-between">
          <SimplePaging
            data={{
              nextPageId: data?.nextPageId || "",
              prevPageId: data?.prevPageId || "",
            }}
            onChangePagination={onChangePaginationHandler}
          />
          <p>
            Showing {couponsData?.length} out of {data?.total || "-"}
          </p>
        </div>
      )}

      <Modal
        show={showModal}
        onClose={() => {
          setShowModal(false);
          // setSelectedCoupon(null);
        }}
      >
        <CouponAddForm
          // selectedCoupon={selectedCoupon}
          onClose={() => {
            setShowModal(false);
            // setSelectedCoupon(null);
          }}
        />
      </Modal>
      {/* {showModal && <CouponAddForm />} */}
    </>
  );
}
