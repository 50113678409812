import { Loader } from "components";
import { PageHeader } from "components/admin";
import { useAddApplinkMutation, useGetApplinksQuery } from "modules/app-links/appLinkActions";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

const ManageAppLinks = () => {
  const { register, handleSubmit, formState, setValue } = useForm();
  const { data, isLoading: isLoadingLinks } = useGetApplinksQuery();
  const [addApplink, { isLoading }] = useAddApplinkMutation();

  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    if (data?.data) {
      setValue("desktop", data?.data?.desktop || "");
      setValue("ios", data?.data?.ios || "");
      setValue("android", data?.data?.android || "");
    }
  }, [data]);

  const handleSubmitForm = async (fData) => {
    let result = await addApplink(fData);
    if (result.error) {
      toast.error(result?.error?.data?.message || "Something went wrong");
    } else {
      toast.success("App link updated successfully");
    }
  };

  return (
    <div>
      <PageHeader title="Manage App Link" searchField={false} />
      {!editMode ? (
        isLoadingLinks ? (
          <p className="mt-12">Fetching data...</p>
        ) : (
          <div className="mt-12">
            <div className="formElement">
              <p className="formTitle">Registered Desktop Link</p>
              <a target="_blank" href={data?.data?.desktop} download>
                {data?.data?.desktop}
              </a>
            </div>
            <button className="mt-4" onClick={() => setEditMode(true)}>
              Edit
            </button>
          </div>
        )
      ) : (
        <form className="w-1/2" onSubmit={handleSubmit(handleSubmitForm)}>
          <Loader isLoading={isLoading} />
          <div className="mt-12">
            <div className="flex flex-1 flex-col gap-10">
              <div className="formElement">
                <p className="formTitle">Desktop Link</p>
                <div className="formInputWrapper">
                  <input
                    className="formTextInput"
                    {...register("desktop", {
                      required: true,
                    })}
                  />
                </div>
                <div className="validationError">
                  {formState.errors?.desktop?.type === "required" && "Desktop app link is required"}
                </div>
              </div>
            </div>
            <div className="mt-10 flex justify-end gap-5">
              <button onClick={() => setEditMode(false)} type="button" className="btn-secondary">
                Back
              </button>
              <button>Save changes</button>
            </div>
          </div>
        </form>
      )}
    </div>
  );
};

export default ManageAppLinks;
