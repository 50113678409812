import { Loader } from "components";
import { SUBSCRIPTION_STEP_TWO_PURCHASE_TYPE } from "constants/index";
import {
  formatSubscriptionTypeData,
  useFetchSubscriptionCostMutation,
} from "modules/subscription/subscriptionActions";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";

const SubscriptionPurchaseStepTwo = ({
  isLoading = false,
  selectedSubscription,
  paymentOption,
  ignoreOldSeats = null,
  setIgnoreOldSeats = null,
  priceData,
  onSelect = null,
  onNext,
  setPriceData,
  couponCode,
  setCouponCode,
  purchaseType = SUBSCRIPTION_STEP_TWO_PURCHASE_TYPE.initialPurchase,
}) => {
  const inputRef = useRef(null);
  const [couponAddStep, setCouponAddStep] = useState(0);
  const [fetchCost, { isLoading: isFetchingCost }] = useFetchSubscriptionCostMutation();

  useEffect(() => {
    getCostCalculation(selectedSubscription?.id, paymentOption, ignoreOldSeats, couponCode);
  }, [selectedSubscription, paymentOption, ignoreOldSeats]);

  const getCostCalculation = async (
    subscriptionTypeId,
    paymentApproach,
    ignoreOldSeats,
    couponCode = ""
  ) => {
    if (isFetchingCost) return;
    let body = {
      paymentApproach,
      subscriptionTypeId,
    };
    if (couponCode) body.couponCode = couponCode;
    if (purchaseType === SUBSCRIPTION_STEP_TWO_PURCHASE_TYPE.rePurchase)
      body.ignoreOldSeats = ignoreOldSeats;

    let result = await fetchCost(body);
    if (result?.error?.status) {
      toast.error(result?.error?.data?.message || "Something went wrong.");
      if (couponCode) setCouponCode("");
    } else {
      setPriceData(result?.data);
      if (couponCode) setCouponAddStep(2);
      else {
        setCouponAddStep(0);
        if (inputRef?.current?.value) inputRef.current.value = "";
      }
    }
  };

  const subData = selectedSubscription
    ? formatSubscriptionTypeData(selectedSubscription.data)
    : null;

  const applyCoupon = async () => {
    if (inputRef?.current?.value) {
      setCouponCode(inputRef.current.value);
      getCostCalculation(
        selectedSubscription?.id,
        paymentOption,
        ignoreOldSeats,
        inputRef.current.value
      );
    } else {
      toast.error("Please insert a coupon first.");
    }
  };

  return (
    <div className="flex flex-row items-center justify-center gap-10">
      <div className="min-h-[550px] w-[400px] rounded-md bg-bleu-800 p-10 text-left shadow-lg">
        {purchaseType === SUBSCRIPTION_STEP_TWO_PURCHASE_TYPE.rePurchase && (
          <div className="mb-4 flex flex-row items-center justify-start">
            <input
              className="h-6 w-6"
              type="checkbox"
              style={{ marginRight: 15 }}
              name="previousSeatsOption"
              id="previousSeatsOption"
              checked={ignoreOldSeats}
              onChange={(e) => setIgnoreOldSeats(e.target.checked)}
            />
            <label className="text-lg" htmlFor="previousSeatsOption">
              Ignore Old Seats
            </label>
          </div>
        )}
        <p className="text-[32px] text-bleu-100">Choose payment option</p>
        <p className="pt-3 pb-3 text-sm opacity-[.7]">Commitment</p>
        <div className="payment-options">
          <div className="mb-4 flex flex-row items-center justify-start">
            <input
              className="h-6 w-6 rounded-full shadow focus:ring-0"
              type="radio"
              style={{ marginRight: 15 }}
              name="paymentOption1"
              id="paymentOption1"
              value="MonthlyForYear"
              onChange={(e) => onSelect(e.target.value)}
              checked={paymentOption === "MonthlyForYear"}
            />
            <label className="text-lg" htmlFor="paymentOption1">
              Annual plan, paid monthly <br />
              {/* <p className="text-gris-600">${selectedSubscription?.data?.monthly_fee}/mo</p> */}
            </label>
          </div>
          <div className="mb-4 flex flex-row items-center justify-start">
            <input
              className="h-6 w-6 rounded-full shadow focus:ring-0"
              type="radio"
              style={{ marginRight: 15 }}
              name="paymentOption2"
              id="paymentOption2"
              value="AnnuallyOnce"
              onChange={(e) => onSelect(e.target.value)}
              checked={paymentOption === "AnnuallyOnce"}
            />
            <label className="text-lg" htmlFor="paymentOption2">
              Annual plan, paid once-off <br />
              {/* <p className="text-gris-600">${selectedSubscription?.data?.annual_fee}</p> */}
            </label>
          </div>
          <div className="mb-4 flex flex-row items-center justify-start">
            <input
              className="h-6 w-6 rounded-full shadow focus:ring-0"
              type="radio"
              style={{ marginRight: 15 }}
              name="paymentOption3"
              id="paymentOption3"
              value="MonthlyOnce"
              onChange={(e) => onSelect(e.target.value)}
              checked={paymentOption === "MonthlyOnce"}
            />
            <label className="text-lg" htmlFor="paymentOption3">
              Monthly Subscription <br />
              {/* <p className="text-gris-600">${selectedSubscription?.data?.monthly_fee} /mo</p> */}
            </label>
          </div>
        </div>
        <p className="pt-8 text-xs opacity-[.7]">
          Subscriptions do not renew automatically. You can purchase a new subsequent subscription
          in your NVISION3D customer portal.
        </p>
      </div>

      <div className="relative min-h-[550px] w-[400px] rounded-md bg-bleu-400 p-10 text-left shadow-lg">
        <Loader isLoading={isFetchingCost || isLoading} />
        <p className="pb-8 text-[32px] text-bleu-100">{selectedSubscription?.data?.name}</p>
        <p className="pb-20 text-xs opacity-[.7]">{subData?.description}</p>
        <div className="flex flex-row justify-between pb-4">
          <p className="text-bleu-100">Subtotal</p>
          <p>${priceData?.totalFee}</p>
        </div>
        {couponAddStep === 0 && (
          <p
            onClick={() => setCouponAddStep(1)}
            className="cursor-pointer pb-4 text-right text-bleu-100"
          >
            Apply a coupon?
          </p>
        )}
        {couponAddStep === 1 && (
          <div className="flex flex-col gap-1 pb-4">
            <div className="flex gap-4">
              <p className="flex-1 text-bleu-100">Coupon Code</p>
              <input
                ref={inputRef}
                defaultValue={couponCode}
                className="flex-1 p-0"
                style={{ width: "unset" }}
              />
            </div>
            <button className="flex h-8 items-center justify-center self-end" onClick={applyCoupon}>
              Apply Code
            </button>
          </div>
        )}
        {couponAddStep === 2 && (
          <p
            onClick={() => {
              setCouponAddStep(0);
              setCouponCode("");
              getCostCalculation(
                selectedSubscription?.id,
                paymentOption,
                ignoreOldSeats,
                ""
              );
            }}
            className="cursor-pointer pb-4 text-right text-bleu-100"
          >
            Remove Coupon - {couponCode}
          </p>
        )}
        <div className="flex flex-row justify-between pb-20">
          <p className="text-bleu-100">Due now</p>
          <p>${priceData?.totalFee}</p>
        </div>
        <button
          disabled={!paymentOption}
          className={`w-full ${!paymentOption && `bg-gris-600`}`}
          onClick={onNext}
        >
          {purchaseType === SUBSCRIPTION_STEP_TWO_PURCHASE_TYPE.initialPurchase
            ? `Continue to payment`
            : `Purchase`}
        </button>
      </div>
    </div>
  );
};

export default SubscriptionPurchaseStepTwo;
