import "./invoice.css";

import { PageHeader } from "components/admin";
import InvoiceDetails from "components/invoice/invoice-details/invoice-details";
import { useGetInvoiceDetailsQuery } from "modules/payment-history/paymentHistoryActions";
import { useParams } from "react-router-dom";

const Invoice = () => {
  let { id } = useParams();
  const { data, isLoading } = useGetInvoiceDetailsQuery(id);

  return (
    <div>
      <PageHeader
        title="Invoice Details"
        searchField={false}
        buttonTitle="Print"
        buttonActionHandler={() => window.print()}
      />
      {isLoading ? <p className="mt-10">Loading data ...</p> : <InvoiceDetails data={data} />}
    </div>
  );
};

export default Invoice;
