import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  
};

export const couponSlice = createSlice({
  initialState,
  name: "coupon",
  reducers: {},
});

// export const {} = subscriptionSlice.actions;

export default couponSlice.reducer;
