import "./login.css";

import { LoginBox } from "components";

export default function Login() {
  return (
    <div className="loginPage">
      <LoginBox />
    </div>
  );
}
