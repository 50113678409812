import "./parametrics.css";

export default function Parametrics() {
  return (
    <div className="parametrics flex-col p-10 md:flex-row md:p-0 md:px-40 md:pl-24 md:py-[140px]" id="parametrics">
      <div className="h-[300px] w-full shrink-0 bg-[url('assets/images/illustration-1.png')] bg-cover bg-no-repeat md:w-[594px]"></div>
      <div>
        <div className="blueTitle">Nvision3D</div>
        <div className="exoTitle mb-4 mt-2">
          Say goodbye to the 2D design work of the past and welcome straight-to-3D design and
          visualization.
        </div>
        <div className="mb-7 text-lg leading-[30px]">
          With a range of benefits and features, it's time to ditch working with multiple file
          types, exports, and third-party software.
        </div>
        <div>
          <ul className="listCheckBullets">
            <li>
              Design in real-time 3D from the start and switch from 2D to 3D with a quick click of a
              button.
            </li>
            <li>
              Generate cut and materials lists, see dimensions and pricing, and represent your
              design in a fully visualized room.
            </li>
            <li>
              Render 3D models with accurate dimensions in no time. Does a client want to update the
              size? Show them in the meeting or share your screen to design with them.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
