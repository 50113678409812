import { Loader } from "components";
import { useAuth } from "lib/auth";
import { getDateFromUnix } from "lib/utils";
import { useAddSeatsMutation } from "modules/seat/seatActions";
import { useFetchSubscriptionSeatCostMutation } from "modules/subscription/subscriptionActions";
import { useEffect,useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

const PurchaseSeats = ({ customerId, onClose }) => {
  const [fetchCost, { isLoading: isFetchingCost }] = useFetchSubscriptionSeatCostMutation();
  const [addSeats, { isLoading }] = useAddSeatsMutation();
  const { register, handleSubmit, formState, watch } = useForm();
  const [step, setStep] = useState(1);
  const [totalCost, setTotalCost] = useState(0);

  const { currentSubscription, getCurrentSubscription } = useAuth();

  useEffect(() => {
    getCurrentSubscription();
  }, [getCurrentSubscription]);

  const handleCountSubmitForm = async (data) => {
    let body = {
      customerId,
      numberOfSeat: parseInt(data.count),
    };
    let result = await fetchCost(body);
    if (result?.error?.status) {
      toast.error(result?.error?.data?.message || "Something went wrong.");
    } else {
      setTotalCost(result?.data?.deltaAmount);
      setStep(2);
    }
  };

  const onConfirm = async () => {
    let body = {
      count: parseInt(watch("count")),
      customerId,
    };
    let result = await addSeats(body);
    if (result?.error?.status) {
      toast.error(result?.error?.data?.message || "Something went wrong.");
    } else {
      toast.success("Seat added successfully");
      onClose();
    }
  };

  return (
    <div className="relative w-[360px]">
      <Loader isLoading={isLoading || isFetchingCost} />
      <h2 className="font-exo text-thirtytwo font-semibold">Purchase Seat</h2>
      {step === 1 && (
        <form onSubmit={handleSubmit(handleCountSubmitForm)}>
          <div className="formElement mt-2 mb-4">
            <p className="formTitle">Number Of Seats</p>
            <div className="formInputWrapper">
              <input
                className="formTextInput"
                placeholder="Number of Seats"
                {...register("count", {
                  pattern: /^[+-]?([0-9]*[.])?[0-9]+$/,
                  required: true,
                })}
              />
            </div>
            <div className="validationError !text-red-600">
              {formState.errors?.count?.type === "required" && "Count is required"}
              {formState.errors?.count?.type === "pattern" && "Please enter valid number"}
            </div>
          </div>
          <div className="flex gap-5">
            <button className="btn-secondary h-[40px] flex-1" onClick={onClose}>
              Cancel
            </button>
            <button type="submit" className="flex-1 bg-bleu-100">
              Calculate
            </button>
          </div>
        </form>
      )}
      {step === 2 && (
        <>
          <p className="pt-10 text-twentysix font-semibold text-bleu-850">${totalCost}</p>
          <p className="pb-10 text-sixteen font-semibold text-gris-600">
            Expiry Date - {currentSubscription ? getDateFromUnix(currentSubscription.expiration_date?._seconds) : ''}
          </p>
          {/* <p className="pb-10 text-xs opacity-70">
            Your annual seat price is calculated based on the amount of time remaining on your
            current annual subscription.
          </p> */}
          <div className="flex gap-5">
            <button className="btn-secondary h-[40px] flex-1" onClick={onClose}>
              Cancel
            </button>
            <button onClick={onConfirm} className="flex-1">
              Confirm Purchase
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default PurchaseSeats;
