const Loader = ({ isLoading }) => {
  if (!isLoading) return <></>;
  return (
    <div className="absolute left-[50%] top-[50%] z-50 flex h-full w-full translate-x-[-50%] translate-y-[-50%] items-center justify-center bg-white opacity-50">
      <span className="loader"></span>
    </div>
  );
};

export default Loader;
