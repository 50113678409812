import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const SUBSCRIPTION_TYPE_API_REDUCER_KEY = "subscription-types-api";

export const subscriptionTypesApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BACKEND_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem("authToken");
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    addSubscriptionType: builder.mutation({
      invalidatesTags: ["SubscriptionType"],
      query: (contact) => ({
        body: contact,
        method: "POST",
        url: "/subscriptionTypes",
      }),
    }),
    publicSubscriptionTypes: builder.query({
      providesTags: ["SubscriptionTypePublic"],
      query: () => "/subscriptionTypes/public",
    }),
    subscriptionTypes: builder.query({
      providesTags: ["SubscriptionType"],
      query: () => "/subscriptionTypes/public",
    }),
    updateSubscriptionType: builder.mutation({
      invalidatesTags: ["SubscriptionType"],
      query: ({ id, ...body }) => ({
        body: body,
        method: "PUT",
        url: `/subscriptionTypes/${id}`,
      }),
    }),
  }),
  reducerPath: SUBSCRIPTION_TYPE_API_REDUCER_KEY,
  tagTypes: ["SubscriptionType", "SubscriptionTypePublic"],
});

export const {
  usePublicSubscriptionTypesQuery,
  useSubscriptionTypesQuery,
  useAddSubscriptionTypeMutation,
  useUpdateSubscriptionTypeMutation,
} = subscriptionTypesApi;
