import { configureStore } from "@reduxjs/toolkit";
import { appLinkApi } from "modules/app-links";
import { couponApi } from "modules/coupon";
import { paymentHistoryApi } from "modules/payment-history";
import { paymentMethodApi } from "modules/payment-method";
import { profileApi } from "modules/profile";
import { seatApi } from "modules/seat";
import { subscriptionsApi, subscriptionTypesApi } from "modules/subscription";

import { unauthenticatedMiddleware } from "./middlewares/unauthenticatedMiddleware";
import rootReducer from "./reducer";

export const store = configureStore({
  // middleware: [],
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      unauthenticatedMiddleware,
      appLinkApi.middleware,
      subscriptionsApi.middleware,
      subscriptionTypesApi.middleware,
      profileApi.middleware,
      paymentHistoryApi.middleware,
      paymentMethodApi.middleware,
      couponApi.middleware,
      seatApi.middleware,
    ]),
  reducer: rootReducer,
});
