import { Loader } from "components";
import {
  useAddSubscriptionTypeMutation,
  useUpdateSubscriptionTypeMutation,
} from "modules/subscription/subscriptionActions";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

const AddEditForm = ({ selectedSubscription = null, onClose }) => {
  const { register, handleSubmit, formState, setValue } = useForm();

  const [addSubscriptionType, { isLoading }] = useAddSubscriptionTypeMutation();

  const [updateSubscriptionType, { isLoading: isLoadingUpdate }] =
    useUpdateSubscriptionTypeMutation();

  useEffect(() => {
    if (selectedSubscription?.data?.description) {
      try {
        let parsedData = JSON.parse(selectedSubscription?.data?.description);
        setValue("description", parsedData?.description || "");
        setValue("features", parsedData?.features || []);
      } catch (e) {
        setValue("description", selectedSubscription?.data?.description);
      }
    }
  }, []);

  const editMode = !!selectedSubscription;

  const handleSubmitAddForm = async (data) => {
    data.description = JSON.stringify({
      description: data.description || "",
      features: data.features || [],
    });
    delete data.features;
    let result = await addSubscriptionType(data);
    if (result.error) {
      toast.error(result?.error?.data?.message || "Something went wrong");
    } else {
      toast.success("Subscription type added successfully");
      onClose();
    }
  };

  const handleSubmitEditForm = async (data) => {
    data.description = JSON.stringify({
      description: data.description || "",
      features: data.features || [],
    });
    delete data.features;
    delete data.tier; //TODO:: need to handle tier validation on BE
    data.id = selectedSubscription?.id;
    let result = await updateSubscriptionType(data);
    if (result.error) {
      toast.error(result?.error?.data?.message || "Something went wrong");
    } else {
      toast.success("Subscription type updated successfully");
      onClose();
    }
  };

  return (
    <div className="relative w-[700px]">
      <Loader isLoading={isLoading || isLoadingUpdate} />
      <h2 className="page-title">Edit/Add Subscription</h2>
      <form onSubmit={handleSubmit(editMode ? handleSubmitEditForm : handleSubmitAddForm)}>
        <div className="subscription-add-edit-form-container mt-12">
          <div className="form-fields flex flex-row gap-[76px]">
            <div className="flex flex-1 flex-col gap-10">
              <div className="formElement">
                <p className="formTitle">Name</p>
                <div className="formInputWrapper">
                  <input
                    className="formTextInput"
                    {...register("name", {
                      required: true,
                      value: selectedSubscription?.data?.name || "",
                    })}
                  />
                </div>
                <div className="validationError">
                  {formState.errors?.name?.type === "required" && "Name is required"}
                </div>
              </div>

              <div className="formElement">
                <p className="formTitle">Description</p>
                <textarea
                  className="formInputTextarea"
                  {...register("description", {
                    required: true,
                  })}
                ></textarea>
                <div className="validationError">
                  {formState.errors?.description?.type === "required" && "Description is required"}
                </div>
              </div>

              <div className="formElement">
                <p className="formTitle">Tier Level</p>
                <div class="mt-4 flex items-center">
                  <input
                    className={`formInputCheckbox`}
                    type="radio"
                    defaultValue={"1"}
                    {...register("tier", {
                      required: true,
                      value: selectedSubscription?.data?.tier || "",
                    })}
                  />
                  <label class="formInputCheckboxLabel">Tier 1</label>
                </div>
                <div class="mt-4 flex items-center">
                  <input
                    className={`formInputCheckbox`}
                    type="radio"
                    defaultValue={"2"}
                    {...register("tier", {
                      required: true,
                      value: selectedSubscription?.data?.tier || "",
                    })}
                  />
                  <label class="formInputCheckboxLabel">Tier 2</label>
                </div>
                <div class="mt-4 flex items-center">
                  <input
                    className={`formInputCheckbox`}
                    type="radio"
                    defaultValue={"3"}
                    {...register("tier", {
                      required: true,
                      value: selectedSubscription?.data?.tier || "",
                    })}
                  />
                  <label class="formInputCheckboxLabel">Tier 3</label>
                </div>
                <div className="validationError">
                  {formState.errors?.tier?.type === "required" && "Tier is required"}
                </div>
              </div>

              <div className="formElement">
                <p className="formTitle">Featured Texts</p>
                <div class="mt-4 flex items-center">
                  <input
                    className={`formInputCheckbox`}
                    type="checkbox"
                    defaultValue={"Design in 3D from the start"}
                    {...register("features", {
                      required: true,
                    })}
                  />
                  <label class="formInputCheckboxLabel">Design in 3D from the start</label>
                </div>
                <div class="mt-4 flex items-center">
                  <input
                    className={`formInputCheckbox`}
                    type="checkbox"
                    defaultValue={"2D drawing and designing"}
                    {...register("features", {
                      required: true,
                    })}
                  />
                  <label class="formInputCheckboxLabel">2D drawing and designing</label>
                </div>
                <div class="mt-4 flex items-center">
                  <input
                    className={`formInputCheckbox`}
                    type="checkbox"
                    defaultValue={
                      "Export (Cut lists, Material pricing, Material lists,Job costing)"
                    }
                    {...register("features", {
                      required: true,
                    })}
                  />
                  <label class="formInputCheckboxLabel">
                    Export (Cut lists, Material pricing, Material lists,Job costing)
                  </label>
                </div>
                <div class="mt-4 flex items-center">
                  <input
                    className={`formInputCheckbox`}
                    type="checkbox"
                    defaultValue={"CNC nested-based production"}
                    {...register("features", {
                      required: true,
                    })}
                  />
                  <label class="formInputCheckboxLabel">CNC nested-based production</label>
                </div>
                <div className="validationError">
                  {formState.errors?.features?.type === "required" && "Featured text is required"}
                </div>
              </div>
            </div>
            <div className="flex flex-1 flex-col gap-10">
              <div className="formElement">
                <p className="formTitle">Monthly Pricing</p>
                <div className="formInputWrapper">
                  <input
                    className="formTextInput"
                    {...register("monthly_fee", {
                      pattern: /^[+-]?([0-9]*[.])?[0-9]+$/,
                      required: true,
                      value: selectedSubscription?.data?.monthly_fee || "",
                    })}
                  />
                  <span className="text-gris-600">USD</span>
                </div>
                <div className="validationError">
                  {formState.errors?.monthly_fee?.type === "required" &&
                    "Monthly Pricing is required"}
                  {formState.errors?.monthly_fee?.type === "pattern" && "Please enter valid number"}
                </div>
              </div>

              <div className="formElement">
                <p className="formTitle">Annual Pricing</p>
                <div className="formInputWrapper">
                  <input
                    className="formTextInput"
                    {...register("annual_fee", {
                      pattern: /^[+-]?([0-9]*[.])?[0-9]+$/,
                      required: true,
                      value: selectedSubscription?.data?.annual_fee || "",
                    })}
                  />
                  <span className="text-gris-600">USD</span>
                </div>
                <div className="validationError">
                  {formState.errors?.annual_fee?.type === "required" &&
                    "Annual Pricing is required"}
                  {formState.errors?.annual_fee?.type === "pattern" && "Please enter valid number"}
                </div>
              </div>

              <div className="formElement">
                <p className="formTitle">Monthly Seat Pricing</p>
                <div className="formInputWrapper">
                  <input
                    className="formTextInput"
                    {...register("extra_seat_monthly_fee", {
                      pattern: /^[+-]?([0-9]*[.])?[0-9]+$/,
                      required: true,
                      value: selectedSubscription?.data?.extra_seat_monthly_fee || "",
                    })}
                  />
                  <span className="text-gris-600">USD</span>
                </div>
                <div className="validationError">
                  {formState.errors?.extra_seat_monthly_fee?.type === "required" &&
                    "Monthly Seat Pricing is required"}
                  {formState.errors?.extra_seat_monthly_fee?.type === "pattern" &&
                    "Please enter valid number"}
                </div>
              </div>

              <div className="formElement">
                <p className="formTitle">Annual Seat Pricing</p>
                <div className="formInputWrapper">
                  <input
                    className="formTextInput"
                    {...register("extra_seat_annual_fee", {
                      pattern: /^[+-]?([0-9]*[.])?[0-9]+$/,
                      required: true,
                      value: selectedSubscription?.data?.extra_seat_annual_fee || "",
                    })}
                  />
                  <span className="text-gris-600">USD</span>
                </div>
                <div className="validationError">
                  {formState.errors?.extra_seat_annual_fee?.type === "required" &&
                    "Annual Seat Pricing is required"}
                  {formState.errors?.extra_seat_annual_fee?.type === "pattern" &&
                    "Please enter valid number"}
                </div>
              </div>
            </div>
          </div>
          <div className="mt-10 flex justify-end gap-5">
            <button onClick={onClose} className="btn-secondary">
              Cancel
            </button>
            <button>Save changes</button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddEditForm;
