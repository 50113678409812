import "./footer.css";

import { Facebook, Instagram, Twitter } from "assets/icons";
import logo from "assets/images/logo.svg";
import { useAuth } from "lib/auth";
import { HashLink } from "react-router-hash-link";

export default function Footer() {
  const auth = useAuth();
  return (
    <div className="bg-parametric-blue">
      <div className="flex justify-center pt-24 pb-16">
        <img src={logo} alt="NVISION3D" width={"325px"} />
      </div>
      <div className="footerNavLinks">
        {!auth.isUserLoggedIn() && (
          <>
            <a href="/register">Register</a>
            <a href="/login">Log In</a>
          </>
        )}
        <HashLink smooth to="/#faq">
          FAQ
        </HashLink>
      </div>
      <div className="footerOtherLinks">
        <div>&copy; 2022 Parametrics | All rights reserved</div>
        <div className="flex grow justify-center gap-5">
          <a href="/">
            <Facebook />
          </a>
          <a href="/">
            <Instagram />
          </a>
          <a href="/">
            <Twitter />
          </a>
        </div>
        <div className="legalLinks flex">
          <a href="/privacy-policy">Privacy Policy</a>
          <a href="/terms-conditions">Terms and Conditions</a>
        </div>
      </div>
    </div>
  );
}
