import { Sort } from "assets/icons";
import { useMemo } from "react";
import { useSortBy, useTable } from "react-table";

function SubscriptionSeatsWithOptions({ seats, onEdit, onClear, onDelete, disabled = true }) {
  const columns = useMemo(
    () => [
      {
        Cell: (props) => (
          <>
            <p className={`${disabled ? `text-gris-600` : `text-bleu-100`}`}>
              {props.row.original.data.fingerprint}
            </p>
          </>
        ),
        Header: "Machine ID",
        accessor: "fingerprint",
        isSortable: true,
      },
      {
        Cell: (props) => (
          <>
            <p className={`${disabled ? `text-gris-600` : `text-bleu-850`}`}>
              {props.row.original.data.login}
            </p>
          </>
        ),
        Header: "Username",
        accessor: "username",
        isSortable: true,
      },
      {
        Cell: (props) => (
          <>
            <p className={`font-semibold ${disabled ? `text-gris-600` : `text-bleu-850`}`}>
              {props.row.original.data.name}
            </p>
          </>
        ),
        Header: "Name",
        accessor: "name",
        isSortable: true,
      },
      {
        Cell: (props) => {
          return (
            <>
              <p className={`${disabled ? `text-gris-600` : `text-bleu-100`}`}>
                <span
                  className="cursor-pointer"
                  onClick={() => (disabled ? {} : onEdit(props.row.original))}
                >
                  Edit
                </span>{" "}
                |{" "}
                <span
                  className="cursor-pointer"
                  onClick={() => (disabled ? {} : onClear(props.row.original))}
                >
                  Clear
                </span>{" "}
                |{" "}
                <span
                  className={`cursor-pointer ${disabled ? `text-gris-600` : `text-red-600`}`}
                  onClick={() => (disabled ? {} : onDelete(props.row.original))}
                >
                  Delete
                </span>
              </p>
            </>
          );
        },
        Header: "",
        accessor: "options",
      },
    ],
    []
  );

  const tableInstance = useTable(
    {
      columns: columns,
      data: seats,
    },
    useSortBy
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;

  return (
    <table {...getTableProps()}>
      <thead>
        {
          // Loop over the header rows
          headerGroups.map((headerGroup) => (
            // Apply the header row props
            <tr {...headerGroup.getHeaderGroupProps()}>
              {
                // Loop over the headers in each row
                headerGroup.headers.map((column) => {
                  let thProps = column.getHeaderProps();
                  if (column.isSortable) {
                    thProps = column.getHeaderProps(column.getSortByToggleProps());
                  }
                  return (
                    <th {...thProps}>
                      {column.render("Header")}
                      {column.isSortable && <Sort />}
                    </th>
                  );
                })
              }
            </tr>
          ))
        }
      </thead>
      {/* Apply the table body props */}
      <tbody {...getTableBodyProps()}>
        {
          // Loop over the table rows
          rows.map((row, i) => {
            // Prepare the row for display
            prepareRow(row);
            const rowClass = i % 2 ? "odd" : "even";
            return (
              // Apply the row props
              <tr {...row.getRowProps()} className={rowClass}>
                {
                  // Loop over the rows cells
                  row.cells.map((cell) => {
                    // Apply the cell props
                    return (
                      <td {...cell.getCellProps()}>
                        {
                          // Render the cell contents
                          cell.render("Cell")
                        }
                      </td>
                    );
                  })
                }
              </tr>
            );
          })
        }
      </tbody>
    </table>
  );
}

export default SubscriptionSeatsWithOptions;
