import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const COUPON_API_REDUCER_KEY = "coupon-api";

export const couponApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BACKEND_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem("authToken");
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    addCoupon: builder.mutation({
      invalidatesTags: ["couponList"],
      query: (couponBody) => ({
        body: couponBody,
        method: "POST",
        url: "/coupons",
      }),
    }),
    getCoupons: builder.query({
      providesTags: ["couponList"],
      query: () => "/coupons?orderByFieldName=created_date&orderByDirection=desc",
    }),
  }),
  reducerPath: COUPON_API_REDUCER_KEY,
  tagTypes: ["couponList"],
});

export const { useAddCouponMutation, useGetCouponsQuery } = couponApi;
