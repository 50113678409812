import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { generateQueryParams } from "lib/utils";

export const PAYMENT_HISTORY_API_REDUCER_KEY = "payment-history-api";

export const paymentHistoryApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BACKEND_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem("authToken");
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getAdminPaymentHistory: builder.query({
      providesTags: ["AdminPaymentHistory"],
      query: (params = {}) => `/admin/invoices?${generateQueryParams(params)}`,
    }),
    getCustomerPaymentHistory: builder.query({
      providesTags: ["CustomerPaymentHistory"],
      query: () => "/invoices",
    }),
    getInvoiceDetails: builder.query({
      providesTags: ["InvoiceDetails"],
      query: (id) => `/invoices/${id}`,
    }),
  }),
  reducerPath: PAYMENT_HISTORY_API_REDUCER_KEY,
  tagTypes: ["AdminPaymentHistory", "CustomerPaymentHistory", "InvoiceDetails"],
});

export const {
  useGetAdminPaymentHistoryQuery,
  useGetCustomerPaymentHistoryQuery,
  useGetInvoiceDetailsQuery,
} = paymentHistoryApi;
