import "./contact.css";

import { ArrowSend, Email, Location, Phone } from "assets/icons";
import { Loader } from "components";
import { useSubmitCustomerContactMutation } from "modules/customer/customerActions";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

export default function Contact() {
  const { register, handleSubmit, formState, reset } = useForm();

  const [submitContactForm, { isLoading }] = useSubmitCustomerContactMutation();

  const handleSubmitForm = async (body) => {
    let result = await submitContactForm(body);
    if (result.error) {
      toast.error(result?.error?.data?.message || "Something went wrong");
    } else {
      toast.success("Contact request submitted successfully.");
      reset();
    }
  };

  return (
    <div
      className="contact p-0 lg:px-40 md:min-h-[756px] md:p-0 md:px-10 md:py-[85px]"
      id="contactus"
    >
      <div className="relative">
        <div className="flex flex-col justify-between gap-6 text-left n3d1440:gap-44 md:flex-row">
          <div>
            <div className="blueTitle">Contact</div>
            <div className="exoTitle mt-1 mb-14 text-white">Get in Touch</div>
            <div className="contactInfo">
              <div className="mb-6 flex">
                <Email />
                <div className="ml-3 grow">
                  <a href="mailto:info@nv3dsoftware.com">Info@nv3dsoftware.com</a>
                </div>
              </div>
              <div className="mb-6 flex">
                <Phone />
                <div className="ml-3 grow">(479) 313-0919</div>
              </div>
              <div className="flex">
                <Location />
                <div className="ml-3 grow">
                  2811 Springdale Ave #8422
                  <br />
                  Springdale AR 72766
                </div>
              </div>
            </div>
          </div>
          <div>
            <Loader isLoading={isLoading} />
            <form onSubmit={handleSubmit(handleSubmitForm)}>
              <div className="flex gap-5">
                <div>
                  <input
                    placeholder="First Name"
                    type="text"
                    {...register("first_name", {
                      required: true,
                    })}
                  />
                  <div className="validationError">
                    {formState.errors?.first_name?.type === "required" && "First Name is required"}
                  </div>
                </div>
                <div>
                  <input
                    placeholder="Last Name"
                    type="text"
                    {...register("last_name", {
                      required: true,
                    })}
                  />
                  <div className="validationError">
                    {formState.errors?.last_name?.type === "required" && "First Name is required"}
                  </div>
                </div>
              </div>
              <div>
                <input
                  type="text"
                  placeholder="Email"
                  {...register("email", {
                    pattern: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                    required: true,
                  })}
                />
                <div className="validationError">
                  {formState.errors?.email?.type === "required" && "Email is required"}
                  {formState.errors?.email?.type === "pattern" && "Email is not valid"}
                </div>
              </div>
              <div className="pt-9">
                <textarea
                  placeholder="Type message here..."
                  rows={5}
                  {...register("message", {
                    required: true,
                  })}
                ></textarea>
                <div className="validationError">
                  {formState.errors?.message?.type === "required" && "Message is required"}
                </div>
              </div>
              <div className="mt-10">
                <button type="submit" className="ml-auto flex justify-center gap-3">
                  Send <ArrowSend />
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
