export default function Sort() {
  return (
    <svg width="7" height="9" viewBox="0 0 7 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.550519 5.22344H5.99493C6.48447 5.22344 6.72924 5.80897 6.38382 6.15034L3.66161 8.84062C3.44658 9.05313 3.09887 9.05313 2.88613 8.84062L0.161632 6.15034C-0.183791 5.80897 0.0609793 5.22344 0.550519 5.22344ZM6.38382 2.84966L3.66161 0.159382C3.44658 -0.0531274 3.09887 -0.0531274 2.88613 0.159382L0.161632 2.84966C-0.183791 3.19103 0.0609793 3.77656 0.550519 3.77656H5.99493C6.48447 3.77656 6.72924 3.19103 6.38382 2.84966Z"
        fill="#00A0EE"
      />
    </svg>
  );
}
