import {
  AvailableSeatCount,
  Modal,
  SeatClearConfirmModal,
  SeatDeleteConfirmModal,
  SubscriptionSeatEdit,
  SubscriptionSeatsWithOptions,
} from "components";
import { PageHeader } from "components/admin";
import { SUBSCRIPTION_STATUS } from "constants/index";
import { useAuth } from "lib/auth";
import {
  useDeleteSeatMutation,
  useGetSubscriptionSeatsQuery,
  useUpdateSeatMutation,
} from "modules/seat/seatActions";
import { useState } from "react";
import { toast } from "react-toastify";

export default function MySubscription() {
  const { getCustomerId, currentSubscription } = useAuth();
  const { data, isLoading } = useGetSubscriptionSeatsQuery(getCustomerId());
  const [updateSeat, { isLoading: isLoadingClear }] = useUpdateSeatMutation();
  const [removeSeat, { isLoading: isLoadingDelete }] = useDeleteSeatMutation();

  const [selectedSeat, setSelectedSeat] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showClearModal, setShowClearModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const onEditHandler = (seat) => {
    setSelectedSeat(seat);
    setShowEditModal((p) => !p);
  };

  const onClearHandler = (seat) => {
    setSelectedSeat(seat);
    setShowClearModal((p) => !p);
  };

  const onDeleteHandler = (seat) => {
    setSelectedSeat(seat);
    setShowDeleteModal((p) => !p);
  };

  const clearSeat = async () => {
    let result = await updateSeat({
      customerId: getCustomerId(),
      fingerprint: "",
      id: selectedSeat?.id,
      login: "",
      name: "",
      password: "",
    });
    if (result.error) {
      toast.error(result?.error?.data?.message || "Something went wrong");
    } else {
      toast.success("Seat cleared successfully");
      setShowClearModal(false);
      setSelectedSeat(null);
    }
  };

  const deleteSeat = async () => {
    let result = await removeSeat({
      customerId: getCustomerId(),
      id: selectedSeat?.id,
    });
    if (result.error) {
      toast.error(result?.error?.data?.message || "Something went wrong");
    } else {
      toast.success("Seat deleted successfully");
      setShowDeleteModal(false);
      setSelectedSeat(null);
    }
  };

  return (
    <>
      <PageHeader title="Subscription Seats" searchField={false} />
      {isLoading && <p className="mt-12">Loading data...</p>}
      {!isLoading && !!!data?.length && <p className="mt-12">No seats found.</p>}
      {!isLoading && !!data?.length && (
        <div className="mt-12 flex gap-8">
          <div className="flex-[2]">
            <SubscriptionSeatsWithOptions
              seats={data || []}
              onEdit={onEditHandler}
              onClear={onClearHandler}
              onDelete={onDeleteHandler}
              disabled={currentSubscription?.status !== SUBSCRIPTION_STATUS.active}
            />
          </div>
          <div className="flex-1">
            <AvailableSeatCount
              customerId={getCustomerId()}
              data={data || []}
              disabled={currentSubscription?.status !== SUBSCRIPTION_STATUS.active}
            />
          </div>
        </div>
      )}
      <Modal
        show={showEditModal}
        onClose={() => {
          setShowEditModal(false);
          setSelectedSeat(null);
        }}
      >
        <SubscriptionSeatEdit
          customerId={getCustomerId()}
          selected={selectedSeat}
          onClose={() => {
            setShowEditModal(false);
            setSelectedSeat(null);
          }}
        />
      </Modal>

      <Modal
        show={showClearModal}
        onClose={() => {
          setShowClearModal(false);
          setSelectedSeat(null);
        }}
      >
        <SeatClearConfirmModal
          loading={isLoadingClear}
          onConfirm={clearSeat}
          onClose={() => {
            setShowClearModal(false);
            setSelectedSeat(null);
          }}
        />
      </Modal>

      <Modal
        show={showDeleteModal}
        onClose={() => {
          setShowDeleteModal(false);
          setSelectedSeat(null);
        }}
      >
        <SeatDeleteConfirmModal
          loading={isLoadingDelete}
          onConfirm={deleteSeat}
          onClose={() => {
            setShowDeleteModal(false);
            setSelectedSeat(null);
          }}
        />
      </Modal>
    </>
  );
}
