import "tw-elements";
import "./header.css";

import { Avatar } from "assets/icons";
import { ADMIN_ROUTES, CUSTOMER_ROUTES, UNSUBSCRIBED_CUSTOMER_ROUTES } from "constants";
import { useAuth } from "lib/auth";
import { useMemo } from "react";
import { NavLink } from "react-router-dom";

export default function ProtectedLayoutHeader() {
  const { checkRules, currentSubscription, user, getLoggedInEmail, isAdmin, logout } = useAuth();

  const routes = useMemo(() => {
    if (isAdmin()) return ADMIN_ROUTES;
    else {
      if (currentSubscription) return CUSTOMER_ROUTES;
      else return UNSUBSCRIBED_CUSTOMER_ROUTES;
    }
  }, [isAdmin]);

  return (
    <>
      <div className="headerComponent flex justify-center">
        <div>
          <div className="dropdown relative">
            <div
              className="
          dropdown-toggle
          flex
          cursor-pointer
          items-center
          gap-2
          whitespace-nowrap
          rounded
          px-6
          py-2.5
        "
              id="protectedHeaderMenuDropdown"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <div className="text-white">{user?.email}</div>
              <Avatar />
            </div>
            <ul
              className="header-menu-items-container dropdown-menu"
              aria-labelledby="protectedHeaderMenuDropdown"
            >
              {routes.map((r) => {
                if (checkRules(r?.rules))
                  return (
                    <li key={r.href}>
                      <NavLink to={r.href} className="item">
                        {r.title}
                      </NavLink>
                    </li>
                  );
                else return <></>;
              })}
              <li>
                <a href="#" onClick={logout} className="item">
                  Logout
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
