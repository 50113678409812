import { Sort } from "assets/icons";
import { SimplePaging } from "components";
import { PageHeader } from "components/admin";
import { SUBSCRIPTION_PAYMENT_STATUS } from "constants/index";
import { getDateFromUnix } from "lib/utils";
import { useGetCustomerPaymentHistoryQuery } from "modules/payment-history/paymentHistoryActions";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSortBy, useTable } from "react-table";

const columns = (navigate) => {
  return [
    {
      Cell: (props) => (
        <>
          <p className="font-bold text-gris-600">
            {getDateFromUnix(
              props.row.original?.data?.payment_date?._seconds,
              props.row.original?.data?.payment_date?._nanoseconds
            )}
          </p>
        </>
      ),
      Header: "Purchase date",
      accessor: "purchased_at",
      isSortable: true,
      width: "20%",
    },
    {
      Cell: (props) => (
        <>
          <p className="text-bleu-850">
            {props.row.original?.data?.subscription
              ? getDateFromUnix(
                  props.row.original?.data?.subscription?.expiration_date?._seconds,
                  props.row.original?.data?.subscription?.expiration_date?._nanoseconds
                )
              : ""}
          </p>
        </>
      ),
      Header: "Expiry date",
      accessor: "expired_at",
      isSortable: true,
      width: "20%",
    },
    {
      Cell: (props) => (
        <>
          <p className="text-bleu-850">{props.row.original?.data?.amount_paid}</p>
        </>
      ),
      Header: "Amount paid",
      accessor: "paid_amount",
      width: "20%",
    },
    {
      Cell: (props) => (
        <p
          className={`${
            props.row.original?.data?.status !== SUBSCRIPTION_PAYMENT_STATUS.paid
              ? `text-red-500`
              : `text-green-800`
          }`}
        >
          {props.row.original?.data?.status === SUBSCRIPTION_PAYMENT_STATUS.paid && "Paid"}
          {props.row.original?.data?.status !== SUBSCRIPTION_PAYMENT_STATUS.paid && "Not Paid"}
        </p>
      ),
      Header: "Status",
      accessor: "status",
      width: "20%",
    },
    {
      Cell: (props) => (
        <>
          <p
            className="cursor-pointer pr-1 text-bleu-100"
            onClick={() => navigate(`/customer/payment-history/${props.row.original?.id}`)}
          >
            Details
          </p>
        </>
      ),
      Header: "Invoice",
      accessor: "customerId",
      width: "20%",
    },
  ];
};

const DEFAULT_PARAMS = {
  endBeforeId: "",
  startAfterId: "",
  toFirst: "true",
  toLast: "",
};

export default function PaymentHistory() {
  const navigate = useNavigate();
  const [params, setParams] = useState(DEFAULT_PARAMS);
  const { data, isLoading, isFetching } = useGetCustomerPaymentHistoryQuery(params);

  const onChangePaginationHandler = (obj) => {
    setParams((p) => ({ ...p, ...obj }));
  };

  const COLUMN = useMemo(() => columns(navigate) || [], [navigate]);
  const invoiceData = useMemo(() => data?.data || [], [data]);

  const tableInstance = useTable(
    {
      columns: COLUMN,
      data: invoiceData,
    },
    useSortBy
  );
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;
  return (
    <>
      <PageHeader title="Payment History" searchField={false} />
      {isLoading || isFetching ? (
        <p className="mt-10">Loading data ...</p>
      ) : !isLoading && !isFetching && invoiceData.length ? (
        <div style={{ width: "100%" }}>
          <table {...getTableProps()}>
            <thead>
              {
                // Loop over the header rows
                headerGroups.map((headerGroup) => (
                  // Apply the header row props
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {
                      // Loop over the headers in each row
                      headerGroup.headers.map((column) => {
                        let thProps = column.getHeaderProps();
                        if (column.isSortable) {
                          thProps = column.getHeaderProps(column.getSortByToggleProps());
                        }
                        return (
                          <th {...thProps} width={column.width || "unset"}>
                            {column.render("Header")}
                            {column.isSortable && <Sort />}
                          </th>
                        );
                      })
                    }
                  </tr>
                ))
              }
            </thead>
            {/* Apply the table body props */}
            <tbody {...getTableBodyProps()}>
              {
                // Loop over the table rows
                rows.map((row, i) => {
                  // Prepare the row for display
                  prepareRow(row);
                  const rowClass = i % 2 ? "odd" : "even";
                  return (
                    // Apply the row props
                    <tr {...row.getRowProps()} className={rowClass}>
                      {
                        // Loop over the rows cells
                        row.cells.map((cell) => {
                          // Apply the cell props
                          return (
                            <td {...cell.getCellProps()}>
                              {
                                // Render the cell contents
                                cell.render("Cell")
                              }
                            </td>
                          );
                        })
                      }
                    </tr>
                  );
                })
              }
            </tbody>
          </table>
          {!(isLoading || isFetching) && (
            <div className="mt-6 flex items-center justify-between">
              <SimplePaging
                data={{
                  nextPageId: data?.nextPageId || "",
                  prevPageId: data?.prevPageId || "",
                }}
                onChangePagination={onChangePaginationHandler}
              />
              <p>
                Showing {invoiceData?.length} out of {data?.total || "-"}
              </p>
            </div>
          )}

          {/* <Paging
        extraStyle={"mt-9"}
        pageCount={numberOfPages}
        pageIndex={currentPage}
        gotoPage={goToPage}
      /> */}
        </div>
      ) : (
        <p className="mt-10">No data found.</p>
      )}
    </>
  );
}
